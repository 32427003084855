import React, { Component } from "react";
import "./ChangePassword.css";
import { getToken } from "../../common/commonFunctions/commonFunctions";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";

class ChangePassword extends Component {
  state = {
    currentPassword: "",
    newPassword: "",
    confirmPassword: "",
    isShowPassword: true,
    isShowCurrentPassword: true,
    isShowConfirmPassword: true,
    Message: "",
    errorMessage: "",
  };
  showConfirmPassword = () => {
    this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
  };
  showCurrentPassword = () => {
    this.setState({ isShowCurrentPassword: !this.state.isShowCurrentPassword });
  };
  showPassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoader: true });
    if (this.state.newPassword === this.state.confirmPassword) {
      axios({
        method: "POST",
        url: baseURL + "api/v1/Auth/ChangePassword",
        data: {
          oldPassword: this.state.currentPassword,
          newPassword: this.state.newPassword,
        },
        headers: {
          "Content-Type": "application/json-patch+json",
          accept: "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => {
          this.setState({ isLoader: false });
          if (res.data.isSuccess) {
            this.setState({ Message: "Password Changed Successfully!" });
          } else {
            this.setState({ isLoader: false });
            this.setState({ errorMessage: res.data.message });
          }
        })
        .catch((error) => {
          this.setState({ disableButton: false, isLoader: false });

          this.setState({
            errorMessage: error.response.data.error.errorMessage,
            isSuccess: false,
          });
        });
    } else {
      this.setState({ isLoader: false });
      this.setState({
        errorMessage: "Passwords Do not Match , Please Try Again ",
      });
    }
  };

  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <div class="change-password">
        <div className={this.state.isLoader ? "loader-bg" : ""}></div>

        <div className="center-div form-container">
          <div className="container-fluid">
            {this.state.Message !== "" ? (
              <>
                {this.state.Message !== "" ? (
                  <strong>
                    <strong>
                      <h4>
                        <p class="login-or">{this.state.Message}</p>
                      </h4>
                    </strong>
                  </strong>
                ) : null}
                <div class="mt-3 mb-3"></div>
              </>
            ) : (
              <>
                <form onSubmit={this.handleSubmit} className="form-group">
                  <div className="col-md-12">
                    <div className="form-group input-full main-input">
                      <div className="showPassword">
                        {" "}
                        <input
                          type={
                            this.state.isShowCurrentPassword
                              ? "password"
                              : "text"
                          }
                          className="form-control"
                          id="currentPassword"
                          name="currentPassword"
                          placeholder="Enter Current Password"
                          required
                          onChange={this.onHandleChange}
                        />
                        {this.state.isShowCurrentPassword ? (
                          <i
                            className="fas fa-eye"
                            onClick={() => this.showCurrentPassword()}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash"
                            onClick={() => this.showCurrentPassword()}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className=" form-group input-full main-input">
                      <div className="showPassword">
                        {" "}
                        <input
                          type={this.state.isShowPassword ? "password" : "text"}
                          className="form-control"
                          id="newPassword"
                          name="newPassword"
                          placeholder="New Password"
                          required
                          onChange={this.onHandleChange}
                        />
                        {this.state.isShowPassword ? (
                          <i
                            className="fas fa-eye"
                            onClick={() => this.showPassword()}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash"
                            onClick={() => this.showPassword()}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className=" form-group input-full main-input">
                      <div className="showPassword">
                        {" "}
                        <input
                          type={
                            this.state.isShowConfirmPassword
                              ? "password"
                              : "text"
                          }
                          className="form-control"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          required
                          onChange={this.onHandleChange}
                        />
                        {this.state.isShowConfirmPassword ? (
                          <i
                            className="fas fa-eye"
                            onClick={() => this.showConfirmPassword()}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash"
                            onClick={() => this.showConfirmPassword()}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-danger"> {this.state.errorMessage}</div>
                  <div className="row margin-top-15">
                    <div className="col-xs-12">
                      <div className="button-width">
                        <button
                          className="button-primary button-shadow button-full"
                          type="submit"
                        >
                          <span>save</span>
                          <div className="button-triangle"></div>
                          <div className="button-triangle2"></div>
                          <div className="button-icon">
                            <i className="fa fa-user"></i>
                          </div>
                        </button>
                      </div>

                      <div></div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default ChangePassword;
