// MyGoogleMaps.js
import React, { Component } from 'react';

import GoogleMapReact from 'google-map-react';
import { GoogleMap } from 'react-google-maps';
import { InfoWindow } from '@react-google-maps/api';

import styled from 'styled-components';

import AutoComplete from './Autocomplete';
import Marker from './Marker';
import axios from 'axios';
import { baseURL } from '../../../ApiURL/ApiURL';
const Wrapper = styled.main`
  width: 100%;
  height: 60%;
`;

class MyGoogleMap extends Component {



    state = {

        mapApiLoaded: false,
        mapInstance: null,
        mapApi: null,
        geoCoder: null,
        places: [],
        center: [],
        zoom: 9,
        address: '',
        draggable: true,
        lat: 33.6844,
        lng: 73.0479
    };

    componentWillMount() {





        this.setCurrentLocation();
    }


    onMarkerInteraction = (childKey, childProps, mouse) => {
        this.setState({
            draggable: false,
            lat: mouse.lat,
            lng: mouse.lng
        });
    }
    onMarkerInteractionMouseUp = (childKey, childProps, mouse) => {
        this.setState({ draggable: true });
        this._generateAddress();
    }

    _onChange = ({ center, zoom }) => {
        this.setState({
            center: center,
            zoom: zoom,
        });

    }

    _onClick = (value) => {
        this.setState({
            lat: value.lat,
            lng: value.lng
        });
    }

    apiHasLoaded = (map, maps) => {
        this.setState({
            mapApiLoaded: true,
            mapInstance: map,
            mapApi: maps,
        });

        this._generateAddress();
    };

    addPlace = (place) => {
        this.setState({
            places: [place],
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng()
        });
        this._generateAddress()
    };

    _generateAddress() {
        const {
            mapApi
        } = this.state;

        const geocoder = new mapApi.Geocoder;

        geocoder.geocode({ 'location': { lat: this.state.lat, lng: this.state.lng } }, (results, status) => {
            console.log("rsult is", results);
            console.log("stuts", status);
            if (status === 'OK') {
                if (results[0]) {
                    this.zoom = 12;
                    this.setState({ address: results[0].formatted_address });
                } else {
                    window.alert('No results found');
                }
            } else {
                window.alert('Geocoder failed due to: ' + status);
            }

        });
    }

    // Get Current Location Coordinates
    setCurrentLocation() {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition((position) => {
                this.setState({
                    center: [position.coords.latitude, position.coords.longitude],
                    lat: position.coords.latitude,
                    lng: position.coords.longitude
                });
            });
        }
    }

    render() {

        const LatLong = [{ latitude: 31.5088452, longitude: 74.3577488 }, { latitude: 30.4782156, longitude: 76.439572 },
        { latitude: 31.3659731, longitude: 72.8302535 },
        { latitude: 34.5003192, longitude: 72.0697234 },
        ]



        const {
            places, mapApiLoaded, mapInstance, mapApi,
        } = this.state;

        return (
            <Wrapper>
                {mapApiLoaded && (
                    <div>
                        <AutoComplete map={mapInstance} mapApi={mapApi} addplace={this.addPlace} />
                    </div>
                )}
                <GoogleMapReact
                    center={this.state.center}
                    zoom={this.state.zoom}
                    draggable={this.state.draggable}
                    onChange={this._onChange}
                    onChildMouseDown={this.onMarkerInteraction}
                    onChildMouseUp={this.onMarkerInteractionMouseUp}
                    onChildMouseMove={this.onMarkerInteraction}
                    onChildClick={() => console.log('child click')}
                    onClick={this._onClick}
                    bootstrapURLKeys={{
                        // key: 'AIzaSyAARoDNFh9NXXtEbshTGTMNTUBW7XY31WE',
                        // googleMapURL:`https://maps.googleapis.com/maps/api/js?key=AIzaSyD2jf46O3SfVWbAfraUWP8fOxptp7Vq0ho&v=3.exp&libraries=geometry,drawing,places`,
                        key: 'AIzaSyD2jf46O3SfVWbAfraUWP8fOxptp7Vq0ho',

                        libraries: ['places', 'geometry'],
                    }}
                    yesIWantToUseGoogleMapApiInternals
                    onGoogleApiLoaded={({ map, maps }) => this.apiHasLoaded(map, maps)}
                >



                    {/* <Marker
// text={this.state.address}
lat={this.state.latitude}
lng={this.state.longitude}

/> */}

                    {/* <Marker
// text={this.state.address}
lat="34.5003192"
lng="72.0697234
"
/>
<Marker
// text={this.state.address}
lat="34.5003192"
lng="72.0697234
"

/> */}


                    {
                        LatLong.map((item) => {
                            return (
                                <>
                                    <Marker
                                        // text={this.state.address}
                                        lat={item.latitude}
                                        lng={item.longitude}
                                    />
                                </>
                            )
                        })

                    }

                    {/* <p style={{background:"white"}}></p> */}

                </GoogleMapReact>

                <div className="info-wrapper">
                    <div className="map-details">Latitude: <span>{this.state.lat}</span>, Longitude: <span>{this.state.lng}</span></div>
                    <div className="map-details">Zoom: <span>{this.state.zoom}</span></div>
                    <div className="map-details">Address: <span>{this.state.address}</span></div>
                </div>
            </Wrapper >
        );
    }
}

export default MyGoogleMap;