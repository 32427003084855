import React, { Component } from "react";
import Button from "../../common/Button/Button";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import {
  setToken,
  getToken,
  setUserName,
  setIsLoggedIn,
  getIsLoggedIn,
  closeModal,
  setIsLoader,
  getIsLoader,
  disableScroll,
  enableScroll,
} from "../../common/commonFunctions/commonFunctions";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
const validEmailRegex = RegExp(
  /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
);
const validateForm = errors => {
  let valid = true;
  Object.values(errors).forEach(val => val.length > 0 && (valid = false));
  return valid;
};
class Login extends Component {
  state = {
    loginEmail: "",
    loginPassword: "",
    successMessage: "",
    errorMessage: "",
    disableButton: "",
    isShowPassword: true,
    pathNameUrl: "",
  };
  constructor(props) {
    super(props);

    this.state = {
      isLoader: false,
      isShowPassword: true,
      email: "",
      password: "",
      errors: {
        fullName: '',
        email: '',
        password: '',
      }
    };
  }

  showPassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };
  handleSignIn = (event) => {
    event.preventDefault();

    if(this.state.email==="" && this.state.password==="")
    {
      this.setState({errorMessage:"Please enter username and password"})
    }
else if(this.state.email==="")
{
  this.setState({errorMessage:"Please enter username"})
}
else if(this.state.password==="")
{
  this.setState({errorMessage:"Please enter password"})
}
    else {
  disableScroll();
    this.setState({ disableButton: true, isLoader: true });
  axios({
    method: "post",
    url: baseURL + "api/v1/Auth/login",
    data: {
      email: this.state.email,
      password: this.state.password,
    },
  })
    .then((response) => {
      enableScroll();
      this.setState({ disableButton: false, isLoader: false });
      this.setState({ successMessage: response.data.message });
      if (response.status == 200 || response.status == 201) {
        setToken(response.data.data.token);
        setUserName(response.data.data.userInfo.name);
        setIsLoggedIn(true);
        localStorage.setItem("email", this.state.email);
        //  modal close after logged in
        closeModal("login-modal");
        window.location.reload(true);
      }
    })
    .catch((error) => {
      enableScroll();
      this.setState({
        disableButton: false,
        isLoader: false,
        errorMessage: error.response.data.error.errorMessage,
      });
    });
}   
  };


  handleChange = (event) => {
    this.setState({errorMessage:""})
    this.setState({ [event.target.name]: event.target.value });
    event.preventDefault();
    const { name, value } = event.target;
    let errors = this.state.errors;

    switch (name) {
      case 'email': 
        errors.email = 
          validEmailRegex.test(value)
            ? ''
            : 'Email is not valid!';
        break;
      case 'password': 
        errors.password = 
          value.length ==null
            ? 'Password must be at least 8 characters long!'
            : '';
        break;
      default:
        break;
    }

    this.setState({errors, [name]: value});
  }
  // handleChange = (e) => {
  //   this.setState({ [e.target.name]: e.target.value });
  // };
  render() {
    const {errors} = this.state;
    if (getIsLoggedIn()) {
      if (localStorage.getItem("path") == undefined) {
        // return <Redirect to={{ pathname: "/home" }} />;
      } else {
        // redirect to home if signed up
        return <Redirect to={{ pathname: localStorage.getItem("path") }} />;
      }
    }
    return (
      <div class="modal-body ">
        <div className={this.state.isLoader ? "loader-bg" : ""}></div>
        <button
          type="button"
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          onClick={() => {
            closeModal("login-modal");
          }}
        >
          <span aria-hidden="true">&times;</span>
        </button>{" "}
        <div className="modal-title">
          <h1>
            {" "}
            LOGIN<span className="special-color">.</span>
          </h1>
          <div className="modal-short-title-separator"></div>
        </div>
        <form onSubmit={this.handleSignIn}>
          {" "}
          <input
            // pattern="^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
            name="email"
            type="text"
            className="input-full main-input"
            placeholder="Email"
            onChange={this.handleChange}
            value={this.state.email}
          />
          <div className="showPassword">
            <input
              name="password"
              type={this.state.isShowPassword ? "password" : "text"}
              className="input-full main-input"
              placeholder="Password"
              onChange={this.handleChange}
              value={this.state.password}
             
            />
            {this.state.isShowPassword ? (
              <i className="fas fa-eye" onClick={() => this.showPassword()}></i>
            ) : (
              <i
                className="fas fa-eye-slash"
                onClick={() => this.showPassword()}
              ></i>
            )}
          </div>
          <div className="text-danger">{this.state.errorMessage}</div>
          <div className="text-success">{this.state.successMessage}</div>
          {
(this.state.email==null || this.state.password==null)?
<Button

buttonName="LOGIN"
type="submit"
disableButton={this.state.disableButton}
/>:
  <Button
  buttonName="LOGIN"
  type="submit"
  disableButton={this.state.disableButton}
/>
       }
        
        </form>
        <a
          href="#"
          data-toggle="modal"
          data-target="#resetpassword"
          data-dismiss="modal"
          aria-label="Close"
          class="register-link forgot-link pull-right"
        >
          Forgot Password?
        </a>
        <div class="clearfix"></div>
        <p class="login-or">OR</p>
        <p class="modal-bottom">
          Don't have an account?{" "}
          <a
            href="#"
            data-toggle="modal"
            data-target="#registeration"
            data-dismiss="modal"
            aria-label="Close"
            class="register-link"
          >
            REGISTER
          </a>
        </p>
      </div>
    );
  }
}

export default Login;
// import React, { Component, useState } from "react";
// import Button from "../../common/Button/Button";
// import SimpleReactValidator from "simple-react-validator";
// import axios from "axios";
// import { baseURL } from "../../../ApiURL/ApiURL";
// import {
//   disableScroll,
//   enableScroll,
// } from "../../common/commonFunctions/commonFunctions";
// import PhoneInput from "react-phone-input-2";
// import "react-phone-input-2/lib/style.css";
// class Login extends Component {
//   state = {
//     registerName: "",
//     registerEmail: "",
//     registerPassword: "",
//     registerPhoneNumber: "",
//     errorMessage: "",
//     successMessage: "",
//     disableButton: false,
//     isSuccess: false,
//     isShowPassword: true,
//     isLoader: false,
//   };
//   constructor(props) {
//     super(props);
//     this.validator = new SimpleReactValidator();
//   }
//   showPassword = () => {
//     this.setState({ isShowPassword: !this.state.isShowPassword });
//   };
//   signUp = () => {
//     if (
//       this.state.registerPhoneNumber.length >= 11
//     ) {
//       if (
//         (this.validator.fieldValid("name"),
//         this.validator.fieldValid("email"),
//         this.validator.fieldValid("password"),
//         this.validator.fieldValid("phonenumber"))
//       ) {
//         this.setState({ disableButton: true, isLoader: true });
//         disableScroll();
//         axios({
//           method: "post",
//           url: baseURL + "api/v1/Auth/signup",
//           data: {
//             name: this.state.registerName,
//             email: this.state.registerEmail,
//             password: this.state.registerPassword,
//             phoneNumber: this.state.registerPhoneNumber,
//           },
//         })
//           .then((response) => {
//             this.setState({ disableButton: false, isLoader: false });
//             enableScroll();
//             this.setState({
//               successMessage: response.data.data,
//               isSuccess: response.data.isSuccess,
//             });
//           })

//           .catch((error) => {
//             this.setState({ disableButton: false, isLoader: false });
//             enableScroll();
//             this.setState({
//               errorMessage: error.response.data.error.errorMessage,
//               isSuccess: false,
//             });
//           });
//       } else {
//         this.setState({ disableButton: false, isLoader: false });
//         enableScroll();
//         this.validator.showMessages();
//         // rerender to show messages for the first time
//         // you can use the autoForceUpdate option to do this automatically`
//         this.forceUpdate();
//       }
//     } else {
//       this.setState({
//         errorMessage: "Enter a valid phone number",
//         isSuccess: false,
//       });
//     }
//   };
//   handleChange = (e) => {
//     this.setState({ [e.target.name]: e.target.value });
//   };
//   render() {
//     return (
//       <div class="modal-body ">
//         <div className={this.state.isLoader ? "loader-bg" : ""}></div>
//         {this.state.isSuccess ? (
//           <>
//             <strong>
//               <h4>
//                 <p class="login-or">{this.state.successMessage}</p>
//               </h4>
//             </strong>
//             <button
//               type="button"
//               style={{backgroundColor:"#C17400",border:"1px solid #C17400"}}
//               class="btn btn-primary"
//               data-dismiss="modal"
//               aria-label="Close"
//             >
//               ok
//             </button>
//             <div class="mt-3 mb-3"></div>
//           </>
//         ) : (
//           <>
//             {" "}
//             <button
//               type="button"
//               class="close"
//               data-dismiss="modal"
//               aria-label="Close"
//             >
//               <span aria-hidden="true">&times;</span>
//             </button>{" "}
//             <div className="modal-title">
//               {" "}
//               <h1>
//                 Register<span class="special-color">.</span>
//               </h1>
//               <div class="modal-short-title-separator"></div>
//             </div>
//             <input
//               name="registerName"
//               type="text"
//               class="input-full main-input"
//               placeholder="Name"
//               onChange={this.handleChange}
//             />
//             {this.validator.message(
//               "name",
//               this.state.registerName,
//               "required|alpha",
//               {
//                 className: "text-danger",
//               }
//             )}
//             <input
//               name="registerEmail"
//               type="email"
//               pattern="^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
//               class="input-full main-input"
//               placeholder="Email"
//               onChange={this.handleChange}
//             />
//             {this.validator.message(
//               "email",
//               this.state.registerEmail,
//               "required|email",
//               {
//                 className: "text-danger",
//               }
//             )}
//             <div className="showPassword">
//               <input
//                 name="registerPassword"
//                 type={this.state.isShowPassword ? "password" : "text"}
//                 className="input-full main-input"
//                 placeholder="Password"
//                 onChange={this.handleChange}
//                 required
//               />
//               {this.state.isShowPassword ? (
//                 <i
//                   className="fas fa-eye"
//                   onClick={() => this.showPassword()}
//                 ></i>
//               ) : (
//                 <i
//                   className="fas fa-eye-slash"
//                   onClick={() => this.showPassword()}
//                 ></i>
//               )}
//             </div>
//             {this.validator.message(
//               "password",
//               this.state.registerPassword,
//               "required|min:5|max:20",
//               {
//                 className: "text-danger",
//               }
//             )}
//             <PhoneInput
//               country={"us"}
//               value={this.state.registerPhoneNumber}
//               className="input-full main-input inputStyle"
//               onChange={(phone) =>
//                 this.setState({ registerPhoneNumber: phone })
//               }
//               inputStyle={{
//                 width: "100%",
//                 borderRadius: "unset",
//                 height: " 50px",
//                 lineHeight: "40px",
//                 fontSize: "1.08em",
//                 marginBottom: "15px",
//                 fontWeight: "bold",
//               }}
//               required
//             />
//             {this.validator.message(
//               "phonenumber",
//               this.state.registerPhoneNumber,
//               "required|Phone",
//               {
//                 className: "text-danger",
//               }
//             )}
//             {this.state.isSuccess ? null : (
//               <div className="text-danger">{this.state.errorMessage}</div>
//             )}{" "}
//             <button
//               class="button-primary button-shadow button-full"
//               onClick={() => this.signUp()}
//               disabled={this.state.disableButton}
//             >
//               <span>SIGN UP</span>
//               <div class="button-triangle"></div>
//               <div class="button-triangle2"></div>
//               <div class="button-icon">
//                 <i class="fa fa-user"></i>
//               </div>
//             </button>
//             <div class="clearfix"></div>
//             <p class="login-or">OR</p>
//             <div class="mt-3 mb-3"></div>
//             <p class="modal-bottom">
//               Already registered?{" "}
//               <a
//                 href="#"
//                 data-toggle="modal"
//                 data-target="#login-modal"
//                 data-dismiss="modal"
//                 aria-label="Close"
//                 class="login-link"
//               >
//                 LOGIN
//               </a>
//             </p>
//           </>
//         )}
//       </div>
//     );
//   }
// }

// export default Login;
// import React from 'react';

// const validEmailRegex = RegExp(
//   /^(([^<>()\[\]\.,;:\s@\"]+(\.[^<>()\[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i
// );
// const validateForm = errors => {
//   let valid = true;
//   Object.values(errors).forEach(val => val.length > 0 && (valid = false));
//   return valid;
// };

// export default class Login extends React.Component {
//   constructor(props) {
//     super(props);
//     this.state = {
   
//       email: null,
//       password: null,
//       errors: {
//         fullName: '',
//         email: '',
//         password: '',
//       }
//     };
//   }

//   handleChange = (event) => {
//     event.preventDefault();
//     const { name, value } = event.target;
//     let errors = this.state.errors;

//     switch (name) {
     
//       case 'email': 
//         errors.email = 
//           validEmailRegex.test(value)
//             ? ''
//             : 'Email is not valid!';
//         break;
//       case 'password': 
//         errors.password = 
//           value.length < 8
//             ? 'Password must be at least 8 characters long!'
//             : '';
//         break;
//       default:
//         break;
//     }

//     this.setState({errors, [name]: value});
//   }

//   handleSubmit = (event) => {
//     event.preventDefault();
//     if(validateForm(this.state.errors)) {
//       console.info('Valid Form')
//     }else{
//       console.error('Invalid Form')
//     }
//   }

//   render() {
//     const {errors} = this.state;
//     return (
//       <div className='wrapper'>
//         <div className='form-wrapper'>
//           <h2>Create Account</h2>
//           <form onSubmit={this.handleSubmit} noValidate>

//             <div className='email'>
//               <label htmlFor="email">Email</label>
//               <input type='email' name='email' onChange={this.handleChange} noValidate />
//               {errors.email.length > 0 && 
//                 <span className='error'>{errors.email}</span>}
//             </div>
//             <div className='password'>
//               <label htmlFor="password">Password</label>
//               <input type='password' name='password' onChange={this.handleChange} noValidate />
//               {errors.password.length > 0 && 
//                 <span className='error'>{errors.password}</span>}
//             </div>
//             <div className='submit'>
//               <button>Create</button>
//             </div>
//           </form>
//         </div>
//       </div>
//     );
//   }
// }