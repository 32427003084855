import React, { useState } from "react";
import { useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import hull from "hull.js";
import Autocomplete from "react-google-autocomplete";
import {
  GoogleMap,
  withScriptjs,
  withGoogleMap,
  Marker,
  InfoWindow,
  Polygon,
} from "react-google-maps";
import MapStyles from "./MapStyles";
function Map({ searchedCenterLagLng, filteredList }) {
  const options = {
    fillColor: "#00000055",
    fillOpacity: 0.1,
    strokeColor: "green",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: false,
    draggable: false,
    editable: false,
    geodesic: false,
    zIndex: 1,
  };

  const [showInfo, setshowInfo] = useState(null);
  const [Listing, setListing] = useState([]);
  const [LatLong, setLatLong] = useState([]);
  const [Lat, setlat] = useState(searchedCenterLagLng.searchedLat || 31.5204);
  const [Lng, setLng] = useState(searchedCenterLagLng.searchedLng || 74.3587);
  const [calculatedHull, setCalculatedHull] = useState([]);
  const center = { lat: Lat, lng: Lng };
  const [pointList, setPointList] = useState(filteredList);
  console.log("pointList", pointList);
  useEffect(() => {
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/GetAllLatitudeAndLongitude",
    })
      .then((response) => {
        console.log("latlong is here", response.data.data);
        setLatLong(response.data.data);
      })
      .catch((error) => {});
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/get_listings",
    })
      .then((response) => {
        console.log("res is here", response.data.data);
        setListing(response.data.data);
      })
      .catch((error) => {});
  }, []);

  useEffect(() => {
    const needToCalculateHull = LatLong.map((itm) => {
      return [itm.latitude, itm.longitude];
    });

    const convertedHullData =
      LatLong.length != 0 && hull(needToCalculateHull, 50);

    const data =
      LatLong.length != 0 &&
      convertedHullData?.map((item) => {
        return { lat: item[0], lng: item[1] };
      });

    setCalculatedHull(data);
  }, [LatLong]);

  const onPlaceSelected = (place) => {
    setlat(place.geometry.location.lat());
    setLng(place.geometry.location.lng());
  };

  return (
    <>
      <Autocomplete
        style={{
          width: "100%",
          height: "40px",
          paddingleft: "16px",
          marginTop: "2px",
        }}
        onPlaceSelected={onPlaceSelected}
      />
      <GoogleMap
        defaultOptions={{ styles: MapStyles }}
        defaultZoom={10}
        center={center}
      >
        {filteredList.map((item, index) => {
          return (
            <>
              <Marker
                key={item.id}
                position={{ lat: item.latitude, lng: item.longitude }}
                defaultPosition={{ lat: 31.5204, lng: 74.3587 }}
                onClick={() => {
                  setshowInfo(item);
                }}
              />
            </>
          );
        })}
        {/* <Polygon paths={calculatedHull} options={options} /> */}
        {showInfo && (
          <InfoWindow
            position={{ lat: showInfo.latitude, lng: showInfo.longitude }}
            onCloseClick={() => {
              setshowInfo(null);
            }}
          >
            <div>{showInfo.cityName}</div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );
}

const WrappedMap = withScriptjs(withGoogleMap((props) => <Map {...props} />));

export default function NewGoogleMap({ searchedCenterLagLng, filteredList }) {
  return (
    <div style={{ width: "60vw", height: "75vh" }}>
      <WrappedMap
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=AIzaSyD2jf46O3SfVWbAfraUWP8fOxptp7Vq0ho&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: "100%" }} />}
        searchedCenterLagLng={searchedCenterLagLng}
        filteredList={filteredList}
        containerElement={
          <div
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column-reverse",
            }}
          />
        }
        mapElement={<div style={{ height: "100%" }} />}
      />
    </div>
  );
}
