import React, { Component, useState } from "react";
import Button from "../../common/Button/Button";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import {
  disableScroll,
  enableScroll,
} from "../../common/commonFunctions/commonFunctions";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";


class Register extends Component {
  state = {
    registerName: "",
    registerEmail: "",
    registerPassword: "",
    registerPhoneNumber: "",
    errorMessage: "",
    successMessage: "",
    disableButton: false,
    isSuccess: false,
    isShowPassword: true,
    isLoader: false,
  };

  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator();
  }

  showPassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };

  signUp = () => {
    const regMail = /^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$/

    if (this.state.registerName === "") {
      this.setState({
        errorMessage: "Enter name",
        isSuccess: false,
      });
    }
    
    else if (this.state.registerEmail==="") {
      this.setState({
        errorMessage: "Enter email",
        isSuccess: false,
      })
    }
    else if (!this.state.registerEmail.match(regMail)) {
      this.setState({
        errorMessage: "Invalid email",
        isSuccess: false,
      })
    } else if (this.state.registerPassword === "") {
      this.setState({
        errorMessage: "Enter password",
        isSuccess: false,
      });
    } else if (this.state.registerPhoneNumber === "") {
      this.setState({
        errorMessage: "Enter phone number",
        isSuccess: false,
      });
    } else if (
      (this.validator.fieldValid("name"),
        this.validator.fieldValid("email"),
        this.validator.fieldValid("password"),
        this.validator.fieldValid("phonenumber"))
    ) {
      this.setState({ disableButton: true, isLoader: true });
      disableScroll();
      axios({
        method: "post",
        url: baseURL + "api/v1/Auth/signup",
        data: {
          name: this.state.registerName,
          email: this.state.registerEmail,
          password: this.state.registerPassword,
          phoneNumber: this.state.registerPhoneNumber,
        },
      })
        .then((response) => {
          this.setState({ disableButton: false, isLoader: false });
          enableScroll();
          this.setState({
            successMessage: response.data.data,
            isSuccess: response.data.isSuccess,
          });
        })

        .catch((error) => {
          this.setState({ disableButton: false, isLoader: false });
          enableScroll();
          this.setState({
            errorMessage: error.response.data.error.errorMessage,
            isSuccess: false,
          });
        });
    } else {
      this.setState({ disableButton: false, isLoader: false });
      enableScroll();
      this.validator.showMessages();
      // rerender to show messages for the first time
      // you can use the autoForceUpdate option to do this automatically`
      this.forceUpdate();
    }


  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });

  };
  checkmail = mail => {
    const regex = new RegExp("^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$")
    return regex.test(mail);
  }
  render() {
    return (
      <div class="modal-body ">
        <div className={this.state.isLoader ? "loader-bg" : ""}></div>
        {this.state.isSuccess ? (
          <>
            <strong>
              <h4>
                <p class="login-or">{this.state.successMessage}</p>
              </h4>
            </strong>
            <button
              type="button"
              style={{ backgroundColor: "#C17400", border: "1px solid #C17400" }}
              class="btn btn-primary"
              data-dismiss="modal"
              aria-label="Close"
            >
              ok
            </button>
            <div class="mt-3 mb-3"></div>
          </>
        ) : (
          <>
            {" "}
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>{" "}
            <div className="modal-title">
              {" "}
              <h1>
                Register<span class="special-color">.</span>
              </h1>
              <div class="modal-short-title-separator"></div>
            </div>
            <input
              name="registerName"
              type="text"
              class="input-full main-input"
              placeholder="Name"
              onChange={this.handleChange}
            />
            {this.validator.message(
              "name",
              this.state.registerName,
              "required|alpha",
              {
                className: "text-danger",
              }
            )}
            <input
              name="registerEmail"
              type="email"

              class="input-full main-input"
              placeholder="Email"
              onChange={this.handleChange}
            />
            {/* {this.validator.message(
              "email",
              this.state.registerEmail,
              "required|email",
              {
                className: "text-danger",
              }
            )} */}
            <div className="showPassword">
              <input
                name="registerPassword"
                type={this.state.isShowPassword ? "password" : "text"}
                className="input-full main-input"
                placeholder="Password"
                onChange={this.handleChange}
                required
              />
              {this.state.isShowPassword ? (
                <i
                  className="fas fa-eye"
                  onClick={() => this.showPassword()}
                ></i>
              ) : (
                <i
                  className="fas fa-eye-slash"
                  onClick={() => this.showPassword()}
                ></i>
              )}
            </div>
            {this.validator.message(
              "password",
              this.state.registerPassword,
              "required|min:5|max:20",
              {
                className: "text-danger",
              }
            )}
            <PhoneInput
              country={"us"}
              value={this.state.registerPhoneNumber}
              className="input-full main-input inputStyle"
              onChange={(phone) =>
                this.setState({ registerPhoneNumber: phone })
              }
              inputStyle={{
                width: "100%",
                borderRadius: "unset",
                height: " 50px",
                lineHeight: "40px",
                fontSize: "1.08em",
                marginBottom: "15px",
                fontWeight: "bold",
              }}
              required
            />
            {this.validator.message(
              "phonenumber",
              this.state.registerPhoneNumber,
              "required|Phone",
              {
                className: "text-danger",
              }
            )}
            {this.state.isSuccess ? null : (
              <div className="text-danger">{this.state.errorMessage}</div>
            )}{" "}
            <button
              class="button-primary button-shadow button-full"
              onClick={() => this.signUp()}

            >
              <span>SIGN UP</span>
              <div class="button-triangle"></div>
              <div class="button-triangle2"></div>
              <div class="button-icon">
                <i class="fa fa-user"></i>
              </div>
            </button>
            <div class="clearfix"></div>
            <p class="login-or">OR</p>
            <div class="mt-3 mb-3"></div>
            <p class="modal-bottom">
              Already registered?{" "}
              <a
                href="#"
                data-toggle="modal"
                data-target="#login-modal"
                data-dismiss="modal"
                aria-label="Close"
                class="login-link"
              >
                LOGIN
              </a>
            </p>
          </>
        )}
      </div>
    );
  }
}

export default Register;
