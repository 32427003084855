import React, { useEffect, useState } from "react";
import {
  openModal,
  enableScroll,
  disableScroll,
} from "../../common/commonFunctions/commonFunctions";
import { Link, useParams } from "react-router-dom";
import { baseURL } from "../../../ApiURL/ApiURL";
import axios from "axios";
import "./VerifyEmail.css";
function VerifyEmail(props) {
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  var params = useParams();
  useEffect(() => {
    setLoader(true)
    disableScroll();
    axios({
      method: "post",
      url: baseURL + "api/v1/Auth/VerifyEmailToken",
      data: {
        token: params.id,
      },
    })
      .then((response) => {
        setLoader(false)
        setMessage("Your Email has been Verified.");
      })
      .catch((error) => {
        setLoader(false);
        enableScroll();
        setMessage("Email link is not valid.");
      })}, []);
  const handleClick = () => {
    setTimeout(() => {
      openModal("login-modal");
    }, 1000);
  };
  return (
    <>
      <div className={loader ? "loader-bg" : ""}></div>
      <section class="short-image no-padding blog-short-title">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-lg-12 short-image-title">

              <h1 class="second-color">EMAIL VERIFICATION</h1>
              <div class="short-title-separator"></div>
            </div>
          </div>
        </div>
      </section>
      <section class="section-light section-top-shadow">
        <div class="container">
          <div class="row">
            <div class="col-xs-12">
              <h1 class="error-subtitle-verify text-color4" style={{color:"#C17400"}}  >{message}</h1>

              <h5>
                {" "}
                <p class="centered-text">
                  Go to our{" "}
                  <strong>
                    <Link to="/home" style={{color:"#C17400"}}> HOMEPAGE</Link>
                  </strong>{" "}
                  or{" "}
                  <strong>
                    {" "}
                    <Link to="/" className="login-here" onClick={() => handleClick()} style={{color:"#C17400"}}> login here</Link>{" "}
                  </strong>
                  .
                </p>
              </h5>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default VerifyEmail;
