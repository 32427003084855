import React, { Component } from "react";
import AdvSearchSection from "../AdvSearchSection/AdvSearchSection";
import FavouriteListing from "../FavouriteListing/FavouriteListing";
import SectionLight from "../SectionLight/SectionLight";
import Footer from "../Footer/Footer";
import { openModal } from "../../common/commonFunctions/commonFunctions";
import { baseURL } from "../../../ApiURL/ApiURL";
import axios from "axios";

class Favourite extends Component {
  userId = "";
  constructor(props) {
    super(props);
    this.userId = props.location.pathname.split("home")[1];
    console.log("id is User id", this.userId);
  }
  componentDidMount() {
    axios({
      method: "POST",
      url: `${baseURL}/api/v1/Auth/VerifyEmailToken${this.userId}`,
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
      },
    })
      .then((res) => {})
      .catch((error) => {});
  }

  componentDidMount(props) {
    var url = this.props.location;

    console.log("url is here", url);

    window.localStorage.removeItem("path");

    if (this.props.location.state) {
      setTimeout(() => {
        openModal("login-modal");
      }, 1000);
      localStorage.setItem("path", this.props.location.state.referrer);
    }
  }
  render() {
    return (
      <>
        {/* <AdvSearchSection /> */}
        <FavouriteListing />
      </>
    );
  }
}

export default Favourite;
