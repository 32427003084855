import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import BannerCard from "../../common/BannerCard/BannerCard";
import CustomizedRange from "../../common/CustomizedRange/CustomizedRange";
import "rc-slider/assets/index.css";
// import SearchIcon from '@material-ui/icons/Search';
import Modal from "react-responsive-modal";
import RangeSlider from "rc-slider";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import queryString from "query-string";
import { withRouter, Link } from "react-router-dom";
import Data from "../../../GeoData.json";
import Default from "./../../../assets/images/gallery/gallery2.jpg";
import "./AdvSearchSection.css";
import Autocomplete from 'react-google-autocomplete';
import   MySlider from '@material-ui/core/Slider';

const { Range } = RangeSlider;
const style = { width: 400, margin: 50 };
const propertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial Plot" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
];
class AdvSearchSection extends Component {
  state = {
    Listings: "",
    image1: "",
    image2: "",
    image3: "",
    PropertyTypeId: "",
    DistrictName: "",
    cityNames: "",
    PropertyStatus: "",
    minAmount: 0,
    maxAmount: 1000000000000000,
    CityName: "",
    CountryName: "",
    TransactionTypes: "",
    isLoader: false,
    openModel:false,

  };
  constructor(props) {
    super(props);
    this.state = {
      Listings: "",
      image1: "",
      image2: "",
      image3: "",
      PropertyTypeId: "",
      DistrictName: "",
      PropertyStatus: "",
      value: [0, 1000000],
      Kitchen:"",
      TransactionType: "",
      PropertyTypeId: "",
      CountryName: "",
      Bedroom:"",
      Bathroom:"",
      ATransactionType:"",
      APropertyTypeId:"",
      ACountryName:"",
      AKitchen:"",
      ABathroom:"",
      ABedroom:"",
      TransactionTypes: "",
      LatValue:"",
      LngValue:"",
      isLoader: false,
    };
  }
  changeSlideNext = () => {
    this.slider.slickNext();
  };
  changeSlidePrev = () => {
    this.slider.slickPrev();
  };

  componentDidMount() {
    let array = [];
    let arraySliced = [];
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/get_listings",
    })
      .then((response) => {
        this.setState({ isLoader: false });
        array = response.data.data;
        arraySliced = array.slice(Math.max(array.length - 3, 0));
        let image1 = "";
        let image2;
        let image3;
        image1 = arraySliced[0].propertyMedia[0].mediaUrl;
        image2 = arraySliced[1].propertyMedia[0].mediaUrl;
        image3 = arraySliced[2].propertyMedia[0].mediaUrl;
        this.setState(
          {
            Listings: arraySliced,
            image1: image1,
            image2: image2,
            image3: image3,
          },
          () => { }
        );
      })
      .catch((error) => {
        this.setState({ isLoader: false });
      });
  }
  submitSearch = () => {
    console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
    this.setState({ isLoader: true });
        axios({
          method: "get",
          url:
            baseURL +
    
    `api/v1/Property/get_listings?MinimumAmount=${this.state.value[0]}&MaximumAmount"${this.state.value[1]}${this.state.ATransactionType}${this.state.TransactionType}${this.state.APropertyTypeId}${this.state.PropertyTypeId}
    ${this.state.ACountryName}${this.state.CountryName}${this.state.AKitchen}${this.state.Kitchen}${this.state.ABathroom}${this.state.Bathroom}${this.state.ABedroom}${this.state.Bedroom}
    `})
          .then((response) => {
            this.setState({ isLoader: false });
            // enableScroll()
            let array = response.data.data;
            let arraySliced = array.slice(0, array.length - 5);
            this.setState({ Listings:array });
            window.scroll({
              top: 800, 
              left: 0, 
              behavior: 'smooth' 
             });
             this.setState({openModel:false})
          })
          .catch((error) => {
            this.setState({ isLoader: false });
            // enableScroll()
          });
      }
      handleListingChange = (e) => {
    
        if (e.target.name === "TransactionTypes") {
          this.setState({ATransactionType:"&TransactionType="})
          this.setState({ TransactionType: e.target.value })
          if(this.state.TransactionType==="")
          {
            this.setState({ATransactionType:""})
          }
        }
        else if (e.target.name === "PropertyTypeId") {
          this.setState({APropertyTypeId:"&PropertyTypeId="})
          this.setState({ PropertyTypeId: e.target.value })
          if(this.state.PropertyTypeId==="")
          {
            this.setState({APropertyTypeId:""})
          }
        }
        else if (e.target.name === "CountryName") {
          this.setState({ACountryName:"&CountryName="})
          this.setState({ CountryName: e.target.value })
          if(this.state.CountryName==="")
          {
            this.setState({ACountryName:""})
          }
        }
        else if(e.target.name==="maxKitchen"){
          this.setState({AKitchen:"&Kitchen="})
          this.setState({Kitchen:e.target.value})
          if(this.state.Kitchen==="")
          {
            this.setState({AKitchen:""})
          }
        }
        else if(e.target.name==="maxBed"){
          this.setState({ABedroom:"&Bedroom="})
          this.setState({Bedroom:e.target.value})
          if(this.state.Bedroom){
            this.setState({ABedroom:""})
          }
        }
        else if(e.target.name==="maxBath"){
          this.setState({ABathroom:"&Bathroom="})
          this.setState({Bathroom:e.target.value})
          if(this.state.Bathroom){
            this.setState({ABathroom:""})
          }
        }
      }
  onHandleCountryChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      CityName: "",
      DistrictName: "",
    });
    let array = [];
    let cityNames = [];
    array = Data.filter(
      (countryName) => countryName.name == event.target.value
    ).map((DistrictName) => DistrictName.states)[0];

    for (let i = 0; i < array.length; i++) {
      array[i].cities.map((state) => {
        return (cityNames = [...cityNames, state.name]);
      });
    }
    console.log("array is ", cityNames.sort());
    this.setState({ cityNames: cityNames.sort() });
  };
  onHandleDistrictChange = (event) => {
    this.setState({ [event.target.name]: event.target.value, CityName: "" });
  };
  onHandleCityChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onSliderChange = (value) => {
    this.setState({ minAmount: value[0], maxAmount: value[1] });
  };
  onSliderChangeBed = (value) => {
    this.setState({ minBed: value[0], maxBed: value[1] });
  };
  onSliderChangeKitchen = (value) => {
    this.setState({ minKitchen: value[0], maxKitchen: value[1] });
  };
  onSliderChangeBath = (value) => {
    this.setState({ minBath: value[0], maxBath: value[1] });
  };
  onSubmit = (e) => {
    e.preventDefault();

    this.props.history.push({
      pathname: "/listing",
      search:
        "Country=" +
        this.state.CountryName +
        "&City=" +
        this.state.CityName +
        "&TransactionType=" +
        this.state.TransactionTypes +
        "&PropertyTypeID=" +
        this.state.PropertyTypeId +
        "&MaximumAmount=" +
        this.state.maxAmount +
        "&MinimumAmount=" +
        this.state.minAmount +
        "&Kitchen=" +
        this.state.maxKitchen +
        "&Bedroom=" +
        this.state.maxBed +
        "&Bathroom=" +
        this.state.maxBath,
    });
  };
 
setModel=()=>{
  this.setState({openModel:true})
}
closeModal=()=>{
  this.setState({openModel:false})
}

	onPlaceSelected = (place) => {
		console.log('plc', place);
    alert("hello")
   this.props.history.push({
    pathname: `/Marketplace`,
    state: {name:"jibran",latitude:place.geometry.location.lat(),longitude:place.geometry.location.lng()
  }
 });
	};

  handleChange = (event, newValue) => {
    this.setState({ value: newValue })
  };
  render() {
    var settings = {
      arrows: false,
      infinite: true,
      speed: 1000,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: true,
      autoplaySpeed: 4000,
    };
    return (

      <div className="landing-header-section">
<Modal classNames="modal-width" open={this.state.openModel} onClose={this.closeModal} center>

<div className="accept-modal">
  {" "}
  <div class="modal-body">
    {" "}
    <section className="section-light top-padding-60 location-pnl bottom-padding-60" >
          <div className="transaction-frame-pnl">
            <iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.1583091352!2d-74.11976373946234!3d40.69766374859258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1642745113772!5m2!1sen!2s"}></iframe>
          </div>
          <form class="adv-search-form" onSubmit={(e)=>{e.preventDefault()}}>
            <div class="adv-search-cont">
              <div class="pd-div">
                <div class="row">

                  <div class="col-xs-12 col-lg-12 col-md-12 text-center">
                    <h1>Location & Neighbouring</h1>
                    
                  </div>
                </div>
              </div>
              <div class="pd-div margin-top-60">
                <div class="row tab-content">
                  <div
                    role="tabpanel"
                    class="col-xs-12 adv-search-outer tab-pane fade in active"
                    id="apartments"
                  >
                    <div class="row">
                      <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 select-field">
                        <label>Transaction Type</label>
                        <select
                          name="TransactionTypes"
                          className="form-control input-full main-input property-select"
                          id="TransactionTypes"
                          // onChange={this.onHandleChange}
                          onChange={this.handleListingChange}


                        >
                          {" "}
                          <option value="">Select Transaction Type</option>
                          <option value="Sale">Sale</option>
                          <option value="Rent">Rent</option>
                        </select>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-3  col-lg-3 select-field">
                        <label>Property Type</label>
                        <select
                          name="PropertyTypeId"
                          className="form-control input-full main-input property-select"
                          onChange={this.handleListingChange}
                        >
                          <option value="">Select Property Type</option>
                          <option value="1">House</option>
                          <option value="2">Farm House</option>
                          <option value="3">Penthouse</option>
                          <option value="4">Flat</option>
                          <option value="5">Commercial Plot</option>
                          <option value="6">Agricultural Land</option>
                          <option value="7">Residential Plot</option>
                          <option value="8">Office</option>
                          <option value="9">Warehouse</option>
                          <option value="10">Building</option>
                          <option value="11">Shop</option>
                          <option value="12">Factory</option>
                        </select>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-lg-3 col-md-3  select-field">
                        <label>Country Name</label>
                        <select
                          name="CountryName"
                          className="form-control input-full main-input property-select"
                          onChange={this.handleListingChange}
                        >
                          <option value="">Select Country</option>
                          {Data.map((countryName) => (
                            <option value={countryName.name}>
                              {countryName.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-xs-12 col-sm-6 slider col-md-3  col-lg-3">
                        <div class="adv-search-range-cont">
                          <label
                            for="slider-range-price1-value"
                            class="adv-search-label pull-left"
                          >
                            Amount:
                          </label>
                          <span className="pull-right" style={{ color: "white" }}>min-{this.state.value[0]}$ max-{this.state.value[1]}$</span>
                          <div style={{ marginTop: "50px" }}>
                            {/* <Slider
                            value={value}
                            onChange={handleChange}
                            // valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            getAriaValueText={valuetext}
                          /> */}

                            <MySlider
                            min={0}
                            max={1000000}
                            value={this.state.value}
                            onChange={this.handleChange}
                            />
                          </div>
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-3  col-lg-3">
                        <div class="adv-search-range-cont">
                          <label
                            for="slider-range-price1-value"
                            class="adv-search-label pull-left"
                          >
                            Kitchen:
                          </label>
                          <select
                            name="maxKitchen"
                            className="form-control input-full main-input property-select"
                            id="maxKitchen"
                          onChange={this.handleListingChange}
                          >
                            {" "}
                            <option value="">Select </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                          {/* <span className="pull-right"></span>
                        <input
                          type="text"
                          id="slider-range-price1-value"
                          placeholder={
                            this.state.minKitchen + "-" + this.state.maxKitchen
                          }
                          readonly
                          class="adv-search-amount"
                        />
                        <div class="clearfix"></div>

                        <Range
                          onChange={this.onSliderChangeKitchen}
                          min={0}
                          max={10}
                        /> */}
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-3  col-lg-3">
                        <div class="adv-search-range-cont">
                          <label
                            for="slider-range-price1-value"
                            class="adv-search-label pull-left"
                          >
                            Bedrooms:
                          </label>
                          <select
                            name="maxBed"
                            className="form-control input-full main-input property-select"
                            id="maxBed"
                          onChange={this.handleListingChange}
                          >
                            {" "}
                            <option value="">Select </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                          {/* <span className="pull-right"></span>
                        <input
                          type="text"
                          id="slider-range-price1-value"
                          placeholder={
                            this.state.minBed + "-" + this.state.maxBed
                          }
                          readonly
                          class="adv-search-amount"
                        />
                        <div class="clearfix"></div>

                        <Range
                          onChange={this.onSliderChangeBed}
                          min={0}
                          max={10}
                        /> */}
                        </div>
                      </div>
                      <div class="col-xs-12 col-sm-6 col-md-3  col-lg-3">
                        <div class="adv-search-range-cont">
                          <label
                            for="slider-range-price1-value"
                            class="adv-search-label pull-left"
                          >
                            Bathrooms:
                          </label>
                          <select
                            name="maxBath"
                            className="form-control input-full main-input property-select"
                            id="maxBath"
                          onChange={this.handleListingChange}
                          >
                            {" "}
                            <option value="">Select </option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3</option>
                            <option value="4">4</option>
                            <option value="5">5</option>
                            <option value="6">6</option>
                            <option value="7">7</option>
                            <option value="8">8</option>
                            <option value="9">9</option>
                            <option value="10">10</option>
                          </select>
                          {/* <span className="pull-right"></span>
                        <input
                          type="text"
                          id="slider-range-price1-value"
                          placeholder={
                            this.state.minBath + "-" + this.state.maxBath
                          }
                          readonly
                          class="adv-search-amount"
                        />
                        <div class="clearfix"></div>

                        <Range
                          onChange={this.onSliderChangeBath}
                          min={0}
                          max={10}
                        /> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="container">
                <div class="row">
                  <div class="col-sm-12 col-md-6 col-lg-3 col-md-offset-6 col-lg-offset-9 adv-search-button-cont">
                    <button
                      onClick={() => { this.submitSearch() }}
                      class="button-primary pull-right"
                    >
                      <span>search</span>
                      <div class="button-triangle"></div>
                      <div class="button-triangle2"></div>
                      <div class="button-icon">
                        <i class="fa fa-search"></i>
                      </div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>

        </section>
   
  </div>
  <div className="Modal-footer">

  </div>
</div>


</Modal>


        <div className="bg-layer setSearch">
       
         <div className="">
           <h1 style={{color:"white"}} className="text-center">Find Property</h1>
         <div style={{marginTop:"50px"}}>
         <button
          className="btn btn-primary"
                  >
                    <span>Buy</span>
                  </button>
                  <button
          className="btn btn-primary"
                  >
                    <span>Rent</span>
                  </button>
                  <button
          className="btn btn-primary"
                  >
                    <span>Sold</span>
                  </button>
                  <button
          className="btn btn-primary"
                  >
                    <span>Commercial</span>
                  </button>
         </div>
          {/* <button style={{color:"black"}}>buy</button>
          <button style={{color:"black"}}>rent</button> */}
      <div>
        
      <Autocomplete className="input-search-bg"
							style={{
								width:"500px",
								height: '60px',
								paddingLeft: '16px',
								marginTop: '2px',
							}}
              forcePopupIcon={true}
              popupIcon={<div class="button-icon">
              <i class="fa fa-search"></i>
            </div>}
							onPlaceSelected={this.onPlaceSelected}
              placeholder="Enter an address,neighborhood,city or ZIP code"
							types={['(regions)']}
						/>
      </div>
      <div>
      <button
                      class="button-primary"
                      onClick={this.setModel}
                    >
                      <span>Advance Search</span>
                      <div class="button-triangle"></div>
                      <div class="button-triangle2"></div>
                      <div class="button-icon">
                        <i class="fa fa-search"></i>
                      </div>
                    </button>

      </div>
         </div>
        </div>

        {/* <div className="landing-bar">
          <div className="landing-bar-inner">
            <ul className="bar-list">
              <li>
                <div className="table">
                  <div className="table-cell">
                    <div className="txt-div">
                      <p>178, Lake View New York, Ny- 1004</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="table">
                  <div className="table-cell">
                  <div className="txt-div">
                    <p>2 Bedrooms</p>
                    <p>3 Bathrooms</p>
                    <p>35 Sqr.Ft</p>
                  </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="table">
                  <div className="table-cell">
                  <div className="txt-div">
                    <h1>
                      <span>Price:</span>
                      $ 35,000
                    </h1>
                  </div>
                  </div>
                </div>
                
              </li>
            </ul>
          </div>
       
        </div> */}
      </div>
      // <section class="no-padding adv-search-section">
      //   <Slider
      //     ref={(c) => (this.slider = c)}
      //     {...settings}
      //     className="swiper"
      //     style={{ height: "761px", width: "100%" }}
      //   >
      //     <BannerCard
      //       slideClass=" col-xs-12 col-sm-10 col-md-8 col-lg-6 col-sm-offset-1 col-md-offset-2  col-lg-offset-3 animated fadeInDown1 fadeOutDown1"
      //       imageUrl={this.state.image1}
      //       listing={this.state.Listings[0]}
      //       changeSlideNext={this.changeSlideNext}
      //       changeSlidePrev={this.changeSlidePrev}
      //       style={{ height: "inherit" }}
      //     />

      //     <BannerCard
      //       slideClass="col-xs-12 col-sm-10 col-md-8 col-lg-6 col-sm-offset-2 col-md-offset-4 col-lg-offset-6   animated fadeInDown1 fadeOutDown1   "
      //       changeSlideNext={this.changeSlideNext}
      //       imageUrl={this.state.image2}
      //       listing={this.state.Listings[1]}
      //       changeSlidePrev={this.changeSlidePrev}
      //       style={{ height: "inherit" }}
      //     />
      //     <BannerCard
      //       slideClass="col-xs-12 col-sm-10 col-md-8 col-lg-6 offset-sm-2 offset-md-4  offset-lg-6 animated fadeInDown1 fadeOutDown1 "
      //       imageUrl={this.state.image3}
      //       listing={this.state.Listings[2]}
      //       changeSlideNext={this.changeSlideNext}
      //       changeSlidePrev={this.changeSlidePrev}
      //       style={{ height: "inherit" }}
      //     />
      //   </Slider>

      // </section>
    );
  }
}

export default withRouter(AdvSearchSection);
