import React, { Component } from "react";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ListingCard from "./../../common/ListingCard/ListingCard";
import Data from "../../../GeoData.json";
import ListingImage from "./../../../assets/images/grid-offer2.jpg";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import MySlider from "@material-ui/core/Slider";
import { withRouter } from "react-router-dom";
import DefaultImage from "../../../assets/images/grid-offer1.jpg";
import GoogleMapReact from "google-map-react";
import {
  disableScroll,
  enableScroll,
  getToken,
} from "../../common/commonFunctions/commonFunctions";
import { toast } from "react-toastify";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const PropertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial Plot" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
];
class NewListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Listings: [],
      myFavouritProperty: [],
      value: [0, 1000000],
      Kitchen: "",
      TransactionType: "",
      PropertyTypeId: "",
      CountryName: "",
      Bedroom: "",
      Bathroom: "",
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      isLoader: false,
    };
  }
  componentDidMount() {
    let array = [];
    let arraySliced = [];

    this.setState({ isLoader: true });
    disableScroll();

    axios({
      method: "get",
      url: baseURL + "api/v1/Property/get_listings",
    })
      .then((response) => {
        this.setState({ isLoader: false });
        enableScroll();
        array = response.data.data;
        arraySliced = array.slice(0, array.length - 5);
        this.setState({ Listings: arraySliced }, () => {});
      })
      .catch((error) => {
        this.setState({ isLoader: false });
        enableScroll();
      });
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        console.log("my favourite property ", response.data.data);
        this.setState({ myFavouritProperty: response.data.data });
      })
      .catch((error) => {
        console.log("errrrrrrrrrrr", error);
      });
  }

  submitSearch = () => {
    console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj");
    this.setState({ isLoader: true });
    axios({
      method: "get",
      url:
        baseURL +
        "api/v1/Property/get_listings?" +
        "TransactionType=" +
        this.state.TransactionType +
        "&" +
        "PropertyTypeId=" +
        this.state.PropertyTypeId +
        "&" +
        "CountryName=" +
        this.state.CountryName +
        "&" +
        "MinimumAmount=" +
        this.state.value[0] +
        "&" +
        "MaximumAmount=" +
        this.state.value[1] +
        "&" +
        "Kitchen=" +
        this.state.Kitchen +
        "&" +
        "Bedroom=" +
        this.state.Bedroom +
        "&" +
        "Bathroom=" +
        this.state.Bathroom,
    })
      .then((response) => {
        this.setState({ isLoader: false });
        enableScroll();
        let array = response.data.data;
        let arraySliced = array.slice(0, array.length - 5);
        this.setState({ Listings: array });
      })
      .catch((error) => {
        this.setState({ isLoader: false });
        enableScroll();
      });
  };

  // const [value, setValue] = React.useState([0, 1000000]);

  handleChange = (event, newValue) => {
    this.setState({ value: newValue });
  };

  handleListingChange = (e) => {
    if (e.target.name === "TransactionTypes") {
      this.setState({ TransactionType: e.target.value });
    } else if (e.target.name === "PropertyTypeId") {
      this.setState({ PropertyTypeId: e.target.value });
    } else if (e.target.name === "CountryName") {
      this.setState({ CountryName: e.target.value });
    } else if (e.target.name === "maxKitchen") {
      this.setState({ Kitchen: e.target.value });
    } else if (e.target.name === "maxBed") {
      this.setState({ Bedroom: e.target.value });
    } else if (e.target.name === "maxBath") {
      this.setState({ Bathroom: e.target.value });
    }
  };

  handleButton = (e) => {
    this.props.history.push({
      pathname: "/listingdetail",
      search: "selectedList=" + e,
    });
    window.location.reload()
  };

  gotoNext = () => {
    this.slider.slickNext();
  };
  gotoPrev = () => {
    this.slider.slickPrev();
  };

  favouritProperty = (id) => {
    toast.success("Added in favourite");
    axios({
      method: "post",
      url: baseURL + "api/v1/Property/AddFavouriteProperty",
      data: {
        propertyId: id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("my favourite property ", response.data.data);
            this.setState({ myFavouritProperty: response.data.data });
          })
          .catch((error) => {
            console.log("errrrrrrrrrrr", error);
          });
        console.log("remove");
        this.setState({ favouritColor: "red" });
      })
      .catch((error) => {
        console.log("errrrrrrr", error);
      });
  };
  removeFavouritProperty = (id) => {
    toast.error("Removed from favourite");
    axios({
      method: "put",
      url: baseURL + "api/v1/Property/RemoveFavouriteProperty",
      data: {
        propertyId: id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("my favourite property ", response.data.data);
            this.setState({ myFavouritProperty: response.data.data });
          })
          .catch((error) => {
            console.log("errrrrrrrrrrr", error);
          });

        console.log("remove");
        this.setState({ favouritColor: "red" });
      })
      .catch((error) => {
        console.log("errrrrrrr", error);
      });
  };

  render() {
    var settings = {
      arrows: false,
      infinite: true,
      speed: 500,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 2024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 4,
            infinite: true,
            dots: true,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 2,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <>
        {this.state.Listings.length === 0 ? (
          <h1 className="text-center mt-5">no data found</h1>
        ) : (
          ""
        )}
        <section
          className="section-light 
         villa-section  carosuile-section"
        >
          <div className={this.state.isLoader ? "loader-bg" : ""}></div>
          <div className="grid-offers-container slider-cntnr">
            <a
              onClick={() => this.gotoPrev()}
              className="navigation-box navigation-box-prev"
              id="grid-offers-owl-prev"
            >
              <div className="navigation-triangle"></div>
              <div className="navigation-box-icon">
                <i className="fas fa-angle-left"></i>
              </div>
            </a>
            <a
              className="navigation-box navigation-box-next"
              id="grid-offers-owl-next"
              onClick={() => this.gotoNext()}
            >
              <div className="navigation-triangle"></div>
              <div className="navigation-box-icon">
                <i className="fas fa-angle-right"></i>
              </div>
            </a>

            <Slider
              ref={(c) => (this.slider = c)}
              style={{ width: "95%", margin: "auto" }}
              {...settings}
            >
              {this.state.Listings.map((list) => (
                <ListingCard
                  ImageURL={
                    list.propertyMedia === null ? (
                      <img height="175px" src={DefaultImage} />
                    ) : list.propertyMedia.filter(
                        (item) => item.isThumbnail === true
                      ).length <= 0 ? (
                      <img
                        onClick={() => this.handleButton(list.uuid)}
                        height="175px"
                        src={
                          baseURL +
                          list.propertyMedia.filter(
                            (item) => item.isThumbnail === false
                          )[0]?.mediaUrl
                        }
                      />
                    ) : (
                      list.propertyMedia
                        .filter((item) => item.isThumbnail === true)
                        .map((data) => (
                          <img height="175px" src={baseURL + data.mediaUrl} />
                        ))
                    )
                  }
                  PropertyType={PropertyType.filter(
                    (item) => item.id === list.propertyTypeId
                  ).map((id) => id.PropertyTypeName)}
                  TransactionType={list.transactionTypes}
                  LocalAddress={list.locationAddress}
                  Description={list.discription}
                  Price={list.price}
                  PropertyArea={list.propertyArea}
                  Bedrooms={list.bedrooms}
                  Bath={list.fullBaths}
                  uuid={list.uuid}
                  id={list.id}
                  favCount={list.propertyFavouritesCount}
                  handleButton={() => {}}
                  longitude={list.longitude}
                  latitude={list.latitude}
                  ListingData={this.state.Listings}
                  myFavouritProperty={this.state.myFavouritProperty}
                  favouritProperty={this.favouritProperty}
                  removeFavouritProperty={this.removeFavouritProperty}
                />
              ))}
            </Slider>
          </div>
        </section>
      </>
    );
  }
}

export default withRouter(NewListing);
