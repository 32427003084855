import React, { Component } from "react";
import "./../ChangePassword/ChangePassword.css";
import { getToken } from "../../common/commonFunctions/commonFunctions";
import axios from "axios";
import { Link, withRouter } from "react-router-dom";
import { baseURL } from "../../../ApiURL/ApiURL";
import { openModal } from "../../common/commonFunctions/commonFunctions.js";

class ForgotPassword extends Component {
  state = {
    currentPassword: "",
    Message: "",
    errorMessage: "",
    id: "",
    isShowPassword: true,
    isShowConfirmPassword: true,
  };
  componentDidMount() {
    const id = this.props.match.params.id;
    this.setState({ id: id });
    console.log(id);
  }
  showPassword = () => {
    this.setState({ isShowPassword: !this.state.isShowPassword });
  };
  showConfirmPassword = () => {
    this.setState({ isShowConfirmPassword: !this.state.isShowConfirmPassword });
  };
  handleClickLogin = () => {
    setTimeout(() => {
      openModal("login-modal");
    }, 1000);
  };
  handleSubmit = (e) => {
    e.preventDefault();
    this.setState({ isLoader: true });
    if (this.state.newPassword === this.state.confirmPassword) {
      axios({
        method: "POST",
        url: baseURL + "api/v1/Auth/ChangeForgotPassword",
        data: {
          token: this.state.id,
          newPassword: this.state.newPassword,
        },
        headers: {
          "Content-Type": "application/json-patch+json",
          accept: "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => {
          this.setState({ disableButton: false, isLoader: false });
          if (res.data.isSuccess) {
            this.setState({ Message: "Password has been changed" });
          } else {
            this.setState({ Message: res.data.message });
          }
        })
        .catch((error) => {
          this.setState({ disableButton: false, isLoader: false });

          this.setState({
            errorMessage: error.response.data.error.errorMessage,
            isSuccess: false,
          });
        });
    } else {
      this.setState({ isLoader: false });
      this.setState({
        errorMessage: "Passwords Do not Match , Please Try Again ",
      });
    }
  };

  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    return (
      <section class="section-light section-top-shadow change-password">
        <div className={this.state.isLoader ? "loader-bg" : ""}></div>
        <div className="heading">
          <h1>
            Forgot Password
            <span class="special-color">.</span>
          </h1>
        </div>

        <div className="center-div form-container">
          <div className="container-fluid">
            {this.state.Message !== "" ? (
              <>
                {this.state.Message !== "" ? (
                  <strong>
                    <strong>
                      <h4>
                        <p class="login-or">{this.state.Message}</p>
                      </h4>
                    </strong>
                    <div className="login-or">
                      {" "}
                      <Link
                        to="/"
                        className="login-here"
                        onClick={this.handleClickLogin}
                      >
                        Login Here{" "}
                      </Link>
                    </div>
                  </strong>
                ) : null}
                <div class="mt-3 mb-3"></div>
              </>
            ) : (
              <>
                <form onSubmit={this.handleSubmit} class="form-group">
                  <div className="col-md-12">
                    <div className=" form-group input-full main-input">
                      <div className="showPassword">
                        {" "}
                        <input
                          type={this.state.isShowPassword ? "password" : "text"}
                          className="form-control"
                          id="newPassword"
                          name="newPassword"
                          placeholder="New Password"
                          required
                          onChange={this.onHandleChange}
                        />
                        {this.state.isShowPassword ? (
                          <i
                            className="fas fa-eye"
                            onClick={() => this.showPassword()}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash"
                            onClick={() => this.showPassword()}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className=" form-group input-full main-input">
                      <div className="showPassword">
                        {" "}
                        <input
                          type={
                            this.state.isShowConfirmPassword
                              ? "password"
                              : "text"
                          }
                          className="form-control"
                          id="confirmPassword"
                          name="confirmPassword"
                          placeholder="Confirm Password"
                          required
                          onChange={this.onHandleChange}
                        />{" "}
                        {this.state.isShowConfirmPassword ? (
                          <i
                            className="fas fa-eye"
                            onClick={() => this.showConfirmPassword()}
                          ></i>
                        ) : (
                          <i
                            className="fas fa-eye-slash"
                            onClick={() => this.showConfirmPassword()}
                          ></i>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="text-danger"> {this.state.errorMessage} </div>
                  <div className="row margin-top-15">
                    <div className="col-xs-12">
                      <div className="button-width">
                        <button
                          className="button-primary button-shadow button-full"
                          type="submit"
                        >
                          <span>save</span>
                          <div className="button-triangle"></div>
                          <div className="button-triangle2"></div>
                          <div className="button-icon">
                            <i className="fa fa-user"></i>
                          </div>
                        </button>
                      </div>
                      {/* <div className="center-button-cont center-button-cont-border">
                  <button type="submit" class="button-primary button-shadow">
                    <span>save</span>
                    <div className="button-triangle"></div>
                    <div className="button-triangle2"></div>
                    <div className="button-icon">
                      {" "}
                      <i className="fa fa-save"></i>
                    </div>
                  </button>
                </div> */}

                      <div></div>
                    </div>
                  </div>
                </form>
              </>
            )}
          </div>
        </div>
      </section>
    );
  }
}

export default withRouter(ForgotPassword);
