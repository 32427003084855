import React, { useState, useEffect } from "react";

import QR from "react-qr-code";
import "./Wallet.css";
import SendWallet from "../../containers/SendWallet/SendWallet";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { getToken } from "./../../common/commonFunctions/commonFunctions";
import axios from "axios";
import { baseURL } from "./../../../ApiURL/ApiURL";
import DataTable from "react-data-table-component";

const columns = [
  {
    name: "Payment#",
    selector: "uuid",
  },
  {
    name: "Amount",
    selector: "amount",
  },
  {
    name: "Currency",
    selector: "currency.name",
  },
  {
    name: "Date",
    selector: (row) => (
      <div>
        {" "}
        {new Date(row.createdAt).toLocaleDateString("en-us", {
          year: "numeric",
          month: "long",
          day: "numeric",
        })}
      </div>
    ),
  },
  {
    name: "Transaction Type",
    selector: "transactionType",
  },

  {
    name: "Transaction Status",
    selector: "transactionStatus",
  },
];

const Wallet = () => {
  const [isSend, setIsSend] = useState(false);
  const [inputValue, setInputValue] = useState("myaddressIs");
  const [getTransaction, setGetTransaction] = useState([]);
  const [getList, setGetList] = useState([]);
  const [click, setClick] = useState(true);
  const [walletId, setWalletId] = useState("");
  const [currencyName, setCurrencyName] = useState();
  const [currencyImage, setCurrencyImage] = useState();
  const [balance, setBalance] = useState();
  const [date, setDate] = useState("");
  const [isLoader, setIsLoader] = useState(false);

  const Send = () => {
    setIsSend(true);
  };

  const Receive = () => {
    setIsSend(false);
  };

  const Clicked = () => {
    setClick(false);
  };

  const SelectedCurrency = (row) => {
    console.log(row);
    setInputValue(row.address);
    setWalletId(row.uuid);
    setCurrencyName(row.currency.name);
    setCurrencyImage(row.currency.logoImagePath);
    setBalance(row.balance);
  };
  useEffect(() => {
    setIsLoader(true);
    axios({
      method: "get",
      url: baseURL + "api/v1/wallet/get_wallets",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        // console.log("response ios", response.data.data);
        //  setResult(result => [...result, response]);
        setGetList(response.data.data);
        setInputValue(response.data.data[0].address);
        setWalletId(response.data.data[0].uuid);
        setCurrencyName(response.data.data[0].currency.name);
        setCurrencyImage(response.data.data[0].currency.logoImagePath);
        setBalance(response.data.data[0].balance);

        axios({
          method: "get",
          url: baseURL + "api/v1/wallet/get_transections",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            setIsLoader(false);
            setGetTransaction(response.data.data);
            setDate(response.data[0].createdAt);
          })
          .catch((error) => {
            setIsLoader(false);
          });
      })
      .catch((error) => {
        setIsLoader(false);
      });
  }, []);
  const updateOnSend = () => {
    setIsLoader(true);
    axios({
      method: "get",
      url: baseURL + "api/v1/wallet/get_wallets",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        setIsLoader(false);
        setGetList(response.data.data);
        setInputValue(response.data.data[0].address);
        setWalletId(response.data.data[0].uuid);
        setCurrencyName(response.data.data[0].currency.name);
        setCurrencyImage(response.data.data[0].currency.logoImagePath);
        setBalance(response.data.data[0].balance);
      })
      .catch((error) => {
        setIsLoader(false);
      });
  };
  return (
    <>
      <div className={isLoader ? "loader-bg" : ""}></div>
      <div className="main wallet-container">
        <div className="inner-1">
          <div className="bg-layer"></div>
          <div className="text-div-1">WALLET</div>
          <div className="text-div-2 animation-text">MY ACCOUNT</div>

        </div>

        <section class="section-light section-top-shadow">
          <div className="container">
            <div className="row">
              {/* ////////////////SEND PART//////////////////// */}
              <div className="col-xs-12 col-md-12">
                <div className="sidebar-left">
                  <div className="row">
                    <div className="col-xs-12 col-md-3">
                      <h3 className="sidebar-title">
                        wallet<span className="special-color">.</span>
                      </h3>
                      <div className="title-separator-primary"></div>
                      
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-xs-12 col-md-3">
                      <div className="profile-info margin-top-30">
                        <div className="sidebar-main-div">
                          {getList == "" ||
                            getList.map((row) => (
                              <div
                                key={row.currency.uuid}
                                id="first"
                                className="side-menu-option"
                                onClick={() => SelectedCurrency(row)}
                              >
                                <img
                                  className="currency-image"
                                  src={row.currency.logoImagePath}
                                  alt="image"
                                />
                                <div className="currency-text">
                                  <h5
                                    style={{
                                      color: "white",
                                      marginTop: "10px",
                                      marginRight: "40px",
                                    }}
                                    className="currency-name"
                                  >
                                    {row.currency.name}
                                  </h5>
                                  <h5
                                    style={{ color: "white", marginRight: "40px" }}
                                    className="currency-name"
                                  >
                                    ({row.currency.shortName})
                                  </h5>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xs-12 col-md-12">
                <div className="inner-2">
                  <div className="img-pnl">
                    <img
                      className="currency-image-head"
                      src={currencyImage}
                      alt="Image"
                    />
                  </div>
                  <div className="heading mt-1 mb-1">
                    <h2>
                      {currencyName}: {balance}
                    </h2>
                  </div>
                  <div className="buttons-div">
                    <div
                      onClick={Send}
                      className={isSend ? "send-button" : "receive-button"}
                    >
                      SEND
                    </div>

                    <div
                      onClick={Receive}
                      className={isSend ? "receive-button" : "send-button"}
                    >
                      RECEIVE
                    </div>
                  </div>

                  {isSend ? (
                    <SendWallet
                      id={walletId}
                      balance={balance}
                      updateOnSend={updateOnSend}
                    />
                  ) : (
                    <div className="inner-4">
                      <div className="qr-div">
                        <QR
                          value={inputValue}
                          size={200}
                          level={"H"}
                          includeMargin={true}
                        />
                      </div>
                      <div className="input-div">
                        <input
                          type="text"
                          value={inputValue}
                          onChnage={(e) => setInputValue(e.target.value)}
                          className="input-field"
                          placeholder={inputValue}
                          disabled
                        />
                      </div>
                      <div onClick={Clicked} className="buttons-div scnd">
                        <CopyToClipboard text={inputValue}>
                          {click ? (
                            <button className="copy-button">COPY</button>
                          ) : (
                            <button className="copy-button">COPIED</button>
                          )}
                        </CopyToClipboard>
                      </div>
                    </div>
                  )}
                </div>
                <div className="row margin-top-60"></div>
              </div>

              {/* ////////////////CURRENCY LIST////////////////// */}


            </div>
          </div>
        </section>

        <div className="table-div">
          <div className="table-child-div">
            <div>
              <DataTable
                title="Recent Transaction"
                columns={columns}
                data={getTransaction}
                pagination
                paginationPerPage={5}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Wallet;
