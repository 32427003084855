import React, { Component } from "react";

import "./Profile.css";
import { getToken } from "../../common/commonFunctions/commonFunctions";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import { baseURLImages } from "../../../ApiURL/ApiURL";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import { Link } from "react-router-dom";
import Kyc from "../Kyc/Kyc";
import ChangePassword from "../ChangePassword/ChangePassword";
import avatar from "./../../../assets/images/avatar.jpg";
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
class Profile extends Component {
  state = {
    name: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    address: "",
    file: "",
    Message: "",
    ImageUpload: "",
    profileImage: null,
    description: "",
    open: false,
    info: false,
  };

  infoTab = () => {
    this.setState({ info: true });
  };

  handleSubmit = (e) => {
    console.log("api called");
    e.preventDefault();
    this.setState({ isLoader: true });

    {
      axios({
        method: "PUT",
        url: baseURL + "api/v1/account/UpdateProfileInfo",
        data: {
          name: this.state.name,
          lastName: this.state.lastName,
          email: this.state.email,
          phoneNumber: this.state.phoneNumber,
          address: this.state.address,
          description: this.state.description,
        },
        headers: {
          "Content-Type": "application/json-patch+json",
          accept: "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((res) => {
          this.setState({ isLoader: false });
          if (res.data.isSuccess) {
            localStorage.setItem("userName", res.data.data.name)
            this.props.onSelectName(res.data.data.name);
            this.setState({ Message: res.data.message });
            this.onOpenModal();
          }
        })
        .catch((error) => {
          this.setState({ isLoader: false });
        });
    }
  };

  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  onOpenModal = () => {
    this.setState({ open: true });
  };
  onCloseModal = () => {
    this.setState({ open: false });
  };

  componentDidMount() {

    this.setState({ isLoader: true });
    axios({
      method: "GET",
      url: baseURL + "api/v1/account/GetAccountDetails",
      data: {
        profileImage: this.state.profileImage,
      },
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        this.setState({ isLoader: false });
        console.log(res);
        if (res.data.isSuccess) {
          this.setState({ name: res.data.data.name });
          this.setState({ lastName: res.data.data.lastName });
          this.setState({ email: res.data.data.email });
          this.setState({ phoneNumber: res.data.data.phoneNumber });
          this.setState({ address: res.data.data.address });

          this.setState({ profileImage: res.data.data.profileImage });
          this.setState({ description: res.data.data.description });
          this.setState({ Message: this.state.Message });
        }
      })
      .catch((error) => {
        this.setState({ isLoader: false });
      });
  }
  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  //image//
  handleImageUpload = (e) => {
    console.log(e.target.files[0].name);

    var bodyFormData = new FormData();
    bodyFormData.append("profileImage", e.target.files[0]);
    bodyFormData.append("RemoveProfileImage", false);
    this.setState({ isLoader: true });
    axios({
      method: "PUT",
      url: baseURL + "api/v1/account/UploadProfileImage",

      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        this.setState({ isLoader: false });
        if (res.data.isSuccess) {
          this.setState({ profileImage: res.data.data.profileImage });
          this.setState({ Message: res.data.message });
        }
      })
      .catch((error) => {
        this.setState({ isLoader: false });
      });
    this.setState({
      file: URL.createObjectURL(e.target.files[0]),

      ImageUpload: e.target.files[0].name,
    });
  };
  render() {
    const { open } = this.state;
    return (
      <main className="property-main">
        <div className="profile">
          <section className="short-image no-padding blog-short-title">
            <div className="bg-layer"></div>
            <div className="row">
              <div className="col-xs-12 col-lg-12 short-image-title">
                <h5 className="subtitle-margin second-color">dashboard</h5>
                <h1 className="second-color">my account</h1>
                <div className="short-title-separator"></div>
              </div>
            </div>
          </section>
          <div className={this.state.isLoader ? "loader-bg" : ""}></div>

          <section className="section-light profile-btm-pnl section-top-shadow">
            <div className="sidebar-left">
              <div className="container">
                <div className="row Main">
                  <div className="row">
                    <div className="col-xs-12 col-md-12">
                      <div className="sidebar-left" className="side-bar">
                        <h3 className="sidebar-title">
                          Welcome back<span className="special-color">.</span>
                        </h3>
                        <div className="title-separator-primary"></div>

                        <div className="profile-info margin-top-60">
                          <div className="profile-info-title negative-margin">
                            {this.state.name} {this.state.lastName}
                          </div>

                          {
                            this.state.profileImage == null ? <img
                              src={avatar}
                              id="profileImage"
                              name="profileImage"
                              value={this.state.profileImage}
                              className="Profile-Image"
                            /> : <img
                              src={`${baseURL}${this.state.profileImage}`}
                              id="profileImage"
                              name="profileImage"
                              value={this.state.profileImage}
                              className="Profile-Image"
                            />
                          }
                          <div className="profile-info-text pull-left"></div>
                          <div className="clearfix"></div>
                        </div>
                        <ul className="btn-list">
                          <li className="center-button-cont">
                            <Link
                              to="/offers"
                              className="button-primary button-shadow button-full"
                            >
                              <span>Offers</span>
                              <div className="button-triangle"></div>
                              <div className="button-triangle2"></div>
                              <div className="button-icon">
                                <i className="fa fa-th-list"></i>
                              </div>
                            </Link>
                          </li>

                          <li class="center-button-cont">
                            <Link
                              to="/myproperty"
                              class="button-primary button-shadow button-full"
                            >
                              <span>My Property</span>
                              <div class="button-triangle"></div>
                              <div class="button-triangle2"></div>
                              <div class="button-icon">
                                <i class="fa fa-lg mp fa-home"></i>
                              </div>
                            </Link>
                          </li>
                          <li class="center-button-cont">
                            <Link
                              to="/FavouriteProperties"
                              class="button-primary button-shadow button-full"
                            >
                              <span>Favourites</span>
                              <div class="button-triangle"></div>
                              <div class="button-triangle2"></div>
                              <div class="button-icon">
                                <i class="fa fa-lg mp fa-home"></i>
                              </div>
                            </Link>
                          </li>
                          <li className="center-button-cont">
                            <Link
                              to="/addproperty"
                              className="button-alternative button-shadow button-full"
                            >
                              <span>Upload Property</span>
                              <div className="button-triangle"></div>
                              <div className="button-triangle2"></div>
                              <div class="button-icon button-icon-plus">
                                <i class="fa fa-lg fa-home"></i>
                              </div>
                            </Link>
                          </li>
                        </ul>

                      </div>
                    </div>
                    <div className="col-lg-12 margin-top-60"></div>
                    <div className="col-xs-12 col-md-12 mb-p-o">
                      <div className="row">
                        <div className="col-xs-12">
                          <h5 className="subtitle-margin">edit</h5>
                          <h1>
                            Profile<span className="special-color">.</span>
                          </h1>
                          <div className="title-separator-primary"></div>

                          <div
                            style={{ marginTop: "10px" }}
                            className="col-xs-12 apartment-tabs"
                          >
                            <ul className="nav nav-tabs" role="tablist">
                              <li role="presentation" className="active">
                                <a
                                  href="#tab-info"
                                  aria-controls="tab-info"
                                  role="tab"
                                  data-toggle="tab"
                                >
                                  <span>Info</span>
                                  <div className="button-triangle2"></div>
                                </a>
                              </li>

                              <li role="presentation">
                                <a
                                  href="#tab-KYC"
                                  aria-controls="tab-KYC"
                                  role="tab"
                                  data-toggle="tab"
                                >
                                  <span>KYC</span>
                                  <div className="button-triangle2"></div>
                                </a>
                              </li>
                              <li role="presentation">
                                <a
                                  href="#tab-changePassword"
                                  aria-controls="tab-changePassword"
                                  role="tab"
                                  data-toggle="tab"
                                >
                                  <span>Change Password</span>
                                  <div className="button-triangle2"></div>
                                </a>
                              </li>
                            </ul>

                            <div className="tab-content">
                              <div
                                role="tabpanel"
                                className="tab-pane active"
                                id="tab-info"
                              >
                                <div
                                  style={{ height: "650px", width: "100%" }}
                                >
                                  <form
                                    name="agent-from"
                                    action="#"
                                    enctype="multipart/form-data"
                                  >
                                    <div className="row margin-top-60">
                                      <div className="col-xs-6  col-sm-3 col-md-4">
                                        <div className="agent-photos">
                                          {this.state.profileImage == null ?
                                            <img
                                              // src={this.state.file}
                                              src={
                                                avatar
                                              }
                                              className="Logo"
                                            /> : <img
                                              // src={this.state.file}
                                              src={`${baseURL}${this.state.profileImage}`}
                                              className="Logo"
                                            />
                                          }
                                          <div className="change-photo">
                                            <i className="fas fa-pen"></i>
                                            <input
                                              type="button "
                                              classNameName="upload-file-hide"
                                              id="uploadfile"
                                              type="file"
                                              onChange={
                                                this.handleImageUpload
                                              }
                                              accept="image/*"
                                              id="agent-photo"
                                            ></input>
                                          </div>
                                          <input
                                            type="text"
                                            disabled="disabled"
                                            id="agent-file-name"
                                            className="main-input"
                                          />
                                        </div>
                                      </div>

                                      <div className="col-xs-12 col-sm-9 col-md-8">
                                        <div className="labelled-input">
                                          <label for="first-name">
                                            First name
                                          </label>
                                          <input
                                            type="text"
                                            className="input-full main-input"
                                            id="name"
                                            name="name"
                                            placeholder="Enter First Name"
                                            value={this.state.name}
                                            required
                                            onChange={this.onHandleChange}
                                          />
                                        </div>
                                        <div className="labelled-input">
                                          <label for="last-name">
                                            Last name
                                          </label>
                                          <input
                                            type="text"
                                            className="input-full main-input"
                                            id="lastName"
                                            name="lastName"
                                            placeholder="Enter last Name"
                                            value={this.state.lastName}
                                            required
                                            onChange={this.onHandleChange}
                                          />
                                          <div className="clearfix"></div>
                                        </div>
                                        <div className="labelled-input">
                                          <label for="email">Email</label>
                                          <input
                                            id="email"
                                            name="email"
                                            type="email"
                                            required
                                            onChange={this.onHandleChange}
                                            value={this.state.email}
                                            className="input-full main-input"
                                            disabled
                                            placeholder="Enter Your Email"
                                          />{" "}
                                          <div className="clearfix"></div>
                                        </div>
                                        <div className="labelled-input">
                                          <label for="phone">Phone</label>
                                          <input
                                            id="phoneNumber"
                                            name="phoneNumber"
                                            required
                                            type="tel"
                                            onChange={this.onHandleChange}
                                            className="input-full main-input"
                                            value={this.state.phoneNumber}
                                            placeholder="Enter Your Phone Number"
                                          />{" "}
                                          <div className="clearfix"></div>
                                        </div>
                                        <div className="labelled-input last">
                                          <label for="address">Address</label>
                                          <input
                                            id="address"
                                            name="address"
                                            value={this.state.address}
                                            required
                                            type="text"
                                            onChange={this.onHandleChange}
                                            className="input-full main-input"
                                            placeholder="Enter Your Address"
                                          />{" "}
                                          {/* <CopyToClipboard text={this.state.address}
                                            onCopy={() => toast.success("wallet address copied")}>

                                            <button type="button"
                                              style={{ marginTop: "10px" }}
                                              class="button-primary button-shadow"

                                            >
                                              <span>Copy Wallet address </span>
                                              <div class="button-triangle"></div>
                                              <div class="button-triangle2"></div>

                                            </button>


                                          </CopyToClipboard> */}
                                          <div className="clearfix"></div>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="row margin-top-15">
                                      <div className="col-xs-12">
                                        <div className="labelled-textarea">
                                          <label for="description">
                                            Description
                                          </label>
                                          <textarea
                                            maxlength="1100"
                                            id="description"
                                            name="description"
                                            className="input-full main-input"
                                            value={this.state.description}
                                            onChange={this.onHandleChange}
                                          ></textarea>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="save-button">
                                      <button
                                        style={{ marginTop: "10px" }}
                                        class="button-primary button-shadow"
                                        onClick={this.handleSubmit}
                                      >
                                        <span>save</span>
                                        <div class="button-triangle"></div>
                                        <div class="button-triangle2"></div>
                                        <div class="button-icon">
                                          <i class="fa fa-lg fa-save"></i>
                                        </div>
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div
                                role="tabpanel"
                                className="tab-pane"
                                id="tab-KYC"
                              >
                                <div
                                  style={{ height: "600px", width: "100%" }}
                                >
                                  <Kyc />
                                </div>
                              </div>
                              <div
                                role="tabpanel"
                                className="tab-pane"
                                id="tab-changePassword"
                              >
                                <div
                                  style={{ height: "400px", width: "100%" }}
                                >
                                  <ChangePassword />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row margin-top-60"></div>
                    </div>


                  </div>
                </div>

                <form
                  onSubmit={this.handleSubmit}
                  name="agent-from"
                  action="#"
                  enctype="multipart/form-data"
                ></form>

                <div className="row margin-top-15">
                  <div class="col-xs-12">
                    <div class="center-button-cont center-button-cont-border">
                      <Modal open={open} onClose={this.onCloseModal}>
                        <div class="modal-profile-body">
                          <div className="row">
                            <div className="col-xs-12 col-sm-12">
                              <h5>
                                {" "}
                                Profile has been successfully Updated
                                <span class="special-color">.</span>
                              </h5>
                            </div>
                          </div>
                        </div>
                        <div class="modal-profile-footer">
                          <div className="buttons">
                            <a
                              className="buttons"
                              class="button-primary button-shadow"
                              onClick={this.onCloseModal}
                            >
                              <span>Ok</span>
                              <div class="button-triangle"></div>
                              <div class="button-triangle2"></div>
                              <div class="button-icon">
                                <i class="fa fa-user"></i>
                              </div>
                            </a>
                          </div>
                        </div>
                      </Modal>
                      <div className="modal-size"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

        </div>
      </main>
    );
  }
}

export default Profile;
