import React, { useState } from "react";
import "./ContactUs.css";
import Logo from "./../../../assets/images/cezco-logo.png";
import contactlogo from "./../../../assets/images/contact-bg.png";
import { baseURL } from "./../../../ApiURL/ApiURL";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

function ContactUs(props) {
  const values = { name: "", email: "", phone: "", message: "" };
  const [allValues, setAllValues] = useState(values);
  const [loader, setLoader] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [message, setMessage] = useState("false");
  const changeHandler = (e) => {
    setAllValues({ ...allValues, [e.target.name]: e.target.value });

    console.log("all", allValues.name);
  };
  const submit = (e) => {
    e.preventDefault();
    setLoader(true);
    axios({
      method: "post",
      url: baseURL + "api/v1/account/AddContactUsDetails",
      data: {
        Name: allValues.name,
        PhoneNo: allValues.phone,
        Email: allValues.email,
        Question: allValues.message,
      },
    })
      .then((response) => {
        setLoader(false);
        openModal();
        console.log("response", response);
        setMessage(response.data.isSuccess);
      })

      .catch((error) => {
        setMessage(error.isSuccess);
      });
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  return (
    <>
      <div className={loader ? "loader-bg" : ""}></div>
      <div className="contact-us">
        <section class="contact-page">
          <div class="contact1-cont">
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-lg-12 col-sm-12">
                  <h1 className="hedng"><span>get in touch</span> Contact Us</h1>
                </div>
              </div>
              <div class="contact-detail-pnl">
                <div class="row">
                  <div class="col-xs-12 col-sm-6 col-md-6 text-center">
                    <img
                      src={contactlogo} alt="" class="contact-logo" />
                    <address class="contact-info">
                      <span>
                        <i class="fa fa-envelope fa-sm"></i>
                        <a href="mailto:info@dwellinggoldmine">info@Dwelling-Goldmine</a>
                      </span>
                    </address>
                  </div>
                  <div class="col-xs-12 col-sm-6 col-md-6">
                    <form
                      name="contact-from"
                      id="contact-form"
                      onSubmit={submit}
                    >
                      <div className="row">
                        <div class="col-xs-12 col-md-6">
                          <div id="form-result"></div>
                          <input
                            style={{ width: "100%" }}
                            name="name"
                            id="name"
                            type="text"
                            class="input-short2 main-input required,all"
                            placeholder="Your name"
                            required
                          />
                        </div>
                        <div class="col-xs-12 col-md-6">
                          <PhoneInput
                            country={"us"}
                            className="input-full main-input inputStyle"
                            value={values.phone}
                            onChange={(phone) =>
                              setAllValues({ phone: phone })
                            }
                            inputStyle={{
                              width: "100%",
                              borderRadius: "5px 0 5px 0",
                              border: "1px solid #ebebeb",
                              height: " 50px",
                              lineHeight: "40px",
                              fontSize: "0.99em",
                              fontWeight: "500",
                              outline: "none",

                            }}
                            required
                          />
                        </div>
                        <div class="col-xs-12 col-md-12">
                          <input
                            name="email"
                            id="email"
                            type="email"
                            class="input-full main-input required,email"
                            placeholder="Your email"
                            required
                            onChange={changeHandler}
                          />
                        </div>
                        <div class="col-xs-12 col-md-12">
                          <textarea
                            name="message"
                            id="message"
                            class="input-full contact-textarea main-input required,email"
                            placeholder="Your question"
                            onChange={changeHandler}
                          ></textarea>
                        </div>
                        <div class="col-xs-12 col-md-12">
                          <div class="form-submit-cont">
                            <button
                              type="submit"
                              class="button-primary"
                              id="form-submit"
                            >
                              <span>send</span>
                              <div class="button-triangle"></div>
                              <div class="button-triangle2"></div>
                              <div class="button-icon">
                                <i class="fa fa-paper-plane"></i>
                              </div>
                            </button>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>

                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <Modal
        classNames="modal-responsive-layout"
        open={isOpen}
        onClose={closeModal}
        center
      >
        <div className="modal-body">
          {message ?
            (
              <h3>Data Sent Successfully</h3>
            ) : (
              <h3>Invalid Data or Network Issue</h3>
            )}
          <button className="button-primary" onClick={closeModal}>
            close
          </button>
        </div>
      </Modal>
    </>
  );
}

export default ContactUs;
