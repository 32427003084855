import { render } from "@testing-library/react";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link,
  Redirect,
  useHistory,
  useLocation,
} from "react-router-dom";
import { getIsLoggedIn } from "../../common/commonFunctions/commonFunctions";
export const PrivateRouter = ({ children, isLogin, ...rest }) => {
  return (
    <Route
      {...rest}
      render={({ location }) =>
        getIsLoggedIn() ? (
          children
        ) : (
          <Redirect
            to={{
              pathname: "/home",
              state: { referrer: window.location.pathname },
            }}
          />
        )
      }
    />
  );
};

export default PrivateRouter;
