import logo from "./logo.svg";
//import { HubConnection, HubConnectionBuilder,LogLevel } from "@microsoft/signalr";
import * as signalR from "@microsoft/signalr";
import "./App.css";
import PrivateRouter from "./components/containers/PrivateRouter/PrivateRouter";
import Navbar from "./components/common/Navbar/Navbar.js";
import LandingPage from "./components/containers/LandingPage/LandingPage";
import SellPage from "./components/containers/SellPage/SellPage";
import CommercialPage from "./components/containers/CommercialPage/CommercialPage";
import FinancingPage from "./components/containers/FinancingPage/FinancingPage";
import RentPage from "./components/containers/RentPage/RentPage";
import Favourite from "./components/containers/Favouritproperties/Favourite";
import Lending from "./components/containers/Lending/Lending";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Profile from "./components/containers/Profile/Profile";
import AddProperty from "./components/containers/AddProperty/AddProperty";
import MyProperty from "./components/containers/MyProperty/MyProperty";
import PageNotFound from "./components/containers/PageNotFound/PageNotFound";
import VerifyEmail from "./components/containers/VerifyEmail/VerifyEmail";
import { useEffect, useState, useRef, Fragment } from "react";
import ListDetail from "./components/containers/ListCardDetail/ListDetail";
import ListDetailImage from "./components/containers/ListCardDetailImage/ListDetailImage";
import MarketPlace from "./components/containers/Listing/Listing";
import Wallet from "./components/containers/Wallet/Wallet";
import Offers from "./components/containers/Offers/Offers";
import ChatView from "./components/containers/ChatView/ChatView";
import ForgotPassword from "./components/containers/ForgotPassword/ForgotPassword";
import axios from "axios";
import { baseURL } from "./ApiURL/ApiURL";
import {
  getIsLoggedIn,
  getToken,
  counter,
} from "./components/common/commonFunctions/commonFunctions";
import ScrollToTop from "./components/ScrollToTop/ScrollToTop";
import ChangePassword from "./components/containers/ChangePassword/ChangePassword";
import GeneratePDF from "./components/containers/GeneratePDF/GeneratePDF";
import Kyc from "./components/containers/Kyc/Kyc";
import ContactUs from "./components/containers/ContactUs/ContactUs";
import Footer from "./components/containers/Footer/Footer.js";
import BuyPage from "./components/containers/BuyPage/BuyPage";
// import SectionLight from "./components/containers/SectionLight/SectionLight";
// import OurBlog from "./components/containers/OurBlog/OurBlog";
// import FeatureSection from "./components/containers/FeatureSection/FeatureSection";
// import ClientSaySection from "./components/containers/ClientsSaySection/ClientSaySection";
// import ProfessianlTeam from "./components/containers/ProfessionalTeam/ProfessianlTeam";
// import NewListing from "./components/containers/NewListing/NewListing";
// import FeaturedOfferSection from "./components/containers/FeaturedOfferSection/FeaturedOfferSection";
// import AdvSearchSection from "./components/containers/AdvSearchSection/AdvSearchSection";
function App(props) {
  const cref = useRef();
  const navref = useRef();
  const [isLoader, setIsLoader] = useState(true);
  const [connection, setConnection] = useState("");
  const [offerChatDetails, setOfferChatDetails] = useState([]);
  const [count, setCount] = useState(0);
  const [userName, setUserName] = useState();
  const transport = signalR.HttpTransportType.WebSockets;
  const options = {
    transport,
    logMessageContent: true,
    logger: signalR.LogLevel.Error,
    skipNegotiation: true,
  };
  useEffect(() => {
    if (getIsLoggedIn()) {
      const connect = new signalR.HubConnectionBuilder()
        .withUrl(baseURL + "OfferChat?token=" + getToken(), options)
        .withAutomaticReconnect()
        .build();
      console.log("connect", connect);
      setConnection(connect);
    }
  }, []);
  useEffect(() => {
    if (getIsLoggedIn()) {
      function unseenMessageCounter(items, prop) {
        return items.reduce(function (a, b) {
          return a + b[prop];
        }, 0);
      }
      axios({
        method: "get",
        url: baseURL + "api/Offer/GetOffers",
        headers: {
          "Content-Type": "application/json-patch+json",
          accept: "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          const offerDetail = response.data.data.map((item) => {
            return {
              ...item,
              unseenMessages: unseenMessageCounter(
                item.offerChatStatus,
                "unseenMessages"
              ),
            };
          });
          onHandleCount(counter(offerDetail));
        })
        .catch((error) => {});
    }
  }, []);
  useEffect(() => {
    if (getIsLoggedIn()) {
      if (connection) {
        if (connection.state != "Connected")
          connection.on("connectedlistener", (Objectresponse) => {
            console.log(Objectresponse);
          });
        connection.on("errorlistener", (object) => {
          console.log("error  ", object);
        });
        connection.start().then(() => {
          console.log("STARTED Chat");
          connection.on("messagelistener", (object) => {
            console.log("new message ", object);
            newChatMessage(object);
          });
          connection.on("updateofferlistener", (object) => {
            console.log("new offer", object);
            offerUpdate(object);
          });
        });
      }
      return () => {
        if (connection !== null) {
          try {
            connection.stop();
          } catch {}
          console.log("STOPPED Chat");
        }
      };
    }
  }, [connection]);
  useEffect(() => {
    setIsLoader(false);
  });
  const offerUpdate = (object) => {
    if (cref.current) {
      cref.current.updateOffer(object);
    }
  };
  const newChatMessage = (object) => {
    if (cref.current) {
      cref.current.setFromOutside(object);
    }
  };

  const onHandleCount = (count) => {
    if (navref.current) {
      navref.current.setCountFromOutside(count);
    }
  };
  const handleName = (value) => {
    console.log("this is vaue for name ", value);
    setUserName(value);
    console.log("this is state", userName);
  };
  return (
    <div className="" id="app">
      <div className={isLoader ? "loader-bg" : ""}></div>
      <header className="">
        <Navbar ref={navref} username={userName} />
      </header>
      <Fragment>
        <ScrollToTop />

        <Switch>
          <Route exact path="/" component={LandingPage}></Route>
          <Route exact path="/home" component={LandingPage}></Route>
          <Route exact path="/buyProperty" component={BuyPage}></Route>
          <Route exact path="/sellProperty" component={SellPage}></Route>
          <Route
            exact
            path="/commercialProperty"
            component={CommercialPage}
          ></Route>
          <Route
            exact
            path="/financingProperty"
            component={FinancingPage}
          ></Route>
          <Route exact path="/rentProperty" component={RentPage}></Route>
          <Route
            exact
            path="/FavouriteProperties"
            component={Favourite}
          ></Route>
          <Route exact path="/contactus" component={ContactUs}></Route>
          <Route path="/MarketPlace" component={MarketPlace}></Route>
          <Route path="/Lending" component={Lending}></Route>
          <Route path="/listingdetail" component={ListDetail}></Route>
          <Route path="/listingdetailImage" component={ListDetailImage}></Route>
          <Route path="/generatepdf" component={GeneratePDF}></Route>
          <Route path="/verify/:id" component={VerifyEmail}></Route>
          <Route path="/forgotPassword/:id" component={ForgotPassword}></Route>
          <PrivateRouter exact path="/profile">
            <Profile onSelectName={handleName} />
          </PrivateRouter>
          <PrivateRouter exact path="/changepassword">
            <ChangePassword />
          </PrivateRouter>
          <PrivateRouter exact path="/addProperty">
            <AddProperty />
          </PrivateRouter>
          <PrivateRouter exact path="/myproperty">
            <MyProperty />
          </PrivateRouter>
          <PrivateRouter exact path="/kyc">
            <Kyc />
          </PrivateRouter>
          <PrivateRouter exact path="/wallet">
            <Wallet />
          </PrivateRouter>
          <PrivateRouter exact path="/offers">
            <Offers
              offerChatDetails={offerChatDetails}
              ref={cref}
              onHandleCount={onHandleCount}
            />
          </PrivateRouter>

          <PrivateRouter exact path="/chat">
            <ChatView />
          </PrivateRouter>
          {/* <Route exact path="/addproperty" component={AddProperty}></Route> */}
          {/* <Route exact path="/myproperty" component={MyProperty}></Route> */}
          <Route exact={true} path="/*" component={PageNotFound}></Route>
        </Switch>
      </Fragment>
      {/* <AdvSearchSection /> */}
      {/* <FeatureSection /> */}
      {/* <FeaturedOfferSection /> */}
      {/* <ProfessianlTeam /> */}
      {/* <ClientSaySection /> */}
      {/* <NewListing /> */}
      {/* // <OurBlog /> */}
      {/* <SectionLight /> */}
      <Footer  />
    </div>
  );
}

export default App;
