import React, { Component } from "react";
import {
  closeModal,
  disableScroll,
  enableScroll,
  getToken,
  getUserName,
} from "../../common/commonFunctions/commonFunctions";
import Offer from "../../../assets/images/grid-offer1.jpg";
import "./MyProperty.css";
import axios from "axios";
import { baseURL, GoogleMapKey } from "./../../../ApiURL/ApiURL";
import DefaultImage from "../../../assets/images/grid-offer1.jpg";
import bedRooms from "../../../assets/images/rooms-icon.png";
import bathRooms from "../../../assets/images/bathrooms-icon.png";
import { withRouter, Link } from "react-router-dom";
import GoogleMapReact from "google-map-react";
const PropertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial Plot" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
];
const AnyReactComponent = ({ text }) => <div className="pin1"></div>;
class MyProperty extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      userProperties: [],
    };
  }

  componentDidMount() {
    this.setState({
      isLoader: true,
    });
    disableScroll();
    setTimeout(() => {
      closeModal("login-modal");
    }, 1000);
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/get_user_listings",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        this.setState({ isLoader: false, userProperties: response.data.data });
        enableScroll();
      })
      .catch((error) => {
        this.setState({ isLoader: false });
        enableScroll();
      });
  }
  handleButton = (e) => {
    this.props.history.push({
      pathname: "/listingdetail",
      search: "selectedList=" + e + "&myproperty=" + true,
    });
  };

  onChangeOrderBy = (event) => {
    console.log(event.target.value);
    if (event.target.value == "priceHigh") {
      this.setState({
        userProperties: this.state.userProperties.sort((a, b) =>
          a.price < b.price ? 1 : -1
        ),
      });
    } else if (event.target.value == "priceLow") {
      this.setState({
        userProperties: this.state.userProperties.sort((a, b) =>
          a.price > b.price ? 1 : -1
        ),
      });
    } else if (event.target.value == "squareHigh") {
      this.setState({
        userProperties: this.state.userProperties.sort((a, b) =>
          a.propertyArea < b.propertyArea ? 1 : -1
        ),
      });
    } else if (event.target.value == "squareLow") {
      this.setState({
        userProperties: this.state.userProperties.sort((a, b) =>
          a.propertyArea > b.propertyArea ? 1 : -1
        ),
      });
    } else if (event.target.value == "dateLatest") {
      this.setState({
        userProperties: this.state.userProperties.sort((a, b) =>
          a.id < b.id ? 1 : -1
        ),
      });
    } else if (event.target.value == "dateOldest") {
      this.setState({
        userProperties: this.state.userProperties.sort((a, b) =>
          a.id > b.id ? 1 : -1
        ),
      });
    }
  };
  render() {
    return (
      <>
        <main className="property-main">
          <div className="my-property">
            <div className={this.state.isLoader ? "loader-bg" : ""}></div>
            <section className="short-image no-padding blog-short-title">
              <div className="bg-layer"></div>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-lg-12 short-image-title">
                    <h5 className="subtitle-margin second-color">dashboard</h5>
                    <h1 className="second-color animation-text">my account</h1>
                    <div className="short-title-separator"></div>
                  </div>
                </div>
              </div>
            </section>

            <section className="section-light section-top-shadow">
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-md-12">
                    <div className="sidebar-left">
                      <ul class="btn-list">
                        <li className="center-button-cont margin-top-15">
                          <Link
                            to="/profile"
                            className="button-primary button-shadow button-full"
                          >
                            <span>My profile</span>
                            <div className="button-triangle"></div>
                            <div className="button-triangle2"></div>
                            <div className="button-icon">
                              <i className="fa fa-user"></i>
                            </div>
                          </Link>
                        </li>
                        <li className="center-button-cont margin-top-15">
                          <Link
                            to="/addProperty"
                            className="button-alternative button-shadow button-full"
                          >
                            <span>Upload Property</span>
                            <div className="button-triangle"></div>
                            <div className="button-triangle2"></div>
                            <div className="button-icon button-icon-plus">
                              <i className="fa fa-lg fa-home"></i>
                            </div>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-12 margin-top-30">
                    <div className="row">
                      <div className="col-xs-12 col-md-7 col-sm-7 col-lg-7">
                        <h5 className="subtitle-margin">Your Properties</h5>
                        <h1>
                          {this.state.userProperties.length} estates found
                          <span className="special-color">.</span>
                        </h1>
                      </div>
                      <div className="col-xs-12 col-md-5 col-sm-5 col-lg-5">
                        <div className="order-by-container">
                          <select
                            name="orderByFilter"
                            className="bootstrap-select select-search-list btn-group"
                            title="Order By:"
                            onChange={this.onChangeOrderBy}
                          >
                            <option>Order By:</option>
                            <option value="priceHigh">Price high to low</option>
                            <option value="priceLow">Price low to high</option>
                            <option value="squareHigh">
                              Square Footage high to low
                            </option>
                            <option value="squareLow">
                              Square Footage low to high
                            </option>
                            <option value="dateLatest"> Latest </option>
                            <option value="dateOldest">Oldest</option>
                          </select>
                        </div>
                      </div>
                      <div className="col-xs-12">
                        <div className="title-separator-primary"></div>
                      </div>
                    </div>
                    {this.state.userProperties.map((item) => (
                      <div className="row list-offer-row">
                        <div className="col-xs-12">
                          <div className="list-offer">
                            <div className="list-offer-left">
                              <div className="list-offer-front">
                                <div className="list-offer-photo">
                                  {item.propertyMedia === null ? (
                                    <img height="177px" src={DefaultImage} />
                                  ) : item.propertyMedia.filter(
                                    (item) => item.isThumbnail === true
                                  ).length <= 0 ? (
                                    <img
                                      height="177px"
                                      src={
                                        baseURL +
                                        item.propertyMedia.filter(
                                          (item) => item.isThumbnail === false
                                        )[0]?.mediaUrl
                                      }
                                      alt="image"
                                    />
                                  ) : item.propertyMedia.filter(
                                    (item) => item.isThumbnail === true
                                  ).length > 1 ? (
                                    <img
                                      height="177px"
                                      src={
                                        baseURL +
                                        item.propertyMedia.filter(
                                          (item) => item.isThumbnail === true
                                        )[0].mediaUrl
                                      }
                                    />
                                  ) : (
                                    item.propertyMedia
                                      .filter((item) => item.isThumbnail === true)
                                      .map((data) => {
                                        return item.thumbnail === true ? null : (
                                          <img
                                            height="177px"
                                            src={baseURL + data.mediaUrl}
                                          />
                                        );
                                      })
                                  )}

                                  <div className="type-container">
                                    <div className="estate-type">
                                      {PropertyType.filter(
                                        (type) => type.id === item.propertyTypeId
                                      ).map((id) => id.PropertyTypeName)}
                                    </div>
                                    <div className="transaction-type">
                                      {item.propertyStatus}
                                    </div>
                                  </div>
                                </div>
                                <div className="list-offer-params">
                                  <div className="list-area">
                                    <img src="images/area-icon.png" alt="" />
                                    {item.propertyArea}m<sup>2</sup>
                                  </div>
                                  <div className="list-rooms">
                                    <img src={bedRooms} alt="" />
                                    {item.bedrooms}
                                  </div>
                                  <div className="list-baths">
                                    <img src={bathRooms} alt="" />
                                    {item.fullBaths}
                                  </div>
                                </div>
                              </div>
                              {/* <div className="list-offer-back">
                                <div id="list-map1" className="list-offer-map">
                                  <GoogleMapReact
                                    bootstrapURLKeys={{
                                      key: GoogleMapKey,
                                    }}
                                    defaultCenter={{
                                      lat: item.latitude,
                                      lng: item.longitude,
                                    }}
                                    defaultZoom={20}
                                  >
                                    <AnyReactComponent
                                      lat={item.latitude}
                                      lng={item.longitude}
                                      text="My Marker"
                                    />
                                  </GoogleMapReact>
                                </div>
                              </div> */}
                            </div>
                            <div className="list-offer-right">
                              {item.propertyAddStatus === "PendingApproval" ? (
                                <div className="pendingStatusBadge">Pending</div>
                              ) : item.propertyAddStatus === "Approved" ? (
                                <div className="acceptedStatusBadge">
                                  Accepted
                                </div>
                              ) : (
                                <div className="rejectStatusBadge">Rejected</div>
                              )}
                              <div className="list-offer-text">
                                <i className="fa fa-map-marker list-offer-localization"></i>
                                <div className="list-offer-h4">
                                  <a onClick={() => this.handleButton(item.uuid)}>
                                    <h4 className="list-offer-title">
                                      {item.locationAddress}
                                    </h4>
                                  </a>
                                </div>
                                <div className="clearfix"></div>
                                <p className="description-p">{item.discription}</p>

                                <div className="clearfix"></div>
                              </div>
                              <div className="profile-list-footer">
                                <div className="list-price profile-list-price">
                                  $ {item.price}
                                </div>
                          

                                <div className="profile-list-info hidden-xs">
                                  added: {item.createdAt.split("T")[0]}
                                </div>

                              </div>
                            </div>
                            <div className="clearfix"></div>
                          </div>
                          <div className="clearfix"></div>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
    );
  }
}

export default withRouter(MyProperty);
