import { useEffect, useState, useRef } from "react";
import "./Kyc.css";
import Image from "./../../../assets/images/blog-grid2h.jpg";
import axios from "axios";
import { baseURL, baseURLImages } from "../../../ApiURL/ApiURL";
import { getToken } from "../../common/commonFunctions/commonFunctions";
function Kyc(props) {
  const userDetails = {
    firstName: "",
    lastName: "",
    address: "",
  };
  const [userInfo, setUserInfo] = useState(userDetails);
  const [loader, setLoader] = useState(true);
  const [imageFront, setImageFront] = useState("");
  const [imageBack, setImageBack] = useState("");
  const [cnicFront, setCnicFront] = useState("");
  const [cnicBack, setCnicBack] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [successMessage, setSuccessMessage] = useState("");
  const [kycStatus, setKycStatus] = useState("");

  useEffect(() => {
    axios({
      method: "get",
      url: baseURL + "api/v1/account/GetKYC",
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        console.log("response", response.data.data);
        setUserInfo({
          firstName: response.data.data.firstName,
          lastName: response.data.data.lastName,
          address: response.data.data.address,
        });
        setKycStatus(response.data.data.kycStatus);
        setImageFront(baseURLImages + response.data.data.cnicFrontUrl);
        setImageBack(baseURLImages + response.data.data.cnicBackUrl);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
      });
  }, []);
  const handleChange = (e) => {
    setUserInfo({ ...userInfo, [e.target.name]: e.target.value });
  };
  const handleImageUploadFront = (e) => {
    setCnicFront(e.target.files[0]);
    console.log(cnicFront);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageFront(reader.result);
        console.log(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
    console.log(imageFront);
  };
  const handleImageUploadBack = (e) => {
    setCnicBack(e.target.files[0]);
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImageBack(reader.result);
      }
    };
    reader.readAsDataURL(e.target.files[0]);
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setLoader(true);
    var bodyFormData = new FormData();
    bodyFormData.append("FirstName", userInfo.firstName);
    bodyFormData.append("LastName", userInfo.lastName);
    bodyFormData.append("Address", userInfo.address);
    bodyFormData.append("CNICFrontUrl", cnicFront);
    bodyFormData.append("CNICBackUrl", cnicBack);
    axios({
      method: "post",
      url: baseURL + "api/v1/account/AddKYC",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        if (response.data.isSuccess) {
          setLoader(false);

          setSuccessMessage("Your KYC IS UPLOADED");
        } else {
          setLoader(false);
          setSuccessMessage(response.data.message);
        }
      })

      .catch((error) => {
        console.log("error", error);
        setLoader(false);

        setErrorMessage("Something went Wrong");
      });
  };
  return (
    <div className="kyc">
      <div className={loader ? "loader-bg" : ""}></div>
     
      
        <div class="">
          <div class="row">
    
            <form onSubmit={onSubmit}>
              <div className="dark-col">
                <div className="text-danger">{errorMessage}</div>
                <div className="text-success">{successMessage}</div>
                {kycStatus == "Rejected" ? (
                  <h3 className="text-danger">
                    {" "}
                    Your KYC is rejected please upload your kyc again.
                  </h3>
                ) : null}
                {kycStatus == "Verified" ? (
                  <h3 className="text-success"> Your KYC is Accepted.</h3>
                ) : null}
                {kycStatus == "InReview" ? (
                  <div className="review">
                    <h3>Your KYC is in review</h3>
                  </div>
                ) : kycStatus == "Verified" ? (
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <input
                        type="text"
                        className="form-control input-full main-input"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        value={userInfo.firstName}
                        required
                        onChange={handleChange}
                        disabled
                      />
                    </div>

                    <div className="col-xs-12 col-sm-6">
                      {" "}
                      <input
                        type="text"
                        className="form-control input-full main-input"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        value={userInfo.lastName}
                        required
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                      {" "}
                      <input
                        type="text"
                        className="form-control input-full main-input"
                        id="address"
                        name="address"
                        placeholder="Address"
                        value={userInfo.address}
                        required
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                    <div className="col-md-6 col-sm-6  ">
                      <div className="browse-button">
                        <div className="col-md-12 col-sm-12 Card-image">
                          {imageFront === "" ? (
                            <img
                              height="200px"
                              width="400px"
                              src={Image}
                              alt="id-card"
                            />
                          ) : (
                            <img
                              height="200px"
                              width="400px"
                              src={imageFront}
                              alt="id-card"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 padding-0">
                        <div className="upload-button padding-0">
                          {" "}
                          <button
                            className="button-primary button-shadow button-full browse"
                            type="button"
                            disabled
                            onClick={() => {
                              console.log("clicked");
                              document
                                .getElementById("uploadfileFront")
                                .click();
                            }}
                          >
                            <i class="far fa-folder-open folder-open"></i>
                            <span>Upload Front</span>
                            
                          </button>
                          <input
                            className="upload-file-hide"
                            id="uploadfileFront"
                            type="file"
                            onChange={handleImageUploadFront}
                            accept="image/*"
                            required
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6  ">
                      <div className="browse-button">
                      <div className="col-md-12 col-sm-12 Card-image">
                          {imageBack === "" ? (
                            <img
                              height="200px"
                              width="400px"
                              src={Image}
                              alt="id-card"
                            />
                          ) : (
                            <img
                              height="200px"
                              width="400px"
                              src={imageBack}
                              alt="id-card"
                            />
                          )}
                        </div>
                      </div>
                      <div className="col-md-12 col-sm-12 padding-0">
                        <div className=" upload-button padding-0">
                          <button
                            className="button-primary button-shadow button-full browse"
                            type="button"
                            disabled
                            onClick={() => {
                              console.log(
                                "clicked",
                                document.getElementById("uploadfileBack")
                              );
                              document.getElementById("uploadfileBack").click();
                            }}
                          >
                            <i class="far fa-folder-open folder-open"></i>
                            <span>Upload Back</span>
                          </button>
                          <input
                            className="upload-file-hide"
                            id="uploadfileBack"
                            type="file"
                            onChange={handleImageUploadBack}
                            accept="image/*"
                            required
                            disabled
                          />
                        </div>{" "}
                      </div>
                    </div>

                    <div className="col-md-12  submit-btn margin-top-40">
                      {" "}
                        {" "}
                        <button
                          className="button-primary button-shadow button-full"
                          type="submit"
                          disabled
                        >
                          <span>SUBMIT</span>
                          <div className="button-triangle"></div>
                          <div className="button-triangle2"></div>
                          <div className="button-icon">
                            <i className="fa fa-user"></i>
                          </div>
                        </button>
                      
                    </div>
                  </div>
                  
                ) : (
                  <div className="row">
                    <div className="col-xs-12 col-sm-6">
                      <input
                        type="text"
                        className="form-control input-full main-input"
                        id="firstName"
                        name="firstName"
                        placeholder="First Name"
                        value={userInfo.firstName}
                        required
                        onChange={handleChange}
                      />
                    </div>

                    <div className="col-xs-12 col-sm-6">
                      {" "}
                      <input
                        type="text"
                        className="form-control input-full main-input"
                        id="lastName"
                        name="lastName"
                        placeholder="Last Name"
                        value={userInfo.lastName}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-xs-12 col-sm-12">
                      {" "}
                      <input
                        type="text"
                        className="form-control input-full main-input"
                        id="address"
                        name="address"
                        placeholder="Address"
                        value={userInfo.address}
                        required
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-md-6 ml-5  col-sm-6  KYCImage">
                      
                      <div className="col-md-12 col-sm-12 Card-image">
                          {imageFront === "" ? (
                            <img
                              height="200px"
                              width="400px"
                              src={Image}
                              alt="id-card"
                            />
                          ) : (
                            <img
                              height="200px"
                              width="400px"
                              src={imageFront}
                              alt="id-card"
                            />
                          )}
                      
                      </div>
                      <div className="col-md-12 col-sm-12 upload-button KYCImage padding-0">
                       
                          {" "}
                          <button
                            className="button-primary button-shadow button-full browse"
                            type="button"
                            onClick={() => {
                              console.log("clicked");
                              document
                                .getElementById("uploadfileFront")
                                .click();
                            }}
                          >
                            <i class="far fa-folder-open folder-open"></i>
                            <span>Upload Front</span>
                          </button>
                          <input
                            className="upload-file-hide"
                            id="uploadfileFront"
                            type="file"
                            onChange={handleImageUploadFront}
                            accept="image/*"
                            required
                          />
                        
                      </div>
                    </div>
                    <div className="col-md-6 col-sm-6 KYCImage">
                    
                      <div className="col-md-12 col-sm-12 Card-image">
                          {imageBack === "" ? (
                            <img
                              height="200px"
                              width="400px"
                              src={Image}
                              alt="id-card"
                            />
                          ) : (
                            <img
                              height="200px"
                              width="400px"
                              src={imageBack}
                              alt="id-card"
                            />
                          )}
                        </div>
                      
                      <div className="col-md-12 col-sm-12 upload-button KYCImage padding-0">
                       
                          <button
                            className="button-primary button-shadow button-full browse"
                            type="button"
                            onClick={() => {
                              console.log(
                                "clicked",
                                document.getElementById("uploadfileBack")
                              );
                              document.getElementById("uploadfileBack").click();
                            }}
                          >
                            <i class="far fa-folder-open folder-open"></i>
                            <span>Upload Back</span>
                          </button>
                          <input
                            className="upload-file-hide"
                            id="uploadfileBack"
                            type="file"
                            onChange={handleImageUploadBack}
                            accept="image/*"
                            required
                          />
                        </div>{" "}
                      
                    </div>

                    <div className="col-md-12  submit-btn margin-top-10 KYCImage">
                      {" "}
                      <div className="col-md-3 padding-0">
                        {" "}
                        <button
                          className="button-primary button-shadow button-full"
                          type="submit"
                        >
                          <span>SUBMIT</span>
                          <div className="button-triangle"></div>
                          <div className="button-triangle2"></div>
                          <div className="button-icon">
                            <i className="fa fa-user"></i>
                          </div>
                        </button>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
    </div>
  );
}

export default Kyc;
