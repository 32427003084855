import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Pagination, Controller, Thumbs } from "swiper";
import "swiper/swiper-bundle.css";
import "./ListBanner.css";
import { baseURL } from "../../../ApiURL/ApiURL";

SwiperCore.use([Navigation, Pagination, Controller, Thumbs]);

function ListBanner(props) {
  const [thumbsSwiper, setThumbsSwiper] = useState(null);
  const [controlledSwiper, setControlledSwiper] = useState(null);

  /*...........SLIDER IMAGES...................... */

  const slides = [];
  !props.imagesMedia ||
    props.imagesMedia.map((item, index) => {
      return slides.push(
        <SwiperSlide key={`slide-${index}`} tag="li" className="swiper-image">
          <img
            height="500px"
            width="auto"
            src={baseURL + item.mediaUrl}
            style={{ listStyle: "none" }}
            alt={`Slide ${index}`}
          />
        </SwiperSlide>
      );
    });
  /*......................SLIDER thumbnail IMages.................. */
  const thumbs = [];
  !props.imagesMedia ||
    props.imagesMedia.map((item, index) => {
      return thumbs.push(
        <SwiperSlide
          key={`thumb-${index}`}
          tag="li"
          style={{
            listStyle: "none",
            width: "150px!important",
            cursor: "pointer",
          }}
        >
          <img
            height="80px"
            width="140px"
            src={baseURL + item.mediaUrl}
            alt={`Thumbnail ${index}`}
          ></img>
        </SwiperSlide>
      );
    });

  return (
    <React.Fragment>
      <Swiper
        id="main-swiper"
        thumbs={{ swiper: thumbsSwiper }}
        controller={{ control: controlledSwiper }}
        tag="section"
        wrapperTag="ul"
        navigation
        pagination
        spaceBetween={0}
        slidesPerView={1}
        onInit={(swiper) => {}}
        onSlideChange={(swiper) => {}}
        onSwiper={setControlledSwiper}
      >
        {slides}
      </Swiper>

      <div className="thumbs-wrapper section-both-shadow">
        <a className="thumb-box thumb-prev thumb-prev-1 pull-left swiper-button-disabled">
          {/* <div className="navigation-triangle"></div>
          <div className="navigation-box-icon">
            <i className="fa fa-angle-left"></i>
          </div> */}
        </a>
        <Swiper
          id="thumbs"
          spaceBetween={1}
          slidesPerView={4}
          onSwiper={setThumbsSwiper}
        >
          {thumbs}
        </Swiper>
        <a className="thumb-box thumb-next-1 thumb-prev pull-left swiper-button-disabled">
          {/* <div className="navigation-triangle"></div>
          <div className="navigation-box-icon">
            <i className="fa fa-angle-right"></i>
          </div> */}
        </a>
      </div>

      <Swiper id="controller" onSwiper={setControlledSwiper}></Swiper>
    </React.Fragment>
  );
}

export default ListBanner;
