import React, { Component } from "react";
import "./AddProperty.css";

import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";
import { withStyles } from "@material-ui/core/styles";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import iconfolder from "../../../assets/images/icon-folder.png";

import iconbills from "../../../assets/images/bills-icon.png";
import icondocument from "../../../assets/images/Document-icon.png";
import Data from "../../../GeoData.json";
import axios from "axios";
import { baseURL, GoogleMapKey } from "../../../ApiURL/ApiURL";
import MyGoogleMap from "../MyGoogleMap/MyGoogleMap";
import Map from "../MyMap/MyMap";

import {
  geocodeSetAddress,
  geocodegetAddress,
} from "./../../common/GeoCodeAPI/GeoCodeAPI";
import {
  closeModal,
  disableScroll,
  enableScroll,
  getToken,
} from "../../common/commonFunctions/commonFunctions";
import Button from "../../common/Button/Button";
import { withRouter } from "react-router-dom";
import moment from "moment";
import GoogleMapReact from "google-map-react";
const AnyReactComponent = ({ text }) => <div className="pin1"></div>;

const CustomColorCheckbox = withStyles({
  root: {
    color: "#3797dd",
    "&$checked": {
      color: "#3797dd",
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: "none",
  margin: grid * 3,
  width: "200px",
  position: "relative",
  padding: "1px",
  // change background colour if dragging
  background: "white",
  height: "fit-content",
  // styles we need to apply on draggables
  ...draggableStyle,
});
const getListStyle = (isDraggingOver) => ({
  background: "transparent",
  display: "flex",
  height: "285px",
  justifyContent: "space-between",
  alignItems: "center",
  overflow: "auto",
  borderStyle: "dashed",
  borderColor: "black",
  margin: "15px 0 20px 0",
  width: "90%",
  padding: "20px",
});
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};
const propertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
  { id: 13, PropertyTypeName: "Multi-Family" },
];

class AddProperty extends Component {
  state = {
    docValue: "",
    thumbnail: "",
    PropertyTitle: "",
    PropertyTypeId: "",
    cityNames: "",
    Discription: "",
    successMessage: "",
    errorMessage: "",
    TransactionTypes: "",
    // MLS: "",
    StreetName: "",
    StreetNumber: "",
    StreetSuffix: "",
    DirSfx: "",
    UnitNumber: "",
    LocationAddress: "",
    Latitude: "",
    Longitude: "",

    ExteriorFinish: "",
    Flooring: "",

    ParkingType: "",
    YearBuilt: new Date(),

    PropertyStatusDate: new Date(),
    PropertyStatus: "",
    CityAreaName: "",
    CityName: "",
    DistrictName: "",
    CountryName: "",
    Features: "",
    Community: "",
    PropertyStyle: "",
    EstMoPayment: "",
    PricePerSQFeet: "",
    MLSNumber: "",
    BuyerBrockageCommission: "",
    PropertyType: "",
    imageValue: "",
  };
  constructor(props) {
    super(props);
    this.state = {
      docValue: "",
      imageValue: "",
      PropertyType: "",
      thumbnail: "",
      PropertyTitle: "",
      PropertyTypeId: "",
      Discription: "",
      mapLatitude: 59.955413,
      mapLongitude: 30.337844,
      TransactionTypes: "",
      //  MLS: "",
      StreetName: "",
      StreetNumber: "",
      StreetSuffix: "",
      DirSfx: "",
      UnitNumber: "",
      LocationAddress: "",
      Latitude: "",
      Longitude: "",

      ExteriorFinish: "",
      Flooring: "",

      ParkingType: "",
      YearBuilt: new Date(),

      PropertyStatusDate: new Date(),
      PropertyStatus: "",
      CityAreaName: "",
      CityName: "",
      DistrictName: "",
      CountryName: "",
      Features: "",
      Pictures: [],
      Documents: [],
      ExistingBills: [],
      InspectionReports: [],
      HasBasement: false,
      HasImmediatePossession: false,
      HasSwimmingFacility: false,
      HasWaterFront: false,
      HasWaterView: false,
      HasWaterFrontNear: false,
      IsBuilt: false,
      PropertyStatus: "",
      Price: "",
      Bedrooms: "",
      PropertyArea: 0,
      PropertyFrontage: 0,
      PropertyArceage: 0,
      BedroomsAboveGrade: 0,
      FullBaths: 0,
      HalfBaths: 0,
      FullKitchen: 0,
      center: {
        lat: 59.955413,
        lng: 30.337844,
      },
      isLoader: false,
      Community: "",
      PropertyStyle: "",
      EstMoPayment: "",
      PricePerSQFeet: "",
      MLSNumber: "",
      BuyerBrockageCommission: "",
    };
    this.onDragEnd = this.onDragEnd.bind(this);
    this.onDragEndDocuments = this.onDragEndDocuments.bind(this);
    this.onDragEndExisting = this.onDragEndExisting.bind(this);
    this.onDragEndInspection = this.onDragEndInspection.bind(this);
  }

  static defaultProps = {
    zoom: 15,
  };
  componentDidMount() {
    setTimeout(() => {
      closeModal("login-modal");
    }, 1000);
  }
  onDragEndExisting(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const ExistingBills = reorder(
      this.state.ExistingBills,
      result.source.index,
      result.destination.index
    );

    this.setState({
      ExistingBills,
    });
  }
  onDragEndInspection(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const InspectionReports = reorder(
      this.state.InspectionReports,
      result.source.index,
      result.destination.index
    );

    this.setState({
      InspectionReports,
    });
  }
  onDragEndDocuments(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const Documents = reorder(
      this.state.Documents,
      result.source.index,
      result.destination.index
    );

    this.setState({
      Documents,
    });
  }
  onDragEnd(result) {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const Pictures = reorder(
      this.state.Pictures,
      result.source.index,
      result.destination.index
    );

    this.setState({
      Pictures,
    });
  }
  onHandleChange = (event) => {
    console.log("on change ");
    this.setState({ [event.target.name]: event.target.value });
  };
  onHandleCountryChange = (event) => {
    this.setState({
      [event.target.name]: event.target.value,
      CityName: "",
      DistrictName: "",
    });
    let array = [];
    let cityNames = [];
    array = Data.filter(
      (countryName) => countryName.name == event.target.value
    ).map((DistrictName) => DistrictName.states)[0];

    for (let i = 0; i < array.length; i++) {
      array[i].cities.map((state) => {
        return (cityNames = [...cityNames, state.name]);
      });
    }
    console.log("array is ", cityNames.sort());
    this.setState({ cityNames: cityNames.sort() });
  };
  onHandleDistrictChange = (event) => {
    this.setState({ [event.target.name]: event.target.value, CityName: "" });
  };
  onHandleCityChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };

  handlecheckPackage = (event) => {
    console.log("event", event.target.checked);
    if (event.target.checked == true) {
      this.setState({
        [event.target.name]: true,
      });
      console.log([event.target.name]);
    } else {
      this.setState({
        [event.target.name]: false,
      });
    }
  };
  handleChangeDate = (date) => {
    this.setState({
      YearBuilt: date.target.value,
    });
  };
  handlePropertyStatusChangeDate = (date) => {
    this.setState({
      PropertyStatusDate: date.target.value,
    });
  };

  handleDocuemntsUpload = (e) => {
    this.setState({ docValue: e.target.value });
    console.log("docValue", this.state.docValue);
    console.log("this is document", e.target.files);
    let array = [...this.state.Documents];
    [...e.target.files].forEach((file) => {
      array = [
        ...array,
        {
          data: file,
          url: URL.createObjectURL(file),
        },
      ];
    });
    this.setState({
      Documents: array,
    });
    console.log("documents ", array.data);
  };
  handleInsepectionUpload = (e) => {
    console.log("this is document", e.target.files);
    let array = [...this.state.InspectionReports];
    [...e.target.files].forEach((file) => {
      array = [
        ...array,
        {
          data: file,
          url: URL.createObjectURL(file),
        },
      ];
    });
    this.setState({
      InspectionReports: array,
    });
    console.log("documents ", array);
  };

  handleImageUpload = (e) => {
    let array = [...this.state.Pictures];
    [...e.target.files].forEach((file) => {
      array = [
        ...array,
        {
          data: file,
          url: URL.createObjectURL(file),
          Caption: "",
          ISThumbnail: false,
        },
      ];
    });

    this.setState({
      Pictures: array,
    });
    console.log("pictures ", this.state.Pictures);
  };

  handleSecondImageUpload = (e) => {
    let array = [...this.state.Pictures];
    [...e.target.files].forEach((filee) => {
      array = [
        ...array,
        {
          data: filee,
          url: URL.createObjectURL(filee),
          Caption: "",
          ISThumbnail: false,
        },
      ];
    });

    this.setState({
      Pictures: array,
    });
    console.log("pictures ", this.state.Pictures);
  };

  handleThirdImageUpload = (e) => {
    let array = [...this.state.Pictures];
    [...e.target.files].forEach((fiile) => {
      array = [
        ...array,
        {
          data: fiile,
          url: URL.createObjectURL(fiile),
          Caption: "",
          ISThumbnail: false,
        },
      ];
    });

    this.setState({
      Pictures: array,
    });
    console.log("pictures ", this.state.Pictures);
  };
  onRemoveImage = (index) => {
    console.log("index vakue is", index);
    const pictures = [...this.state.Pictures];
    pictures.splice(index, 1);
    this.setState(
      {
        Pictures: pictures,
      },
      () => {}
    );
  };
  onRemoveDocument = (index) => {
    console.log(index);
    const Documents = [...this.state.Documents];
    Documents.splice(index, 1);

    this.setState(
      {
        Documents: Documents,
      },
      () => {}
    );
  };
  handleExistingBillUpload = (e) => {
    let array = [...this.state.ExistingBills];
    [...e.target.files].forEach((file) => {
      array = [
        ...array,
        {
          data: file,
          url: URL.createObjectURL(file),
        },
      ];
    });

    this.setState({
      ExistingBills: array,
    });
    console.log("pictures ", this.state.ExistingBills);
  };
  onRemoveExsistingBill = (index) => {
    console.log(index);
    const ExistingBills = [...this.state.ExistingBills];
    ExistingBills.splice(index, 1);

    this.setState(
      {
        ExistingBills: ExistingBills,
      },
      () => {}
    );
  };
  handleInspectionUpload = (e) => {
    let array = [...this.state.InspectionReports];
    [...e.target.files].forEach((file) => {
      array = [
        ...array,
        {
          data: file,
          url: URL.createObjectURL(file),
        },
      ];
    });

    this.setState({
      InspectionReports: array,
    });
    console.log("inspection ", this.state.InspectionReports);
  };
  onRemoveInspection = (index) => {
    console.log(index);
    const InspectionReports = [...this.state.InspectionReports];
    InspectionReports.splice(index, 1);

    this.setState(
      {
        InspectionReports: InspectionReports,
      },
      () => {}
    );
  };

  onSubmit = (event) => {
    event.preventDefault();
    this.setState({ isLoader: true });
    disableScroll();
    var bodyFormData = new FormData();
    bodyFormData.append("PropertyTitle", this.state.PropertyTitle);
    bodyFormData.append("Discription", this.state.Discription);
    bodyFormData.append("MLS", "");
    bodyFormData.append("StreetNumber", this.state.StreetNumber);
    bodyFormData.append("StreetName", this.state.StreetName);
    bodyFormData.append("StreetSuffix", this.state.StreetSuffix);
    bodyFormData.append("DirSfx", this.state.DirSfx);
    bodyFormData.append("UnitNumber", this.state.UnitNumber);
    bodyFormData.append("PropertyArea", this.state.PropertyArea);
    bodyFormData.append("LocationAddress", this.state.LocationAddress);
    bodyFormData.append("Latitude", parseFloat(this.state.Latitude));
    bodyFormData.append("Longitude", parseFloat(this.state.Longitude));
    bodyFormData.append("PropertyFrontage", this.state.PropertyFrontage);
    bodyFormData.append("PropertyArceage", this.state.PropertyArceage);

    bodyFormData.append(
      "HasImmediatePossession",
      this.state.HasImmediatePossession
    );
    bodyFormData.append("HasSwimmingFacility", this.state.HasSwimmingFacility);
    bodyFormData.append("HasWaterFront", this.state.HasWaterFront);
    bodyFormData.append("HasWaterView", this.state.HasWaterView);
    bodyFormData.append("HasWaterFrontNear", this.state.HasWaterFrontNear);
    bodyFormData.append("Price", this.state.Price);
    bodyFormData.append("Bedrooms", this.state.Bedrooms);
    bodyFormData.append("BedroomsAboveGrade", this.state.BedroomsAboveGrade);
    bodyFormData.append("FullBaths", this.state.FullBaths);
    bodyFormData.append("HalfBaths", this.state.HalfBaths);
    bodyFormData.append("HalfKitchen", "");
    bodyFormData.append("FullKitchen", this.state.FullKitchen);
    bodyFormData.append("ExteriorFinish", this.state.ExteriorFinish);
    bodyFormData.append("HasBasement", this.state.HasBasement);
    bodyFormData.append("ParkingType", this.state.ParkingType);
    bodyFormData.append(
      "YearBuilt",
      moment(this.state.YearBuilt).format("YYYY/MM/DD HH:mm:ss dddd")
    );
    bodyFormData.append("IsBuilt", this.state.IsBuilt);
    bodyFormData.append(
      "PropertyStatusDate",
      moment(this.state.PropertyStatusDate).format("YYYY/MM/DD HH:mm:ss dddd")
    );
    bodyFormData.append("PropertyStatus", this.state.PropertyStatus);
    bodyFormData.append("TransactionTypes", this.state.TransactionTypes);
    bodyFormData.append("CityAreaName", this.state.CityAreaName);
    bodyFormData.append("CityName", this.state.CityName);

    bodyFormData.append("DistrictName", this.state.DistrictName);
    bodyFormData.append("CountryName", this.state.CountryName);
    bodyFormData.append("PropertyTypeId", this.state.PropertyTypeId);
    bodyFormData.append("Community", this.state.Community);
    bodyFormData.append("PropertyStyle", this.state.PropertyStyle);
    bodyFormData.append("EstMoPayment", this.state.EstMoPayment);
    bodyFormData.append("PricePerSquareFeet", this.state.PricePerSQFeet);
    bodyFormData.append("MLS", this.state.MLSNumber);
    bodyFormData.append(
      "BuyerBrokerCommision",
      this.state.BuyerBrockageCommission
    );
    bodyFormData.append("PropertyType", this.state.PropertyType);

    for (const [index, value] of this.state.Pictures.entries(
      this.state.Pictures
    )) {
      bodyFormData.append(`Medias[${index}][Caption]`, value.Caption);
      bodyFormData.append(`Medias[${index}][IsThumbnail]`, value.ISThumbnail);
      bodyFormData.append(`Medias[${index}][SortIndex]`, index);
      bodyFormData.append(`Medias[${index}].Image`, value.data);
    }
    for (const [index, value] of this.state.Documents.entries(
      this.state.Documents
    )) {
      bodyFormData.append(`Proofs[${index}][Caption]`, "");
      bodyFormData.append(`Proofs[${index}][SortIndex]`, index);
      bodyFormData.append(`Proofs[${index}].File`, value.data);
    }
    for (const [index, value] of this.state.ExistingBills.entries(
      this.state.ExistingBills
    )) {
      bodyFormData.append(`ExistingBills[${index}][Caption]`, "");
      bodyFormData.append(`ExistingBills[${index}][SortIndex]`, index);
      bodyFormData.append(`ExistingBills[${index}].File`, value.data);
    }
    for (const [index, value] of this.state.InspectionReports.entries(
      this.state.InspectionReports
    )) {
      bodyFormData.append(`InspectionReports[${index}][Caption]`, "");
      bodyFormData.append(`InspectionReports[${index}][SortIndex]`, index);
      bodyFormData.append(`InspectionReports[${index}].File`, value.data);
    }
    axios({
      method: "post",
      url: baseURL + "api/v1/Property/AddProperty",
      data: bodyFormData,
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        if (response.data.isSuccess) {
          this.setState({ isLoader: false });
          enableScroll();
          this.setState({ successMessage: response.data.message });
          this.props.history.push({
            pathname: "/myproperty",
          });
        } else {
          enableScroll();
          this.setState({ isLoader: false });
          this.setState({ errorMessage: response.data.message });
        }
      })

      .catch((error) => {
        console.log("error", error);
        this.setState({ isLoader: false });
        enableScroll();
        this.setState({
          errorMessage: "Something went Wrong",
        });
      });
  };

  handleClickMap = (e) => {
    console.log("event  is ", e);
    this.setState({
      mapLatitude: e.lat,
      mapLongitude: e.lng,
      LocationAddress: geocodeSetAddress(GoogleMapKey, e.lat, e.lng),
    });
  };

  onChangeFullAddress = (event) => {
    this.setState({ [event.target.name]: event.target.value });

    geocodegetAddress(GoogleMapKey, event.target.value);
  };

  updateLatitudeLongitude = (lat, lng) => {
    this.setState({ Latitude: lat, Longitude: lng });
  };
  render() {
    return (
      <>
        <main className="property-main">
          <section className="short-image no-padding blog-short-title">
            <div className="bg-layer"></div>
            <div className="col-xs-12 col-lg-12">
              <h5 className="subtitle-margin second-color ">To My Account</h5>
              <h1 className="second-color animation-text">Upload property</h1>
              <div className="short-title-separator"></div>
            </div>
          </section>
          <section className="section-light add-property">
            <div className={this.state.isLoader ? "loader-bg" : ""}></div>

            <form className="form-section" onSubmit={this.onSubmit}>
              <div className="container">
                <div className="row">
                  <div className="col-xs-12 col-md-8">
                    <h3 className="title-negative-margin hedng">
                      Listing details
                      {/* <span className="special-color">.</span> */}
                    </h3>
                    <div className="dark-col margin-top-60">
                      <div className="text-danger">
                        {this.state.errorMessage}
                      </div>
                      <div className="text-success">
                        {this.state.successMessage}
                      </div>
                      <div className="row">
                        <div className="col-xs-12 col-sm-12">
                          <input
                            type="text"
                            className="form-control input-full main-input"
                            id="PropertyTitle"
                            name="PropertyTitle"
                            placeholder="Listing Title"
                            required
                            onChange={this.onHandleChange}
                          />
                        </div>
                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-6">
                              <select
                                name="PropertyTypeId"
                                className="form-control input-full main-input property-select"
                                onChange={this.onHandleChange}
                                required
                              >
                                <option value="">Select Property Type</option>
                                {propertyType.map((item) => (
                                  <option value={item.id}>
                                    {item.PropertyTypeName}
                                  </option>
                                ))}
                              </select>
                            </div>
                            <div className="col-xs-12 col-sm-6">
                              <select
                                name="TransactionTypes"
                                className="form-control input-full main-input property-select"
                                id="TransactionTypes"
                                onChange={this.onHandleChange}
                                required
                              >
                                <option value="">TransactionType</option>
                                <option value="Buy">Buy</option>
                                <option value="Rent">Rent</option>
                                <option value="Sold">Sold</option>
                                <option value="Commercial">Commercial</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-6">
                              <input
                                type="number"
                                min="0"
                                className="form-control input-full main-input"
                                id="Price"
                                name="Price"
                                placeholder="Price"
                                onChange={this.onHandleChange}
                                required
                              />
                            </div>
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                type="number"
                                min="0"
                                className="form-control input-full main-input"
                                id="PropertyArea"
                                name="PropertyArea"
                                placeholder="Square footage"
                                onChange={this.onHandleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                type="number"
                                min="0"
                                className="form-control input-full main-input"
                                id="PropertyFrontage"
                                name="PropertyFrontage"
                                placeholder="Frontage"
                                onChange={this.onHandleChange}
                              />
                            </div>
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                type="number"
                                min="0"
                                className="form-control input-full main-input"
                                id="PropertyArceage"
                                name="PropertyArceage"
                                placeholder="Arceage"
                                onChange={this.onHandleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                type="number"
                                min="0"
                                className="form-control  input-full main-input"
                                id="Bedrooms"
                                name="Bedrooms"
                                placeholder="Bedrooms"
                                onChange={this.onHandleChange}
                              />
                            </div>
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                type="number"
                                min="0"
                                className="form-control  input-full main-input"
                                id="BedroomsAboveGrade"
                                name="BedroomsAboveGrade"
                                placeholder="Bedrooms Above Grade"
                                onChange={this.onHandleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                name="FullBaths"
                                type="number"
                                min="0"
                                className=" form-control input-full main-input"
                                placeholder="Bathrooms"
                                onChange={this.onHandleChange}
                              />
                            </div>
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                name="HalfBaths"
                                type="number"
                                min="0"
                                className="form-control input-full main-input"
                                placeholder="Half Bath"
                                onChange={this.onHandleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                name="FullKitchen"
                                type="number"
                                min="0"
                                className="form-control input-full main-input"
                                placeholder="Kitchen"
                                onChange={this.onHandleChange}
                              />
                            </div>

                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                id="ExteriorFinish"
                                name="ExteriorFinish"
                                type="text"
                                className="form-control input-full main-input"
                                placeholder="Exterior"
                                onChange={this.onHandleChange}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                id="Flooring"
                                name="Flooring"
                                type="text"
                                className="form-control input-full main-input"
                                placeholder="Flooring"
                                onChange={this.onHandleChange}
                              />
                            </div>
                            <div className="col-xs-12 col-sm-6 ">
                              <select
                                name="ParkingType"
                                className="form-control property-select"
                                onChange={this.onHandleChange}
                              >
                                <option value="">Select Parking Type</option>
                                <option value="Garage">Garage</option>
                                <option value="Porch">Porch</option>
                                <option value="RoadSide">Roadside</option>
                                <option value="Underground">Underground</option>
                                <option value="ParkingLot">Parking lot</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                required
                                id="BuyerBrockageCommission"
                                name="BuyerBrockageCommission"
                                type="text"
                                className="form-control input-full main-input"
                                placeholder="Buyer's Brockage commission"
                                onChange={this.onHandleChange}
                              />
                            </div>
                            {/* <div className="col-xs-12 col-sm-6 ">
                              <input
                                required
                                id="EstMoPayment"
                                name="EstMoPayment"
                                type="text"
                                className="form-control input-full main-input"
                                placeholder="EstMoPayment"
                                onChange={this.onHandleChange}
                              />
                            </div> */}
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                required
                                id="PricePerSQFeet"
                                name="PricePerSQFeet"
                                type="text"
                                className="form-control input-full main-input"
                                placeholder="Price Per-Square-Feet"
                                onChange={this.onHandleChange}
                              />
                            </div>
                          </div>
                        </div>

                        <div className="col-xs-12 col-sm-12">
                          <div className="row">
                            {/* <div className="col-xs-12 col-sm-6 ">
                              <input
                                required
                                id="Community"
                                name="Community"
                                type="text"
                                className="form-control input-full main-input"
                                placeholder="Community"
                                onChange={this.onHandleChange}
                              />
                            </div> */}
                            <div className="col-xs-12 col-sm-6 ">
                              <select
                                name="PropertyStatus"
                                className="form-control property-select"
                                onChange={this.onHandleChange}
                                required
                              >
                                <option value="">Select Property Status</option>
                                <option value="Active">Active</option>
                                <option value="Rented">Rented</option>
                                <option value="ConditionallySold">
                                  Conditionally Sold
                                </option>
                                <option value="Sold">Sold</option>
                                <option value="Expired">Expired</option>
                                <option value="Cancelled">Cancelled</option>
                                <option value="Suspended">Suspended</option>
                              </select>
                            </div>
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                required
                                id="PropertyStyle"
                                name="PropertyStyle"
                                type="text"
                                className="form-control input-full main-input"
                                placeholder="Property Style"
                                onChange={this.onHandleChange}
                              />
                            </div>
                          </div>
                        </div>
                        {this.state.PropertyStatus === "" ? null : (
                          <div className="col-xs-12 col-sm-12  margin-b">
                            <label for="YearBuilt">*Status Date</label>
                            <TextField
                              className="form-control "
                              id="date"
                              type="date"
                              defaultValue={this.state.PropertyStatusDate}
                              onChange={this.handlePropertyStatusChangeDate}
                            />
                          </div>
                        )}
                      </div>

                      <textarea
                        name="Discription"
                        className="input-full main-input property-textarea"
                        placeholder="Description"
                        onChange={this.onHandleChange}
                      />

                      <div className="row">
                        <div className="col-md-6 col-sm-6 ">
                          <input
                            type="checkbox"
                            id="IsBuilt"
                            name="IsBuilt"
                            className="main-checkbox"
                            onChange={this.handlecheckPackage}
                          />
                          <label for="IsBuilt">
                            <span></span>Built
                          </label>
                          {this.state.IsBuilt ? (
                            <div className="full-div year-build-pnl">
                              <label for="YearBuilt">*Year Built</label>

                              <TextField
                                classNameName="form-control"
                                id="date"
                                type="date"
                                placeholder="Year Built"
                                defaultValue={this.state.YearBuilt}
                                onChange={this.handleChangeDate}
                                required
                              />
                            </div>
                          ) : null}
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <input
                            type="checkbox"
                            id="HasImmediatePossession"
                            name="HasImmediatePossession"
                            className="main-checkbox"
                            onChange={this.handlecheckPackage}
                          />
                          <label for="HasImmediatePossession">
                            <span></span>Has Immediate Possession
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6 ">
                          <input
                            type="checkbox"
                            id="HasSwimmingFacility"
                            name="HasSwimmingFacility"
                            className="main-checkbox"
                            onChange={this.handlecheckPackage}
                          />
                          <label for="HasSwimmingFacility">
                            <span></span>Has Swimming Facility
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6">
                          <input
                            type="checkbox"
                            id="HasWaterFront"
                            name="HasWaterFront"
                            className="main-checkbox"
                            onChange={this.handlecheckPackage}
                          />
                          <label for="HasWaterFront">
                            <span></span>Has Water Front
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <input
                            type="checkbox"
                            id="HasWaterView"
                            name="HasWaterView"
                            className="main-checkbox"
                            onChange={this.handlecheckPackage}
                          />
                          <label for="HasWaterView">
                            <span></span>Has Water View
                          </label>
                        </div>
                        <div className="col-md-6 col-sm-6 ">
                          <input
                            type="checkbox"
                            id="HasWaterFrontNear"
                            name="HasWaterFrontNear"
                            className="main-checkbox"
                            onChange={this.handlecheckPackage}
                          />
                          <label for="HasWaterFrontNear">
                            <span></span>Has Water Front Near
                          </label>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <input
                            type="checkbox"
                            id="HasBasement"
                            name="HasBasement"
                            className="main-checkbox"
                            onChange={this.handlecheckPackage}
                          />
                          <label for="HasBasement">
                            <span></span>Has Basement
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
                    <div className="row">
                      <div className="col-xs-12 col-lg-10 col-md-12">
                        <h3 className="title-negative-margin">
                          Upload Resources
                          {/* <span className="special-color">.</span> */}
                        </h3>
                        <div className="title-separator-primary big"></div>
                      </div>
                      <div className="form-group post-from">
                        <div className="post__pictures col-md-12">
                          <div className="row">
                            <div
                              className="col-md-12 col-sm-6"
                              style={{
                                overflow: "hidden",
                              }}
                            >
                              <DragDropContext
                                onDragEnd={this.onDragEnd}
                                className="DragDrop"
                              >
                                <Droppable
                                  droppableId="droppable"
                                  direction="horizontal"
                                  className="drropdown"
                                  style="margin:40px auto !important"
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      style={getListStyle(
                                        snapshot.isDraggingOver
                                      )}
                                      {...provided.droppableProps}
                                    >
                                      {this.state.Pictures.length > 0
                                        ? this.state.Pictures.map(
                                            (item, index) => (
                                              <Draggable
                                                key={item.url}
                                                draggableId={item.url}
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                      snapshot.isDragging,
                                                      provided.draggableProps
                                                        .style
                                                    )}
                                                  >
                                                    <button
                                                      className="image-close remove-image"
                                                      type="button"
                                                      onClick={() =>
                                                        this.onRemoveImage(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <i className="far fa-trash-alt"></i>
                                                    </button>

                                                    <div className="col-md-12 thumbnail-image ">
                                                      <img
                                                        width="100%"
                                                        height="100%"
                                                        src={item.url}
                                                      />
                                                    </div>
                                                    <div className="col-md-12 image-thumbnail-checkbox">
                                                      <div>Thumbnail</div>
                                                      <CustomColorCheckbox
                                                        checked={
                                                          item.IsThumbnail
                                                        }
                                                        className="ThumbnailCheckBox"
                                                        name="IsThumbnail"
                                                        onChange={(e) => {
                                                          this.setState({
                                                            thumbnail:
                                                              !e.target.checked,
                                                          });
                                                          return (item.ISThumbnail =
                                                            !this.state
                                                              .thumbnail);
                                                        }}
                                                        inputProps={{
                                                          "aria-label":
                                                            "checkbox",
                                                        }}
                                                      />
                                                    </div>
                                                    {/* <div className="col-md-12  image-thumbnail-caption">
                    <input
                      type="text"
                      className="form-control"
                      onChange={(e) => {
                        item.caption = e.target.value;
                      }}
                      placeholder="Enter The Caption"
                    />
                  </div> */}
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          )
                                        : null}
                                      {provided.placeholder}

                                      <button
                                        type="button"
                                        onClick={() => {
                                          console.log("clicked");
                                          document
                                            .getElementById("uploadfile")
                                            .click();
                                        }}
                                        className="Docbtn"
                                        style={{
                                          position: "absolute",
                                          bottom: "40px",
                                          right: "65px",
                                        }}
                                      >
                                        Browse Image
                                      </button>

                                      {/* <span>bbbbbbbbbbbbb</span> */}
                                      <div className="browse-button">
                                        {/* <div className="col-md-10 filenames"></div> */}
                                        <div className=" col-md-12 padding-0">
                                          <p
                                            className="only-text-btn"
                                            type="button"
                                            onClick={() => {
                                              console.log("clicked");
                                              document
                                                .getElementById("uploadfile")
                                                .click();
                                            }}
                                          >
                                            <img src={iconfolder} />
                                            <span>Browse Image</span>
                                          </p>

                                          <input
                                            className="upload-file-hide"
                                            id="uploadfile"
                                            type="file"
                                            multiple
                                            value=""
                                            onChange={this.handleImageUpload}
                                            accept="image/*"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                            <div
                              className="col-md-12 col-sm-6"
                              style={{
                                overflow: "hidden",
                              }}
                            >
                              <DragDropContext
                                onDragEnd={this.onDragEndDocuments}
                                className="DragDrop"
                              >
                                <Droppable
                                  droppableId="droppable"
                                  direction="horizontal"
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      style={getListStyle(
                                        snapshot.isDraggingOver
                                      )}
                                      {...provided.droppableProps}
                                    >
                                      {this.state.Documents.length > 0
                                        ? this.state.Documents.map(
                                            (item, index) => (
                                              <Draggable
                                                key={item.url}
                                                draggableId={item.url}
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                      snapshot.isDragging,
                                                      provided.draggableProps
                                                        .style
                                                    )}
                                                  >
                                                    {" "}
                                                    <p
                                                      style={{
                                                        color: "black",
                                                        marginRight: "40px",
                                                        cursor: "pointer",
                                                      }}
                                                      title={item.data.name}
                                                    >
                                                      {item.data.name.substring(
                                                        0,
                                                        9
                                                      )}
                                                      <br />
                                                    </p>
                                                    <button
                                                      className="image-close remove-image"
                                                      type="button"
                                                      onClick={() =>
                                                        this.onRemoveDocument(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <i className="far fa-trash-alt"></i>
                                                    </button>
                                                    <div className="col-md-12 thumbnail-image ">
                                                      <iframe
                                                        src={item.url}
                                                        width="100%"
                                                        height="100%"
                                                        frameborder="1"
                                                      ></iframe>
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          )
                                        : null}
                                      {provided.placeholder}
                                      <button
                                        type="button"
                                        onClick={() => {
                                          console.log("clicked");
                                          document
                                            .getElementById("uploadDocument")
                                            .click();
                                        }}
                                        className="Docbtn"
                                        style={{
                                          position: "absolute",
                                          bottom: "40px",
                                          right: "65px",
                                        }}
                                      >
                                        Upload Documents
                                      </button>
                                      <div className="browse-button">
                                        <div className="  col-md-12 mb-5">
                                          <p
                                            className="only-text-btn"
                                            type="button"
                                            onClick={() => {
                                              console.log("clicked");
                                              document
                                                .getElementById(
                                                  "uploadDocument"
                                                )
                                                .click();
                                            }}
                                          >
                                            {" "}
                                            <img src={icondocument} />
                                            <span>Upload documents</span>
                                            {/* <i class="far fa-folder-open folder-open"></i>
                                            <span>Upload documents</span> */}
                                          </p>
                                          <input
                                            className="upload-file-hide"
                                            id="uploadDocument"
                                            value=""
                                            type="file"
                                            name="Document"
                                            multiple
                                            onChange={
                                              this.handleDocuemntsUpload
                                            }
                                            accept="application/pdf"
                                          />
                                        </div>
                                      </div>

                                      {/* <input
                                            className="upload-file-hide"
                                            id="uploadfile"
                                            type="file"
                                            multiple
                                            onChange={this.handleSecondImageUpload}
                                            accept="application/pdf"
                                            required
                                          /> */}
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                            <div
                              className="col-md-12 col-sm-6"
                              style={{
                                overflow: "hidden",
                              }}
                            >
                              <DragDropContext
                                onDragEnd={this.onDragEndExisting}
                                className="DragDrop"
                              >
                                <Droppable
                                  droppableId="droppable"
                                  direction="horizontal"
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      style={getListStyle(
                                        snapshot.isDraggingOver
                                      )}
                                      {...provided.droppableProps}
                                    >
                                      {this.state.ExistingBills.length > 0
                                        ? this.state.ExistingBills.map(
                                            (item, index) => (
                                              <Draggable
                                                key={item.url}
                                                draggableId={item.url}
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                      snapshot.isDragging,
                                                      provided.draggableProps
                                                        .style
                                                    )}
                                                  >
                                                    <p
                                                      style={{
                                                        color: "black",
                                                        marginRight: "40px",
                                                      }}
                                                      title={item.data.name}
                                                    >
                                                      {item.data.name.substring(
                                                        0,
                                                        9
                                                      )}
                                                      <br />
                                                    </p>
                                                    <button
                                                      className="image-close remove-image"
                                                      type="button"
                                                      onClick={() =>
                                                        this.onRemoveExsistingBill(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <i className="far fa-trash-alt"></i>
                                                    </button>

                                                    <div className="col-md-12 thumbnail-image ">
                                                      <iframe
                                                        src={item.url}
                                                        width="100%"
                                                        height="100%"
                                                        frameborder="1"
                                                      ></iframe>
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          )
                                        : null}
                                      {provided.placeholder}
                                      <button
                                        type="button"
                                        onClick={() => {
                                          console.log("clicked");
                                          document
                                            .getElementById(
                                              "uploadExistingBills"
                                            )
                                            .click();
                                        }}
                                        className="Docbtn"
                                        style={{
                                          position: "absolute",
                                          bottom: "40px",
                                          right: "65px",
                                        }}
                                      >
                                        Upload Existing Bills
                                      </button>

                                      <div className="browse-button">
                                        <div className="  col-md-12 padding-0">
                                          <p className="only-text-btn">
                                            <img src={icondocument} />
                                            <span>Upload Existing Bills</span>
                                            {/* <i class="far fa-folder-open folder-open"></i>
                                            <span>Upload Existing Bills</span> */}
                                          </p>
                                          <input
                                            value=""
                                            className="upload-file-hide"
                                            id="uploadExistingBills"
                                            type="file"
                                            name="Bills"
                                            multiple
                                            onChange={
                                              this.handleExistingBillUpload
                                            }
                                            accept="application/pdf"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                            <div
                              className="col-md-12 col-sm-6"
                              style={{
                                overflow: "hidden",
                              }}
                            >
                              <DragDropContext
                                onDragEnd={this.onDragEndExisting}
                                className="DragDrop"
                              >
                                <Droppable
                                  droppableId="droppable"
                                  direction="horizontal"
                                >
                                  {(provided, snapshot) => (
                                    <div
                                      ref={provided.innerRef}
                                      style={getListStyle(
                                        snapshot.isDraggingOver
                                      )}
                                      {...provided.droppableProps}
                                    >
                                      {this.state.InspectionReports.length > 0
                                        ? this.state.InspectionReports.map(
                                            (item, index) => (
                                              <Draggable
                                                key={item.url}
                                                draggableId={item.url}
                                                index={index}
                                              >
                                                {(provided, snapshot) => (
                                                  <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                      snapshot.isDragging,
                                                      provided.draggableProps
                                                        .style
                                                    )}
                                                  >
                                                    <p
                                                      style={{
                                                        color: "black",
                                                        marginRight: "40px",
                                                      }}
                                                      title={item.data.name}
                                                    >
                                                      {item.data.name.substring(
                                                        0,
                                                        9
                                                      )}
                                                      <br />
                                                    </p>
                                                    <button
                                                      className="image-close remove-image"
                                                      type="button"
                                                      onClick={() =>
                                                        this.onRemoveInspection(
                                                          index
                                                        )
                                                      }
                                                    >
                                                      <i className="far fa-trash-alt"></i>
                                                    </button>

                                                    <div className="col-md-12 thumbnail-image ">
                                                      <iframe
                                                        src={item.url}
                                                        width="100%"
                                                        height="100%"
                                                        frameborder="1"
                                                      ></iframe>
                                                    </div>
                                                  </div>
                                                )}
                                              </Draggable>
                                            )
                                          )
                                        : null}
                                      {provided.placeholder}
                                      <button
                                        type="button"
                                        onClick={() => {
                                          console.log("clicked");
                                          document
                                            .getElementById("InspectionReports")
                                            .click();
                                        }}
                                        className="Docbtn"
                                        style={{
                                          position: "absolute",
                                          bottom: "40px",
                                          right: "65px",
                                        }}
                                      >
                                        Upload Inspection Reports
                                      </button>

                                      <div className="browse-button">
                                        <div className="  col-md-12 padding-0">
                                          <p
                                            className="only-text-btn"
                                            type="button"
                                            onClick={() => {
                                              console.log("clicked");
                                              document
                                                .getElementById(
                                                  "InspectionReports"
                                                )
                                                .click();
                                            }}
                                          >
                                            <img src={icondocument} />
                                            <span>
                                              Upload Inspection Reports
                                            </span>
                                            {/* <i class="far fa-folder-open folder-open"></i>
                                            <span>Upload Inspection Reports</span> */}
                                          </p>
                                          <input
                                            value=""
                                            className="upload-file-hide"
                                            id="InspectionReports"
                                            type="file"
                                            name="Bills"
                                            multiple
                                            onChange={
                                              this.handleInsepectionUpload
                                            }
                                            accept="application/pdf"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </Droppable>
                              </DragDropContext>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xs-12 col-md-12 margin-top-30 margin-top-xs-60 margin-top-sm-60 margin-bottom-sm-60">
                    <div className="row">
                      <div className="col-lg-6 col-sm-12 col-md-12">
                        <h3 className="title-negative-margin hedng">
                          Localization
                          {/* <span className="special-color">.</span> */}
                        </h3>
                        <div className="dark-col margin-top-60">
                          <div className="text-danger">
                            {this.state.errorMessage}
                          </div>
                          <div className="text-success">
                            {this.state.successMessage}
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <input
                                type="text"
                                className="form-control input-full main-input"
                                id="LocationAddress"
                                name="LocationAddress"
                                placeholder="Full Address"
                                required
                                onChange={this.onChangeFullAddress}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <select
                                name="CountryName"
                                className="form-control input-full main-input property-select"
                                onChange={this.onHandleCountryChange}
                                required
                              >
                                <option value="">Select Country</option>
                                <option value="United States">
                                  United States
                                </option>
                                {/* {Data.map((countryName) => (
                                  <option value={countryName.name}>
                                    {countryName.name}
                                  </option>
                                ))} */}
                              </select>
                            </div>

                            {this.state.CountryName === "" ||
                            this.state.CountryName === undefined ? null : (
                              <div className="col-md-12  ">
                                {/* <label for="CityName">*City Name </label> */}
                                <select
                                  name="CityName"
                                  className="form-control input-full main-input property-select"
                                  onChange={this.onHandleCityChange}
                                  required
                                >
                                  <option value="">Select City</option>
                                  {this.state.cityNames.map((city) => (
                                    <option value={city}>{city}</option>
                                  ))}
                                </select>
                              </div>
                            )}
                            {this.state.CityName == undefined ||
                            this.state.CityName == "" ? null : (
                              <div className="col-md-12 ">
                                <input
                                  type="text"
                                  className="form-control input-full main-input"
                                  name="CityAreaName"
                                  placeholder="Town"
                                  onChange={this.onHandleChange}
                                  required
                                />
                              </div>
                            )}
                          </div>
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                name="StreetName"
                                type="text"
                                className="form-control input-full main-input input-last"
                                placeholder="Street Name"
                                onChange={this.onHandleChange}
                              />
                            </div>
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                name="StreetNumber"
                                type="number"
                                min="0"
                                className="form-control input-full main-input input-last"
                                placeholder="Street Number"
                                onChange={this.onHandleChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                name="Latitude"
                                type="text"
                                className="form-control input-full main-input input-last"
                                placeholder="Latitude"
                                value={this.state.Latitude}
                                onChange={this.onHandleChange}
                                required
                              />
                            </div>
                            <div className="col-xs-12 col-sm-6 ">
                              <input
                                name="Longitude"
                                type="text"
                                min="0"
                                className="form-control input-full main-input input-last"
                                placeholder="longitude"
                                value={this.state.Longitude}
                                onChange={this.onHandleChange}
                                required
                              />
                            </div>
                          </div>
                          {console.log(this.state.PropertyTypeId == 1)}
                          {this.state.PropertyTypeId == 1 ? null : (
                            <div className="row">
                              <div className="col-xs-12 col-sm-12">
                                <input
                                  name="UnitNumber"
                                  type="number"
                                  min="0"
                                  className="form-control input-full main-input input-last"
                                  placeholder="Unit Number"
                                  onChange={this.onHandleChange}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="row">
                          <div className="col-md-12">
                            <p className="negative-margin bold-indent margin-top-15">
                              Or drag the marker to property position
                            </p>
                          </div>
                        </div>
                        <div
                          id="submit-property-map"
                          class="submit-property-map"
                        >
                          {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d86094.71198750017!2d-122.22117104328487!3d47.59774939291554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906bcfa3a66041%3A0xbacf5482ead00765!2sBellevue%2C%20WA%2C%20USA!5e0!3m2!1sen!2s!4v1643263821012!5m2!1sen!2s" ></iframe> */}
                          {/* <MyGoogleMap/> */}
                          <div>
                            <Map
                              google={this.props.google}
                              center={{ lat: 18.5204, lng: 73.8567 }}
                              height="300px"
                              zoom={15}
                              updateLatitudeLongitude={
                                this.updateLatitudeLongitude
                              }
                            />
                          </div>
                          {/* <GoogleMapReact
                            bootstrapURLKeys={{
                              key: GoogleMapKey,
                            }}
                            yesIWantToUseGoogleMapApiInternals={true}
                            defaultCenter={this.state.center}
                            defaultZoom={this.props.zoom}
                            onClick={this.handleClickMap}
                          >
                            <AnyReactComponent
                              lat={this.state.mapLatitude}
                              lng={this.state.mapLongitude}
                              text="My Marker"
                            />
                          </GoogleMapReact> */}
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div className="col-xs-12 margin-top-60">
                    <div className="row margin-top-45">
                      <div className="col-xs-12 apartment-tabs">
                        <ul className="nav nav-tabs" role="tablist">
                          <li role="presentation" className="active">
                            <a
                              href="#tab-doc"
                              aria-controls="tab-doc"
                              role="tab"
                              data-toggle="tab"
                            >
                              <span>Documents</span>
                              <div className="button-triangle2"></div>
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#tab-Inspection-report"
                              aria-controls="tab-Inspection-report"
                              role="tab"
                              data-toggle="tab"
                            >
                              <span> Inspection Report</span>
                              <div className="button-triangle2"></div>
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#tab-existing-bills"
                              aria-controls="tab-existing-bills"
                              role="tab"
                              data-toggle="tab"
                            >
                              <span> Existing Bills </span>
                              <div className="button-triangle2"></div>
                            </a>
                          </li>
                        </ul>

                        <div className="tab-content">
                          <div
                            role="tabpanel"
                            className="tab-pane active"
                            id="tab-doc"
                          >
                            <div style={{ height: "570px", width: "100%" }}>
                              <div className="form-group">
                                <div className="post__pictures">
                                  <div
                                    className="col-md-12"
                                    style={{
                                      backgroundColor: "#3797dd",
                                      padding: "5px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <DragDropContext
                                      onDragEnd={this.onDragEndDocuments}
                                      className="DragDrop"
                                    >
                                      <Droppable
                                        droppableId="droppable"
                                        direction="horizontal"
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            style={getListStyle(
                                              snapshot.isDraggingOver
                                            )}
                                            {...provided.droppableProps}
                                          >
                                            {this.state.Documents.length > 0
                                              ? this.state.Documents.map(
                                                (item, index) => (
                                                  <Draggable
                                                    key={item.url}
                                                    draggableId={item.url}
                                                    index={index}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided
                                                            .draggableProps
                                                            .style
                                                        )}
                                                      >
                                                        <button
                                                          className="image-close remove-image"
                                                          type="button"
                                                          onClick={() =>
                                                            this.onRemoveDocument(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <i className="far fa-trash-alt"></i>
                                                        </button>

                                                        <div className="col-md-12 thumbnail-image ">
                                                          <iframe
                                                            src={item.url}
                                                            width="100%"
                                                            height="100%"
                                                            frameborder="1"
                                                          ></iframe>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )
                                              )
                                              : null}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                    <div className="browse-button">
                                      <div className="col-md-10 filenames"></div>
                                      <div className="  col-md-2 padding-0">
                                        <button
                                          className="button-primary button-shadow button-full browse"
                                          type="button"
                                          onClick={() => {
                                            console.log("clicked");
                                            document
                                              .getElementById("uploadDocument")
                                              .click();
                                          }}
                                        >
                                          <i class="far fa-folder-open folder-open"></i>
                                          <span>documents...</span>
                                        </button>
                                        <input
                                          className="upload-file-hide"
                                          id="uploadDocument"
                                          type="file"
                                          multiple
                                          onChange={this.handleDocuemntsUpload}
                                          accept="image/jpeg, image/png ,application/pdf,.doc,.docx,application/msword"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div
                            role="tabpanel"
                            className="tab-pane "
                            id="tab-Inspection-report"
                          >
                            <div style={{ height: "570px", width: "100%" }}>
                              <div className="form-group">
                                <div className="post__pictures">
                                  <div
                                    className="col-md-12"
                                    style={{
                                      backgroundColor: "#3797dd",
                                      padding: "5px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <DragDropContext
                                      onDragEnd={this.onDragEndInspection}
                                      className="DragDrop"
                                    >
                                      <Droppable
                                        droppableId="droppable"
                                        direction="horizontal"
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            style={getListStyle(
                                              snapshot.isDraggingOver
                                            )}
                                            {...provided.droppableProps}
                                          >
                                            {this.state.InspectionReports.length >
                                              0
                                              ? this.state.InspectionReports.map(
                                                (item, index) => (
                                                  <Draggable
                                                    key={item.url}
                                                    draggableId={item.url}
                                                    index={index}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided
                                                            .draggableProps
                                                            .style
                                                        )}
                                                      >
                                                        <button
                                                          className="image-close remove-image"
                                                          type="button"
                                                          onClick={() =>
                                                            this.onRemoveInspection(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <i className="far fa-trash-alt"></i>
                                                        </button>

                                                        <div className="col-md-12 thumbnail-image ">
                                                          <iframe
                                                            src={item.url}
                                                            width="100%"
                                                            height="100%"
                                                            frameborder="1"
                                                          ></iframe>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )
                                              )
                                              : null}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                    <div className="browse-button">
                                      <div className="col-md-10 filenames"></div>
                                      <div className="  col-md-2 padding-0">
                                        <button
                                          className="button-primary button-shadow button-full browse"
                                          type="button"
                                          onClick={() => {
                                            console.log("clicked");
                                            document
                                              .getElementById("uploadInspection")
                                              .click();
                                          }}
                                        >
                                          <i class="far fa-folder-open folder-open"></i>
                                          <span>Upload...</span>
                                        </button>
                                        <input
                                          className="upload-file-hide"
                                          id="uploadInspection"
                                          type="file"
                                          multiple
                                          onChange={this.handleInspectionUpload}
                                          accept="image/jpeg, image/png ,application/pdf,.doc,.docx,application/msword"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            role="tabpanel"
                            className="tab-pane "
                            id="tab-existing-bills"
                          >
                            <div style={{ height: "570px", width: "100%" }}>
                              <div className="form-group">
                                <div className="post__pictures">
                                  <div
                                    className="col-md-12"
                                    style={{
                                      backgroundColor: "#3797dd",
                                      padding: "5px",
                                      overflow: "hidden",
                                    }}
                                  >
                                    <DragDropContext
                                      onDragEnd={this.onDragEndExisting}
                                      className="DragDrop"
                                    >
                                      <Droppable
                                        droppableId="droppable"
                                        direction="horizontal"
                                      >
                                        {(provided, snapshot) => (
                                          <div
                                            ref={provided.innerRef}
                                            style={getListStyle(
                                              snapshot.isDraggingOver
                                            )}
                                            {...provided.droppableProps}
                                          >
                                            {this.state.ExistingBills.length > 0
                                              ? this.state.ExistingBills.map(
                                                (item, index) => (
                                                  <Draggable
                                                    key={item.url}
                                                    draggableId={item.url}
                                                    index={index}
                                                  >
                                                    {(provided, snapshot) => (
                                                      <div
                                                        ref={provided.innerRef}
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        style={getItemStyle(
                                                          snapshot.isDragging,
                                                          provided
                                                            .draggableProps
                                                            .style
                                                        )}
                                                      >
                                                        <button
                                                          className="image-close remove-image"
                                                          type="button"
                                                          onClick={() =>
                                                            this.onRemoveExsistingBill(
                                                              index
                                                            )
                                                          }
                                                        >
                                                          <i className="far fa-trash-alt"></i>
                                                        </button>

                                                        <div className="col-md-12 thumbnail-image ">
                                                          <iframe
                                                            src={item.url}
                                                            width="100%"
                                                            height="100%"
                                                            frameborder="1"
                                                          ></iframe>
                                                        </div>
                                                      </div>
                                                    )}
                                                  </Draggable>
                                                )
                                              )
                                              : null}
                                            {provided.placeholder}
                                          </div>
                                        )}
                                      </Droppable>
                                    </DragDropContext>
                                    <div className="browse-button">
                                      <div className="col-md-10 filenames"></div>
                                      <div className="  col-md-2 padding-0">
                                        <button
                                          className="button-primary button-shadow button-full browse"
                                          type="button"
                                          onClick={() => {
                                            console.log("clicked");
                                            document
                                              .getElementById(
                                                "uploadExistingBills"
                                              )
                                              .click();
                                          }}
                                        >
                                          <i class="far fa-folder-open folder-open"></i>
                                          <span>Upload...</span>
                                        </button>
                                        <input
                                          className="upload-file-hide"
                                          id="uploadExistingBills"
                                          type="file"
                                          multiple
                                          onChange={this.handleExistingBillUpload}
                                          accept="image/jpeg, image/png ,application/pdf,.doc,.docx,application/msword"
                                        />
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div> */}

                  <div className="col-md-12 col-sm-12 col-xl-12  submit-btn margin-top-30">
                    <div className="col-md-4 col-lg-3">
                      <button
                        className="button-primary  button-shadow button-full"
                        type="submit"
                      >
                        <span>Upload Property</span>
                        <div className="button-triangle"></div>
                        <div className="button-triangle2"></div>
                        <div className="button-icon">
                          <i className="fa fa-lg fa-home"></i>
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </section>
        </main>
      </>
    );
  }
}

export default withRouter(AddProperty);
