import React, { Component } from "react";
import "./Offers.css";
import {
  disableScroll,
  enableScroll,
  getToken,
  getUserName,
  counter,
} from "../../common/commonFunctions/commonFunctions";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";

class Offers extends Component {
  state = {
    collapse: false,
    collapseId: null,
    isLoader: false,
    offerList: [],
    getOfferChatMessages: [],
    messageBody: "",
    sentList: [],
    recievedList: [],
    OfferUuid: "",
    errorMessage: "",
    sendMessageAppend: [],
  };
  handleClick = (id) => {
    if (id === this.state.collapseId && this.state.collapse === true) {
      this.setState({ collapse: false });
    } else {
      axios({
        method: "post",
        url: baseURL + "api/Offer/MarkAsSeen",
        data: {
          offerUuid: id,
        },
        headers: {
          "Content-Type": "application/json-patch+json",
          accept: "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => { })
        .catch((error) => { });
      axios({
        method: "get",
        url: baseURL + "api/Offer/GetOfferChat?OfferUuid=" + id,
        headers: {
          "Content-Type": "application/json-patch+json",
          accept: "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          this.setState({
            getOfferChatMessages: response.data.data,
          });
        })
        .catch((error) => { });

      this.setState({ collapse: true, collapseId: id });
    }
  };
  acceptOffer = () => {
    this.setState({ isLoader: true });
    axios({
      method: "post",
      url: baseURL + "api/Offer/AcceptOffer",
      data: {
        offerUuid: this.state.OfferUuid,
      },
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        let recievedList = [];
        let sentList = [];
        this.setState({ isLoader: true });

        disableScroll();

        function unseenMessageCounter(items, prop) {
          return items.reduce(function (a, b) {
            return a + b[prop];
          }, 0);
        }
        axios({
          method: "get",
          url: baseURL + "api/Offer/GetOffers",
          headers: {
            "Content-Type": "application/json-patch+json",
            accept: "text/plain",
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            this.setState({ isLoader: false });
            enableScroll();

            const offerDetail = response.data.data.map((item) => {
              return {
                ...item,
                unseenMessages: unseenMessageCounter(
                  item.offerChatStatus,
                  "unseenMessages"
                ),
              };
            });
            recievedList = offerDetail.filter(
              (item) => localStorage.getItem("email") === item.offerTo.email
            );
            sentList = offerDetail.filter(
              (item) => localStorage.getItem("email") !== item.offerTo.email
            );

            this.setState({
              offerList: offerDetail,

              sentList,
              recievedList,
            });
            this.handleCount(counter(offerDetail));
          })
          .catch((error) => {
            this.setState({ isLoader: false });
            enableScroll();
          });
        axios({
          method: "post",
          url: baseURL + "api/Offer/MarkAsSeen",
          data: {
            offerUuid: this.state.OfferUuid,
          },
          headers: {
            "Content-Type": "application/json-patch+json",
            accept: "text/plain",
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => { })
          .catch((error) => { });

        this.setState({
          isLoader: false,
          errorMessage: response.data.message,
        });
      })
      .catch((error) => {
        this.setState({
          isLoader: false,
          errorMessage: "Something went Wrong",
        });
      });
  };
  rejectOffer = () => {
    this.setState({ isLoader: true });
    axios({
      method: "post",
      url: baseURL + "api/Offer/DismissOffer",
      data: {
        offerUuid: this.state.OfferUuid,
      },
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        let recievedList = [];
        let sentList = [];

        function unseenMessageCounter(items, prop) {
          return items.reduce(function (a, b) {
            return a + b[prop];
          }, 0);
        }
        axios({
          method: "get",
          url: baseURL + "api/Offer/GetOffers",
          headers: {
            "Content-Type": "application/json-patch+json",
            accept: "text/plain",
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            this.setState({ isLoader: false });
            enableScroll();

            const offerDetail = response.data.data.map((item) => {
              return {
                ...item,
                unseenMessages: unseenMessageCounter(
                  item.offerChatStatus,
                  "unseenMessages"
                ),
              };
            });
            recievedList = offerDetail.filter(
              (item) => localStorage.getItem("email") === item.offerTo.email
            );
            sentList = offerDetail.filter(
              (item) => localStorage.getItem("email") !== item.offerTo.email
            );

            this.setState({
              offerList: offerDetail,

              sentList,
              recievedList,
            });
            this.handleCount(counter(offerDetail));
          })
          .catch((error) => {
            this.setState({ isLoader: false });
            enableScroll();
          });
        axios({
          method: "post",
          url: baseURL + "api/Offer/MarkAsSeen",
          data: {
            offerUuid: this.state.OfferUuid,
          },
          headers: {
            "Content-Type": "application/json-patch+json",
            accept: "text/plain",
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => { })
          .catch((error) => { });
        this.setState({
          isLoader: false,
          errorMessage: response.data.message,
        });
      })
      .catch((error) => {
        this.setState({
          isLoader: false,
          errorMessage: "Something went Wrong",
        });
      });
  };
  componentDidMount() {
    let recievedList = [];
    let sentList = [];
    this.setState({ isLoader: true });

    disableScroll();

    function unseenMessageCounter(items, prop) {
      return items.reduce(function (a, b) {
        return a + b[prop];
      }, 0);
    }
    axios({
      method: "get",
      url: baseURL + "api/Offer/GetOffers",
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        this.setState({ isLoader: false });
        enableScroll();

        const offerDetail = response.data.data.map((item) => {
          return {
            ...item,
            unseenMessages: unseenMessageCounter(
              item.offerChatStatus,
              "unseenMessages"
            ),
          
          };
        });


        response.data.data.map((item) => {
       console.log("ressssssssssssssss user email is",item.offerTo.email)
        });

        console.log("mmmmmmmmmmmmmmmm",localStorage.getItem("email"))
        recievedList = offerDetail.filter(
          (item) => localStorage.getItem("email") === item.offerTo.email
        );
        sentList = offerDetail.filter(
          (item) => localStorage.getItem("email") !== item.offerTo.email
        );

        this.setState({
          offerList: offerDetail,

          sentList,
          recievedList,
        });
        this.handleCount(counter(offerDetail));
      })
      .catch((error) => {
        this.setState({ isLoader: false });
        enableScroll();
      });
  }
  marKAsSeen = (id) => {
    axios({
      method: "post",
      url: baseURL + "api/Offer/MarkAsSeen",
      data: {
        offerUuid: id,
      },
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        this.setState({
          isLoader: false,
          errorMessage: response.data.message,
        });
      })
      .catch((error) => {
        this.setState({
          isLoader: false,
          errorMessage: "Something went Wrong",
        });
      });
  };
  onMessageRecieve = () => {
    this.setState({
      getOfferChatMessages: this.state.getOfferChatMessages.push(
        this.props.offerChatDetails
      ),
    });
  };
  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  sendMessage = (id) => {
    if (this.state.messageBody !== "") {
      let array = [];
      let obj = {
        message: this.state.messageBody,
        from: { email: localStorage.getItem("email"), name: getUserName() },
        uuid: id,
      };
      array = this.state.getOfferChatMessages;
      array.push(obj);

      axios({
        method: "post",
        url: baseURL + "api/Offer/SendMessage",
        data: {
          offerUuid: id,
          messageBody: this.state.messageBody,
        },
        headers: {
          "Content-Type": "application/json-patch+json",
          accept: "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          this.setState({
            messageBody: "",
            getOfferChatMessages: array,
          });

          document.getElementById("messageBody").value = "";
          var elem = document.getElementById("chatMessageView");
          elem.scrollTop = elem.scrollHeight;
        })
        .catch((error) => { });
    } else {
    }
  };

  updateOffer = (object) => {
    let array = [];
    let recievedList = [];
    let sentList = [];
    array = this.state.offerList;

    let arrayFilter = array.filter((item) => object.uuid === item.uuid);

    if (arrayFilter.length > 0) {
      let index = array.findIndex((x) => x.uuid === object.uuid);
      array[index] = object;
    } else {
      array = [object, ...array];
    }
    console.log("array after ", array);
    recievedList = array.filter(
      (item) => localStorage.getItem("email") === item.offerTo.email,
     
    );
    sentList = array.filter(
      (item) => localStorage.getItem("email") !== item.offerTo.email
    );

    this.setState({
      offerList: array,
      sentList,
      recievedList,
    });

    this.handleCount(counter(this.state.offerList));
  };
  setFromOutside = (object) => {
    let array = [];
    array = this.state.getOfferChatMessages;

    array.push(object);

    this.setState({
      getOfferChatMessages: array,
    });
  };

  handleCount = (count) => {
    this.props.onHandleCount(count);
  };
  render() {
    return (
      <>
        <main className="property-main">
          <div className={this.state.isLoader ? "loader-bg" : ""}></div>
          <section className="short-image small-header no-padding blog-short-title">
            <div className="bg-layer"></div>
            <div className="container">
              <div className="row">
                <div className="col-xs-12 col-lg-12 short-image-title">
                  <h5 className="subtitle-margin second-color">Offers</h5>
                  <h1 className="second-color">my account</h1>
                  <div className="short-title-separator"></div>
                </div>
              </div>
            </div>
          </section>
          
          <section className="section-light section-top-shadow chat-offer offer-my-pnl">
            <div class="container">
              <div class="row">
                <div class="col-xs-12 col-md-12">
                  <div className="row margin-top-45">
                    <div className="col-xs-12 apartment-tabs">
                      <ul className="nav nav-tabs" role="tablist">
                        <li role="presentation" className="active">
                          <a
                            href="#tab-sent"
                            aria-controls="tab-sent"
                            role="tab"
                            data-toggle="tab"
                          >
                            <span>Sent</span>
                            <div className="button-triangle2"></div>
                          </a>
                        </li>
                        <li role="presentation">
                          <a
                            href="#tab-recieved"
                            aria-controls="tab-recieved"
                            role="tab"
                            data-toggle="tab"
                          >
                            <span>Recieved</span>
                            <div className="button-triangle2"></div>
                          </a>
                        </li>
                      </ul>

                      <div className="tab-content">
                        <div
                          role="tabpanel"
                          className="tab-pane active"
                          id="tab-sent"
                        >
                          <div style={{ height: "auto", width: "100%" }}>
                            {this.state.sentList?.length > 0 ? null : (
                              <div>There is no data</div>
                            )}
                            {this.state.sentList == null ||
                              this.state.sentList.map((item) => (
                                <div class="row list-offer-row">
                                  <div class="col-xs-12">
                                    <div class="list-offer">
                                      <div class="offer-list">
                                        <div class="list-offer-text">
                                          <div class="list-offer-h4">
                                            {item.offerChatStatus.find(
                                              (item) =>
                                                localStorage.getItem("email") ===
                                                item.account.email
                                            ) && item.offerChatStatus.filter(
                                              (item) =>
                                                localStorage.getItem("email") ===
                                                item.account.email
                                            )[0].unseenMessages > 0 ? (
                                              <div className="badge">
                                                Not Seen
                                              </div>
                                            ) : null}
                                            <a href="#">
                                              <h1 class="list-offer-title">{item.property.propertyTitle}</h1>
                                              <h4 class="list-offer-title">
                                                {item.offerBy.name}
                                              </h4>
                                            </a>
                                          </div>
                                          <div class="clearfix">
                                                 <img width="20%" style={{float:"right",marginBottom:"30px"}} src={baseURL+item.property.propertyMedia[0]?.mediaUrl}/>
                                          </div>
                                          <p class="description-p">
                                            {item.message}
                                          </p>

                                          <div class="clearfix"></div>
                                        </div>
                                        <div class="profile-list-footer">
                                          <div class="list-price profile-list-price">
                                            <span className="offer-price">
                                              Offer Price
                                            </span>{" "}
                                            ${item.offerPrice}
                                          </div>

                                          {(item.isAccepted || item.isDeclined) &&
                                            item.offerChatStatus.find(
                                              (item) =>
                                                localStorage.getItem("email") ==
                                                item.account.email
                                            ) && item.offerChatStatus.filter(
                                              (item) =>
                                                localStorage.getItem("email") ==
                                                item.account.email
                                            )[0].unseenMessages > 0 ? (
                                            <a
                                              onClick={() =>
                                                this.marKAsSeen(item.uuid)
                                              }
                                              class="chat-btn profile-list-delete"
                                              title="Mark Seen"
                                              data-toggle="modal"
                                              data-target="#message"
                                            >
                                              <i class="fas fa-eye"></i>
                                            </a>
                                          ) : null}
                                          {item.isAccepted ||
                                            item.isDeclined ? null : (
                                            <>
                                              {" "}
                                              <a
                                                onClick={() =>
                                                  this.handleClick(item.uuid)
                                                }
                                                class="chat-btn profile-list-delete"
                                                title="Chat"
                                              >
                                                <i class="fas fa-comments"></i>
                                              </a>
                                              <a
                                                onClick={() =>
                                                  this.setState({
                                                    OfferUuid: item.uuid,
                                                  })
                                                }
                                                class="profile-list-delete reject-btn"
                                                title="reject offer"
                                                data-toggle="modal"
                                                data-target="#rejectOffer"
                                              >
                                                <i class="fas fa-times"></i>
                                              </a>
                                            </>
                                          )}
                                          <div class="profile-list-info hidden-xs">
                                            added: {item.createdAt.split("T")[0]}
                                          </div>
                                          <div class="profile-list-info hidden-xs">
                                            City: {item.property.cityName} Country:{item.property.countryName}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="clearfix"></div>
                                    </div>
                                    <div
                                      className={
                                        this.state.collapse &&
                                          this.state.collapseId === item.uuid
                                          ? "expand position"
                                          : "chat-messages position"
                                      }
                                    >
                                      <div className="chat-message-header">
                                        Messages
                                      </div>
                                      <div
                                        id="chatMessageView"
                                        className={
                                          this.state.collapse &&
                                            this.state.collapseId === item.uuid
                                            ? "chat-messages-view"
                                            : "chat-messages-view-collapse"
                                        }
                                      >
                                        {this.state.getOfferChatMessages ===
                                          null ||
                                          this.state.getOfferChatMessages.map(
                                            (item) =>
                                              item.from.email ==
                                                localStorage.getItem("email") ? (
                                                <div className="message-wrapper">
                                                  <div className="message-right">
                                                    <div className="name">
                                                      {item.from.name}
                                                    </div>
                                                    {item.message}
                                                  </div>{" "}
                                                </div>
                                              ) : (
                                                <div className="message-wrapper">
                                                  {" "}
                                                  <div className="offer-chat-messages">
                                                    <div className="name">
                                                      {item.from.name}
                                                    </div>
                                                    {item.message}
                                                  </div>
                                                </div>
                                              )
                                          )}
                                      </div>
                                      <div className="chat-message-send">
                                        <textarea
                                          id="messageBody"
                                          className="chat-message"
                                          name="messageBody"
                                          onChange={this.onHandleChange}
                                          value={this.state.messageBody}
                                          row="1"
                                        />
                                        <button
                                          className="btn btn-primary send-button"
                                          onClick={() =>
                                            this.sendMessage(item.uuid)
                                          }
                                        >
                                          Send
                                        </button>{" "}
                                      </div>{" "}
                                    </div>
                                    <div class="clearfix"></div>
                                  </div>
                                </div>
                              ))}{" "}
                          </div>
                        </div>

                        <div
                          role="tabpanel"
                          className="tab-pane"
                          id="tab-recieved"
                        >
                          <div style={{ height: "auto", width: "100%" }}>
                            {this.state.recievedList.length > 0 ? null : (
                              <div>There is no data</div>
                            )}
                            {this.state.recievedList == null ||
                              this.state.recievedList.map((item) => (
                                <div class="row list-offer-row">
                                  <div class="col-xs-12">
                                    <div class="list-offer">
                                      <div class="offer-list">
                                        <div class="list-offer-text">
                                          <div class="list-offer-h4">
                                            {item.offerChatStatus.find(
                                              (item) =>
                                                localStorage.getItem("email") ==
                                                item.account.email
                                            ) && item.offerChatStatus.filter(
                                              (item) =>
                                                localStorage.getItem("email") ===
                                                item.account.email
                                            )[0].unseenMessages > 0 ? (
                                              <div className="badge">
                                                Not Seen
                                              </div>
                                            ) : null}
                                            <a>
                                            <h1 class="list-offer-title">{item.property.propertyTitle}</h1>
                                              <h4 class="list-offer-title">
                                                {item.offerBy.name}
                                              </h4>
                                            </a>
                                          </div>
                                          <div class="clearfix">
                                          <img width="20%" style={{float:"right",marginBottom:"30px"}} src={baseURL+item.property.propertyMedia[0]?.mediaUrl}/>
                                          </div>
                                          <div class="clearfix"></div>
                                          <p class="description-p">
                                            {item.message}
                                          </p>

                                          <div class="clearfix"></div>
                                        </div>
                                        <div class="profile-list-footer">
                                          <div class="list-price profile-list-price">
                                            <span className="offer-price">
                                              Offer Price
                                            </span>{" "}
                                            ${item.offerPrice}
                                          </div>
                                          {(item.isAccepted || item.isDeclined) &&
                                            item.offerChatStatus.find(
                                              (item) =>
                                                localStorage.getItem("email") ==
                                                item.account.email
                                            ) && item.offerChatStatus.filter(
                                              (item) =>
                                                localStorage.getItem("email") ===
                                                item.account.email
                                            )[0].unseenMessages > 0 ? (
                                            <a
                                              onClick={() =>
                                                this.marKAsSeen(item.uuid)
                                              }
                                              class="chat-btn profile-list-delete"
                                              title="Mark Seen"
                                              data-toggle="modal"
                                              data-target="#message"
                                            >
                                              <i class="fas fa-eye"></i>
                                            </a>
                                          ) : null}
                                          {item.isAccepted ||
                                            item.isDeclined ? null : (
                                            <>
                                              <a
                                                onClick={() =>
                                                  this.handleClick(item.uuid)
                                                }
                                                class="chat-btn profile-list-delete"
                                                title="Chat"
                                              >
                                                {" "}
                                                <i class="fas fa-comments"></i>
                                              </a>
                                              <a
                                                onClick={() =>
                                                  this.setState({
                                                    OfferUuid: item.uuid,
                                                  })
                                                }
                                                class="profile-list-delete reject-btn"
                                                title="reject offer"
                                                data-toggle="modal"
                                                data-target="#rejectOffer"
                                              >
                                                <i class="fas fa-times"></i>
                                              </a>
                                              <a
                                                onClick={() =>
                                                  this.setState({
                                                    OfferUuid: item.uuid,
                                                  })
                                                }
                                                class="accept-btn profile-list-delete"
                                                title="accept offer"
                                                data-toggle="modal"
                                                data-target="#acceptOffer"
                                              >
                                                <i class="fas fa-check"></i>
                                              </a>
                                            </>
                                          )}
                                          <div class="profile-list-info hidden-xs">
                                            added: {item.createdAt.split("T")[0]}
                                          </div>
                                          <div class="profile-list-info hidden-xs">
                                            City: {item.property.cityName} Country:{item.property.countryName}
                                          </div>
                                        </div>
                                      </div>
                                      <div class="clearfix"></div>
                                    </div>
                                    <div
                                      className={
                                        this.state.collapse &&
                                          this.state.collapseId === item.uuid
                                          ? "expand position"
                                          : "chat-messages position"
                                      }
                                    >
                                      <div className="chat-message-header">
                                        Messages
                                      </div>
                                      <div
                                        id="chatMessageView"
                                        className={
                                          this.state.collapse &&
                                            this.state.collapseId === item.uuid
                                            ? "chat-messages-view"
                                            : "chat-messages-view-collapse"
                                        }
                                      >
                                        {this.state.getOfferChatMessages ==
                                          null ||
                                          this.state.getOfferChatMessages.map(
                                            (item) =>
                                              item.from.email ==
                                                localStorage.getItem("email") ? (
                                                <div className="message-wrapper">
                                                  <div className="message-right">
                                                    <div className="name">
                                                      {item.from.name}
                                                    </div>
                                                    {item.message}
                                                  </div>{" "}
                                                </div>
                                              ) : (
                                                <div className="message-wrapper">
                                                  {" "}
                                                  <div className="offer-chat-messages">
                                                    <div className="name">
                                                      {item.from.name}
                                                    </div>
                                                    {item.message}
                                                  </div>
                                                </div>
                                              )
                                          )}
                                      </div>
                                      <div className="chat-message-send">
                                        <textarea
                                          id="messageBody"
                                          className="chat-message"
                                          name="messageBody"
                                          onChange={this.onHandleChange}
                                          value={this.state.messageBody}
                                          row="2"
                                        />
                                        <button
                                          className="btn btn-primary send-button"
                                          onClick={() =>
                                            this.sendMessage(item.uuid)
                                          }
                                        >
                                          Send
                                        </button>{" "}
                                      </div>{" "}
                                    </div>
                                    <div class="clearfix"></div>
                                  </div>
                                </div>
                              ))}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          <div
            class="modal fade list-detail-modal"
            id="acceptOffer"
            tabindex="-1"
            role="dialog"
            aria-labelledby="basicModal"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    &times;
                  </button>
                  <h4 class="modal-title" id="myModalLabel">
                    Accept Offer
                  </h4>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12">
                      <h5>Are you sure you want to accept the offer?</h5>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn cancel-offer"
                    data-dismiss="modal"
                  >
                    NO
                  </button>
                  <button
                    type="button"
                    class="btn  make-offer"
                    onClick={() => this.acceptOffer()}
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#message"
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade list-detail-modal"
            id="rejectOffer"
            tabindex="-1"
            role="dialog"
            aria-labelledby="basicModal"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    &times;
                  </button>
                  <h4 class="modal-title" id="myModalLabel">
                    Reject Offer
                  </h4>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12">
                      <h5>Are you sure you want to reject the offer?</h5>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn cancel-offer"
                    data-dismiss="modal"
                  >
                    NO
                  </button>
                  <button
                    type="button"
                    class="btn  make-offer"
                    onClick={() => this.rejectOffer()}
                    data-dismiss="modal"
                    data-toggle="modal"
                    data-target="#message"
                  >
                    YES
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div
            class="modal fade list-detail-modal"
            id="message"
            tabindex="-1"
            role="dialog"
            aria-labelledby="basicModal"
            aria-hidden="true"
          >
            <div class="modal-dialog modal-lg">
              <div class="modal-content">
                <div class="modal-header">
                  <button
                    type="button"
                    class="close"
                    data-dismiss="modal"
                    aria-hidden="true"
                  >
                    &times;
                  </button>
                  <h4>Alert !</h4>
                </div>
                <div class="modal-body">
                  <div className="row">
                    <div className="col-xs-12 col-sm-12">
                      <h5>{this.state.errorMessage}</h5>
                    </div>
                  </div>
                </div>
                <div class="modal-footer">
                  <button
                    type="button"
                    class="btn cancel-offer"
                    data-dismiss="modal"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>
          </div>
        </main>
      </>
    );
  }
}

export default Offers;
