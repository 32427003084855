import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ListingCard from "../../common/ListingCard/ListingCard";
import ListingImage from "./../../../assets/images/grid-offer2.jpg";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import { withRouter } from "react-router-dom";
import { getToken } from "../../common/commonFunctions/commonFunctions";
import DefaultImage from "../../../assets/images/grid-offer1.jpg";
import GoogleMapReact from "google-map-react";
import {
  disableScroll,
  enableScroll,
} from "../../common/commonFunctions/commonFunctions";
import { toast } from "react-toastify";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const PropertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial Plot" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
];
class FavouriteListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Listings: [],
      myFavouritProperty: [],
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      isLoader: false,
    };
  }
  componentDidMount() {
    let array = [];
    let arraySliced = [];
    this.setState({ isLoader: true });
    disableScroll();
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/GetMyFavouriteProperty",

      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        this.setState({ isLoader: false });
        enableScroll();
        array = response.data.data;

        this.setState({ Listings: array }, () => { });
      })
      .catch((error) => {
        this.setState({ isLoader: false });
        enableScroll();
      });
  }

  handleButton = (e) => {
    this.props.history.push({
      pathname: "/listingdetail",
      search: "selectedList=" + e,
    });
    window.location.reload();
  };

  gotoNext = () => {
    this.slider.slickNext();
  };
  gotoPrev = () => {
    this.slider.slickPrev();
  };

  favouritProperty = (id) => {
    toast.success("Added in favourite");
    axios({
      method: "post",
      url: baseURL + "api/v1/Property/AddFavouriteProperty",
      data: {
        propertyId: id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("my favourite property ", response.data.data);
            this.setState({ Listings: response.data.data });
            this.setState({ myFavouritProperty: response.data.data });
          })
          .catch((error) => {
            console.log("errrrrrrrrrrr", error);
          });
        console.log("remove");
        this.setState({ favouritColor: "red" });
      })
      .catch((error) => {
        console.log("errrrrrrr", error);
      });
  };
  removeFavouritProperty = (id) => {
    toast.error("Removed from favourite");
    axios({
      method: "put",
      url: baseURL + "api/v1/Property/RemoveFavouriteProperty",
      data: {
        propertyId: id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("my favourite property ", response.data.data);
            this.setState({ Listings: response.data.data });
            this.setState({ myFavouritProperty: response.data.data });
          })
          .catch((error) => {
            console.log("errrrrrrrrrrr", error);
          });

        console.log("remove");
        this.setState({ favouritColor: "red" });
      })
      .catch((error) => {
        console.log("errrrrrrr", error);
      });
  };

  render() {
    if (this.state.Listings.length === 1) {
      var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 2024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    } else if (this.state.Listings.length === 2) {
      var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 2024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    } else if (this.state.Listings.length === 3) {
      var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 2024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    } else if (this.state.Listings.length >= 4) {
      var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 2024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    }
    return (
      <>
        <section
          className="section-light
         villa-section  carosuile-section"
          style={{ minHeight: "82vh" }}
        >
          <div className={this.state.isLoader ? "loader-bg" : ""}></div>
          <div className="text-center" style={{ marginTop: "100px" }}>
            <h1>My Favourite Properties</h1>
          </div>
          <div className="grid-offers-container slider-cntnr">
            <a
              onClick={() => this.gotoPrev()}
              className="navigation-box navigation-box-prev"
              id="grid-offers-owl-prev"
            >
              <div className="navigation-triangle"></div>
              <div className="navigation-box-icon">
                <i className="fas fa-angle-left"></i>
              </div>
            </a>
            <a
              className="navigation-box navigation-box-next"
              id="grid-offers-owl-next"
              onClick={() => this.gotoNext()}
            >
              <div className="navigation-triangle"></div>
              <div className="navigation-box-icon">
                <i className="fas fa-angle-right"></i>
              </div>
            </a>

            <Slider
              ref={(c) => (this.slider = c)}
              style={{ width: "95%", margin: "auto" }}
              {...settings}
            >
              {this.state.Listings == null ||
                this.state.Listings.map((list) => (
                  <ListingCard
                    ImageURL={
                      list.propertyMedia === null ? (
                        <img height="175px" src={DefaultImage} />
                      ) : list.propertyMedia.filter(
                        (item) => item.isThumbnail === true
                      ).length <= 0 ? (
                        <img
                          onClick={() => this.handleButton(list.uuid)}
                          height="175px"
                          src={
                            baseURL +
                            list.propertyMedia.filter(
                              (item) => item.isThumbnail === false
                            )[0]?.mediaUrl
                          }
                        />
                      ) : (
                        list.propertyMedia
                          .filter((item) => item.isThumbnail === true)
                          .map((data) => (
                            <img height="175px" src={baseURL + data.mediaUrl} />
                          ))
                      )
                    }
                    PropertyType={PropertyType.filter(
                      (item) => item.id === list.propertyTypeId
                    ).map((id) => id.PropertyTypeName)}
                    TransactionType={list.transactionTypes}
                    LocalAddress={list.locationAddress}
                    Description={list.discription}
                    Price={list.price}
                    PropertyArea={list.propertyArea}
                    Bedrooms={list.bedrooms}
                    Bath={list.fullBaths}
                    uuid={list.uuid}
                    id={list.id}
                    handleButton={() => { }}
                    longitude={list.longitude}
                    latitude={list.latitude}
                    ListingData={this.state.Listings}
                    myFavouritProperty={this.state.myFavouritProperty}
                    favouritProperty={this.favouritProperty}
                    removeFavouritProperty={this.removeFavouritProperty}
                  />
                ))}
            </Slider>

          </div>
          {/* <div className="row">
            <div className="container">
                  {
this.state.Listings.map((item)=>{
return(<>
  <div className="col-lg-3 col-md-4 col-sm-6 col-sx-12">

<div class="grid-offer-col listing">
  <div class="grid-offer">
    <div class="grid-offer-front">
      <div class="grid-offer-photo sm">
        <img height="175px"
          src="http://198.187.28.244:2929/Images\Product\Property_637792312138713955.jpg" />
        <div class="type-container">
          <div class="estate-type reg-btn black">
          
          </div>
          <div class="transaction-type reg-btn yellow"></div>
        </div>
      </div>
      <div class="grid-offer-text">
        <div class="wrap-address">
          <div class="grid-offer-price">$ 300
            <i class="fa fa-heart"><span>1</span></i>
          </div>
          <div class="grid-offer-h4">
            <p>2-Beds 2-Baths 10-Sq.Ft</p>
            <h4 class="grid-offer-title">active my house</h4>
          </div>
        </div>
        <div class="clearfix"></div>
        <p class="description-p"><i class="fa fa-map-marker"></i>
          high-q-tower jail road lahore</p>
        <div class="clearfix"></div>
      </div>
    </div>
  </div>
</div>









</div>
</>)
})

                  }

            
            </div>
          </div> */}
        </section>
      </>
    );
  }
}

export default withRouter(FavouriteListing);
