import React, { Component } from "react";
import queryString from "query-string";
import axios from "axios";
import { baseURL, baseURLImages, GoogleMapKey } from "../../../ApiURL/ApiURL";
// import NewListing from "../NewListing/NewListing";
import DetailNewListing from "../DetailNewListing/DetailNewListing";

import GoogleMapReact from "google-map-react";
import { withRouter } from "react-router-dom";
import ImageDefault from "../../../assets/images/bg-pattern12.jpg";
import homeicon from "../../../assets/images/home-icon.png";
import userimg from "../../../assets/images/user-img.png";
import mobileicon from "../../../assets/images/mobile-icon.png";
import charticon from "../../../assets/images/chart-icon.png";
import mapimage from "../../../assets/images/mapimage.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import DataTable from "react-data-table-component";
import ListBanner from "../../common/ListBanner/ListBanner";
import Button from "../../common/Button/Button";
import "./ListDetail.css";
import avatar from "./../../../assets/images/avatar.jpg";
import {
  disableScroll,
  enableScroll,
  getToken,
  closeModal,
  isMyProperty,
} from "../../common/commonFunctions/commonFunctions";
import { List } from "@material-ui/core";
import Modal from "react-responsive-modal";
import PropertyGoogleMap from "./PropertyGoogleMap";

const AnyReactComponent = ({ text }) => <div className="pin1"></div>;
const PropertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial Plot" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
];

// const data = [
//   {
//     id: 1,
//     transactionHash: "01234X456465465132156465135486541321564156431",
//     from: "Xyz",
//     to: "Xyz",
//     date: "21/03/2021",
//     contractAddress: "2131@1254131#15351313",

//     moreDetails: (
//       <a href={"https://ropsten.etherscan.io/tx/"} target="_blank">
//         View in Ethurscan
//       </a>
//     ),
//   },
// ];

const columns = [
  {
    name: "Transaction Hash",
    selector: "nftMintingBlockchainTransactionHash",
  },
  {
    name: "NFT smart Address",
    selector: "nftSmartContractAddress",
  },
  {
    name: "NFT TokkenID",
    selector: "nftTokenId",
  },
  {
    name: "Owner Address",
    selector: "nftMintingOwnerAddress",
  },
  {
    name: "Owned Date",
    selector: "nftMintingBlockchainTransactionDate",
  },

  {
    name: "More Details",
    selector: "moreDetails",
  },
];

class ListDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDetail: "",
      PropertyType: "",
      isLoader: "",
      dataList: [],
      imagesMedia: [],
      mypropertyDetails: false,
      offerPrice: 0,
      message: "",
      propertyUuid: "",
      errorMessage: "",
      isMyProperty: false,
      documents: [],
      inspection: [],
      existingBills: [],
      List: [],
      openModel: false,
      openOfferModel: false,
      userId: "",
      center: {
        lat: 59.95,
        lng: 30.33,
      },
    };
  }
  static defaultProps = {
    zoom: 5,
  };

  componentDidMount() {
    disableScroll();

    this.setState({ isLoader: true });
    var url = this.props.location.search;
    var params = queryString.parse(url);
    console.log(params);
    let array = [];
    let center = "";
    let imagesMedia = [];
    let dataList = [];
    this.setState({ propertyUuid: params.selectedList });
    this.setState({ mypropertyDetails: params.myproperty == "true" });

    axios({
      method: "get",
      url: baseURL + "api/v1/Property/get_listings",
    })
      .then((response) => {
        this.setState({ userId: params.selectedList })
        this.setState({ isLoader: false });
        enableScroll();
        array = response.data.data.filter(
          (item) => item.uuid == params.selectedList,
        );
        center = { lat: array[0].latitude, lng: array[0].longitude };
        imagesMedia = array[0].propertyMedia;
        
        this.setState({ List: array })
        console.log("data is ", this.state.List)
        console.log("array", array);
        localStorage.setItem("mapId", params.selectedList)
        localStorage.setItem("mapLat", array[0].latitude)
        localStorage.setItem("mapLng", array[0].longitude)

        // console.log("arrray data is here",array[0].latitude)


        dataList = [
          {
            nftMintingBlockchainTransactionHash:
              array[0].nftMintingBlockchainTransactionHash,
            nftSmartContractAddress: array[0].nftSmartContractAddress,
            nftTokenId: array[0].nftTokenId,
            nftMintingOwnerAddress: array[0].nftMintingOwnerAddress,
            nftMintingBlockchainTransactionDate:
              array[0].nftMintingBlockchainTransactionDate,
            moreDetails: (
              <a
                href={
                  "https://ropsten.etherscan.io/tx/" +
                  array[0].nftMintingBlockchainTransactionHash
                }
                target="_blank"
              >
                View in Ethurscan
              </a>
            ),
          },
        ];
        this.setState({
          isMyProperty: isMyProperty(array[0].accountEmail),
          documents: array[0].propertyProofDocs,
          inspection: array[0].propertyInspectionReport,
          existingBills: array[0].propertyExistingBill,
          listDetail: array[0],
          PropertyType: PropertyType.filter(
            (item) => item.id === array[0].propertyTypeId
          ).map((id) => id.PropertyTypeName),
          dataDetail: array,
          imagesMedia: imagesMedia,
          dataList: dataList,
          offerPrice: array[0].price,

          center: center,
        });
      })
      .catch((error) => {
        this.setState({ isLoader: false });
        enableScroll();
      });
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    const {
      location: { search: prevSearch },
    } = prevProps;

    if (search !== prevSearch) {
      this.setState({ isLoader: true });
      disableScroll();
      var params = queryString.parse(search);
      let array = [];
      let center = "";
      let imagesMedia = [];
      let dataList = [];
      axios({
        method: "get",
        url: baseURL + "api/v1/Property/get_listings",
      })
        .then((response) => {
          this.setState({ isLoader: false });
          enableScroll();
          array = response.data.data.filter(
            (item) => item.uuid == params.selectedList
          );
          console.log("array is here.........................", array);
          center = { lat: array[0].latitude, lng: array[0].longitude };
          imagesMedia = array[0].propertyMedia;



          dataList = [
            {
              nftMintingBlockchainTransactionHash:
                array[0].nftMintingBlockchainTransactionHash,
              nftSmartContractAddress: array[0].nftSmartContractAddress,
              nftTokenId: array[0].nftTokenId,
              nftMintingOwnerAddress: array[0].nftMintingOwnerAddress,
              nftMintingBlockchainTransactionDate:
                array[0].nftMintingBlockchainTransactionDate,
              moreDetails: (
                <a
                  href={
                    "https://ropsten.etherscan.io/tx/" +
                    array[0].nftMintingBlockchainTransactionHash
                  }
                  target="_blank"
                >
                  View in Ethurscan
                </a>
              ),
            },
          ];
          this.setState({
            documents: array[0].propertyProofDocs,
            listDetail: array[0],
            offerPrice: array[0].price,
            PropertyType: PropertyType.filter(
              (item) => item.id === array[0].propertyTypeId
            ).map((id) => id.PropertyTypeName),

            imagesMedia: imagesMedia,
            dataList: dataList,
            center: center,
          });
        })
        .catch((error) => {
          this.setState({ isLoader: false });
          enableScroll();
        });
    }
  }
  handleMakeOffer = () => {
    this.setState({
      isLoader: true,
    });
    disableScroll();
    axios({
      method: "post",
      url: baseURL + "api/Offer/MakeOffer",
      data: {
        offerPrice: this.state.offerPrice,
        message: this.state.message,
        propertyUuid: this.state.propertyUuid,
      },
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        this.setState({
          isLoader: false,
          errorMessage: response.data.message,
        });
        enableScroll();
      })

      .catch((error) => {
        this.setState({
          isLoader: false,
          errorMessage: "Something went Wrong",
        });
        enableScroll();
      });
  };
  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleButton = (e) => {
    this.props.history.push({
      pathname: "/generatepdf",
      search: "selectedpdf=" + this.state.propertyUuid,
    });
  };

  mapModel = () => {
    this.setState({ openModel: true })
  }
  OfferModel = () => {
    this.setState({ openOfferModel: true })
  }
  closeModal = () => {
    this.setState({ openModel: false })
  }
  closeOfferModal = () => {
    this.setState({ openOfferModel: false })
  }

  render() {
    return (
      <>


        <Modal open={this.state.openModel} onClose={this.closeModal} center>

          <div className="accept-modal">
            {" "}
            <div class="modal-body">
              {" "}

              {
                this.state.List.map((item)=>{
                   return(<>
                    <PropertyGoogleMap
              		google={this.props.google}
                  center={{lat: item.latitude, lng: item.longitude}}
                  height='300px'
                  zoom={15}
              />
                   </>)
                })
              }
             
            </div>
            <div className="Modal-footer">

            </div>
          </div>


        </Modal>

        <Modal open={this.state.openOfferModel} onClose={this.closeOfferModal} center>

          <div className="accept-modal">
            {" "}
            <h4 class="modal-title">
                  Make Offer
                </h4>

            <div class="modal-body">
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  {" "}
                  <input
                    type="text"
                    className="form-control input-full main-input"
                    id="offerPrice"
                    name="offerPrice"
                    placeholder={this.state.offerPrice}
                    value={this.state.offerPrice}
                    required
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-xs-12 col-sm-12">
                  {" "}
                  <textarea
                    name="message"
                    class="form-control"
                    rows="3"
                    placeholder="Enter Message"
                    onChange={this.onHandleChange}
                  />
                </div>
              </div>
            </div>


            <div class="modal-footer">
                <button
                  type="button"
                  class="btn cancel-offer"
                  data-dismiss="modal"
                  onClick={this.closeOfferModal}
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn  make-offer"
                  onClick={() => this.handleMakeOffer()}
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#message"
                >
                  Make Offer
                </button>
              </div>
            


          </div>


        </Modal>




        <div className={this.state.isLoader ? "loader-bg" : ""}></div>
        {/* <ListBanner imagesMedia={this.state.imagesMedia} /> */}

        <section className="section-light no-bottom-padding list-details-section">
          <div className="container">
            <div className="row">
              <div className="col-xs-12 col-lg-12 col-sm-12 col-md-12">
                <h1 className="hedng"><span>Properties</span> Overview</h1>
              </div>
            </div>
            <>    {
              this.state.List.map((list) => {
                if (list.propertyMedia.length == 1) {
                  return (
                    <>
                      <>
                        <div className="row">
                          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            <div className="big-image">
                              <img src={`${baseURL}${list.propertyMedia[0].mediaUrl}`} alt="big image" />
                              {/* <a className="reg-btn black big" href="#">Listed By</a> */}
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <div className="small-image">
                              <img src={`${baseURL}${list.propertyMedia[0].mediaUrl}`} alt="big image" />
                            </div>
                            <div className="small-image">
                              <img src={`${baseURL}${list.propertyMedia[0].mediaUrl}`} alt="big image" />

                              <Link to={{ pathname: '/listingdetailImage', state: { name: this.state.userId } }}>See All Images</Link>
                              {/* <a className="reg-btn black" href="/listingdetailImage">See All Images</a> */}
                            </div>
                          </div>
                        </div>
                      </>
                    </>)
                }

                else if (list.propertyMedia.length == 2) {
                  return (
                    <>
                      <>
                        <div className="row">
                          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            <div className="big-image">
                              <img src={`${baseURL}${list.propertyMedia[0].mediaUrl}`} alt="big image" />
                              {/* <a className="reg-btn black big" href="#">Listed By</a> */}
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <div className="small-image">
                              <img src={`${baseURL}${list.propertyMedia[1].mediaUrl}`} alt="big image" />
                            </div>
                            <div className="small-image">
                              <img src={`${baseURL}${list.propertyMedia[1].mediaUrl}`} alt="big image" />

                              <Link to={{ pathname: '/listingdetailImage', state: { name: this.state.userId } }}>See All Images</Link>
                              {/* <a className="reg-btn black" href="/listingdetailImage">See All Images</a> */}
                            </div>
                          </div>
                        </div>
                      </>
                    </>)
                }
                else if (list.propertyMedia.length >= 3) {
                  return (
                    <>
                      <>
                        <div className="row">
                          <div className="col-xs-12 col-sm-8 col-md-8 col-lg-8">
                            <div className="big-image">
                              <img src={`${baseURL}${list.propertyMedia[0].mediaUrl}`} alt="big image" />
                              {/* <a className="reg-btn black big" href="#">Listed By</a> */}
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <div className="small-image">
                              <img src={`${baseURL}${list.propertyMedia[1].mediaUrl}`} alt="big image" />
                            </div>
                            <div className="small-image">
                              <img src={`${baseURL}${list.propertyMedia[2].mediaUrl}`} alt="big image" />

                              <Link to={{ pathname: '/listingdetailImage', state: { name: this.state.userId } }}>See All Images</Link>
                              {/* <a className="reg-btn black" href="/listingdetailImage">See All Images</a> */}
                            </div>
                          </div>
                        </div>
                      </>
                    </>)
                }










              })}</>
            <>    {
              this.state.List.map((list) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 left-side">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="full-div">
                              <h5>City: {list.cityName},Country: {list.countryName}</h5>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-4 col-md-4 col-lg-4">
                            <div className="home-detail-info full-width">
                              <h4>${list.price}</h4>
                              <p> By with<a className="active" href="#"> Dwelling Goldmine</a></p>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <div className="home-detail-info-details full-width">
                              <ul>
                                <li>
                                  <span>{list.bedrooms}</span>
                                  Beds
                                </li>
                                <li>
                                  <span>{list.fullBaths}</span>
                                  Baths
                                </li>
                                <li>
                                  <span>{list.propertyArea}</span>
                                  Sq.Ft.
                                </li>
                              </ul>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-2 col-md-2 col-lg-2 text-right">
                            <img src={mapimage} alt="map image" onClick={this.mapModel} style={{ cursor: "pointer" }} />
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="full-width brdr"></div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="view-graph-pnl full-width">
                              <div className="lft-pnl">
                                <h3>{list.propertyTitle}</h3>

                              </div>
                              <div className="rgt-pnl">
                                <img src={charticon} alt="graph image" />
                              </div>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="full-width brdr"></div>
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <p>
                              {list.discription}
                            </p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <div className="row">
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="full-width brdr"></div>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12 listing-list-pnl">
                                <div className="listing-img-pnl" >
                                  {list.userImage ?
                                    <img src={baseURL + list.userImage} /> : <img src={avatar} />
                                  }
                                </div>
                                <ul className="listing-list">
                                  <li>
                                    Listing by <a href="#">{list.userName}</a>
                                  </li>
                                  <li>|</li>
                                  <li>Licensed sales person</li>
                                </ul>
                              </div>
                              <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <div className="full-width brdr"></div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <h2>{list.propertyTitle}</h2>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <ul className="home-facts-list">
                              <li>
                                <span>Status</span>
                                <span className="active">{list.propertyStatus}</span>
                              </li>
                              <li>
                                <span>Property Type</span>
                                <span>{list.propertyTypeId}</span>
                              </li>
                              <li>
                                <span>Style</span>
                                <span>{list.propertyStyle}</span>
                              </li>

                            </ul>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <ul className="home-facts-list">
                              <li>
                                <span>Time on Dwelling Goldmine</span>
                                <span>{list.createdAt.split("T")[0]}</span>
                              </li>
                              <li>
                                <span>Year Built</span>
                                <span>{list.yearBuilt.split("-")[0]}</span>
                              </li>
                              <li>
                                <span>Square Footage</span>
                                <span>{list.propertyArea}</span>
                              </li>
                              {/* <li>
                                <span>Community</span>
                                <span>{list.community}</span>
                              </li>
                              <li>
                                <span>MLS#</span>
                                <span>{list.mls}</span>
                              </li> */}
                            </ul>
                          </div>
                        </div>
                        <div className="full-div height"></div>
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <h2>Price Insights</h2>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <ul className="home-facts-list">
                              <li>
                                <span>List Price</span>
                                <span>${list.price}</span>
                              </li>
                              <li>
                                <span>Price/Square Feet</span>
                                <span>${list.pricePerSquareFeet}</span>
                              </li>
                            </ul>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-6 col-lg-6">
                            <ul className="home-facts-list">
                              {/* <li>
                                <span>Est. Mo. Payment</span>
                                <span>${list.estMoPayment}</span>
                              </li> */}
                              <li>
                                <span>Buyer’s Brockage Commission</span>
                                <span>{list.buyerBrokerCommision} %</span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 side-pnl">
                        <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <button
                              className="button-primary  button-shadow button-full"
                              onClick={this.OfferModel}
                            >
                              <span>Create an offer</span>
                              <div className="button-triangle"></div>
                              <div className="button-triangle2"></div>
                              <div className="button-icon">
                                <i className="fa fa-lg fa-home"></i>
                              </div>
                            </button>
                            <Link to="/generatepdf">
                            <button
                              className="button-primary  button-shadow button-full"

                            >
                              <span >view Agreement</span>
                              
                              <div className="button-triangle"></div>
                              <div className="button-triangle2"></div>
                              <div className="button-icon">
                                <i className="fa fa-lg fa-home"></i>
                              </div>
                            </button>
                            </Link>
                          </div>
                        </div>
                        {/* <div className="row">
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <h2>Go tour this Home</h2>
                          </div>
                          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4 ">
                            <div className="date-pnl full-width">
                              <span>Wednesday</span>
                              <span className="numric">5</span>
                              <span>Jan</span>
                            </div>
                          </div>
                          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                            <div className="date-pnl full-width">
                              <span>Thursday</span>
                              <span className="numric">6</span>
                              <span>Jan</span>
                            </div>
                          </div>
                          <div className="col-xs-4 col-sm-4 col-md-4 col-lg-4">
                            <div className="date-pnl full-width">
                              <span>Friday</span>
                              <span className="numric">7</span>
                              <span>Jan</span>
                            </div>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6">
                            <a className="full-width" href="#"><img src={homeicon} alt="big image" /> Tour in Person</a>
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-12 col-lg-6">
                            <a className="full-width" href="#"> <img src={mobileicon} alt="big image" /> Tour via Video Chat</a>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <a className="full-width" href="#">Shedule Tour</a>
                          </div>
                          <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <p>It’s free with no obligation -- cancel anytime</p>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </>
                )
              })
            }</>

            <div className="margin-top-45"></div>
          </div>

          {/* <div className="col-xs-12 col-sm-7 col-md-8 col-lg-9">
                    <div className="details-image details-image-icon pull-left hidden-xs">
                      <i className="fa fa-home"></i>
                    </div>
                    <div className="details-title pull-left">
                      <h5 class="subtitle-margin">
                        {this.state.PropertyType} for{" "}
                        {this.state.listDetail.transactionTypes}
                      </h5>
                      <h3>
                        {this.state.listDetail.locationAddress}
                        <span class="special-color">.</span>
                      </h3>
                    </div>
                    <div className="clearfix"></div>
                    <div className="title-separator-primary"></div>
                    <p className="details-desc">
                      {this.state.listDetail.discription}
                    </p>
                  </div> */}
          {/* <div className="col-xs-12 col-sm-5 col-md-4 col-lg-3">
                    <div className="details-parameters-price">
                      ${this.state.listDetail.price}
                    </div>
                    <div className="details-parameters">
                      <div className="details-parameters-cont">
                        <div className="details-parameters-name">area</div>
                        <div className="details-parameters-val">
                          {this.state.listDetail.propertyArea}ft<sup>2</sup>
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="details-parameters-cont">
                        <div className="details-parameters-name">bedrooms</div>
                        <div className="details-parameters-val">
                          {this.state.listDetail.bedrooms}
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="details-parameters-cont">
                        <div className="details-parameters-name">bathrooms</div>
                        <div className="details-parameters-val">
                          {this.state.listDetail.fullBaths}
                        </div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="details-parameters-cont">
                        <div className="details-parameters-name">
                          parking places
                        </div>
                        <div className="details-parameters-val">2</div>
                        <div className="clearfix"></div>
                      </div>
                      <div className="details-parameters-cont">
                        <div className="details-parameters-name">Kitchen</div>
                        <div className="details-parameters-val">
                          {this.state.listDetail.fullKitchen}
                        </div>
                        <div className="clearfix"></div>
                      </div>
                    </div>
                    {this.state.isMyProperty ? null : (
                      <>
                        <div className="center-button-cont margin-top-30">
                          <div
                            className="button-primary button-shadow button-full"
                            data-toggle="modal"
                            data-target="#createOffer"
                          >
                            <span>Create an offer</span>
                            <div className="button-triangle"></div>
                            <div className="button-triangle2"></div>
                            <div className="button-icon">
                              <i className="fa fa-th-list"></i>
                            </div>
                          </div>
                        </div>
                        <div className="center-button-cont margin-top-30">
                          <div
                            className="button-primary button-shadow button-full"
                            data-toggle="modal"
                            onClick={() => this.handleButton()}
                          >
                            <span>View Agreement</span>
                            <div className="button-triangle"></div>
                            <div className="button-triangle2"></div>
                            <div className="button-icon">
                              <i className="fa fa-th-list"></i>
                            </div>
                          </div>
                        </div>
                      </>
                    )}
                  </div> */}

          {/* <div className="row margin-top-45">
                  <div className="col-xs-12 col-sm-12">
                    <ul className="details-ticks">
                      {this.state.listDetail.isBuilt ? (
                        <li>
                          <i className="fas fa-check-square"></i>Built
                        </li>
                      ) : null}
                      {this.state.listDetail.hasImmediatePossession ? (
                        <li>
                          <i className="fas fa-check-square"></i>Immediate
                          Possession
                        </li>
                      ) : null}
                      {this.state.listDetail.hasBasement ? (
                        <li>
                          <i className="fas fa-check-square"></i>Basemenet{" "}
                        </li>
                      ) : null}
                      {this.state.listDetail.hasWaterFront ? (
                        <li>
                          <i className="fas fa-check-square"></i>Water Front{" "}
                        </li>
                      ) : null}
                      {this.state.listDetail.hasWaterFrontNear ? (
                        <li>
                          <i class="fas fa-check-square"></i>Water Front Near{" "}
                        </li>
                      ) : null}
                      {this.state.listDetail.hasWaterView ? (
                        <li>
                          <i className="fas fa-check-square"></i>Water View{" "}
                        </li>
                      ) : null}
                      {this.state.listDetail.hasSwimmingFacility ? (
                        <li>
                          <i className="fas fa-check-square"></i>Swimming
                          Facility{" "}
                        </li>
                      ) : null}
                    </ul>
                  </div>
                </div> */}

          {/* <div className="row margin-top-45">
                  <div className="col-xs-12 apartment-tabs">
                    <ul className="nav nav-tabs" role="tablist">
                      <li role="presentation" className="active">
                        <a
                          href="#tab-map"
                          aria-controls="tab-map"
                          role="tab"
                          data-toggle="tab"
                        >
                          <span>Map</span>
                          <div className="button-triangle2"></div>
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          href="#tab-street-view"
                          aria-controls="tab-street-view"
                          role="tab"
                          data-toggle="tab"
                        >
                          <span>Street view</span>
                          <div className="button-triangle2"></div>
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          href="#tab-transaction-view"
                          aria-controls="tab-transaction-view"
                          role="tab"
                          data-toggle="tab"
                        >
                          <span>TX Info</span>
                          <div className="button-triangle2"></div>
                        </a>
                      </li>
                      {this.state.isMyProperty ? (
                        <>
                          <li role="presentation">
                            <a
                              href="#tab-documents-view"
                              aria-controls="tab-documents-view"
                              role="tab"
                              data-toggle="tab"
                            >
                              <span>Documents</span>
                              <div className="button-triangle2"></div>
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#tab-inspection-report"
                              aria-controls="tab-inspection-report"
                              role="tab"
                              data-toggle="tab"
                            >
                              <span>Inspection Report</span>
                              <div className="button-triangle2"></div>
                            </a>
                          </li>
                          <li role="presentation">
                            <a
                              href="#tab-existing-bills"
                              aria-controls="tab-existing-bills"
                              role="tab"
                              data-toggle="tab"
                            >
                              <span>Existing Bills</span>
                              <div className="button-triangle2"></div>
                            </a>
                          </li>
                        </>
                      ) : null}
                    </ul>

                    <div className="tab-content">
                      <div
                        role="tabpanel"
                        className="tab-pane active"
                        id="tab-map"
                      >
                        <div style={{ height: "100vh", width: "100%" }}>
                          {this.state.listDetail === undefined ||
                          this.state.listDetail === "" ? null : (
                            <GoogleMapReact
                              bootstrapURLKeys={{
                                key: GoogleMapKey,
                              }}
                              defaultCenter={{
                                lat: this.state.listDetail.latitude,
                                lng: this.state.listDetail.longitude,
                              }}
                              defaultZoom={20}
                            >
                              <AnyReactComponent
                                lat={this.state.listDetail.latitude}
                                lng={this.state.listDetail.longitude}
                                text="My Marker"
                              />
                            </GoogleMapReact>
                          )}
                        </div>
                      </div>

                      <div
                        role="tabpanel"
                        className="tab-pane"
                        id="tab-street-view"
                      >
                        <div
                          id="estate-street-view"
                          className="details-map"
                        ></div>
                      </div>

                      <div
                        role="tabpanel"
                        className="tab-pane"
                        id="tab-transaction-view"
                      >
                        <div style={{ height: "100vh", width: "100%" }}>
                          <DataTable
                            title="Transaction Info"
                            columns={columns}
                            data={this.state.dataList}
                            pagination
                            paginationPerPage={10}
                          />
                        </div>
                      </div>
                      {this.state.isMyProperty ? (
                        <>
                          <div
                            role="tabpanel"
                            className="tab-pane"
                            id="tab-documents-view"
                          >
                            <div style={{ height: "100vh", width: "100%" }}>
                              <div className="col-md-12 ">
                                {this.state.documents == null
                                  ? null
                                  : this.state.documents.map((item) => (
                                      <div className="col-md-3">
                                        {" "}
                                        <a
                                          href={baseURLImages + item.mediaUrl}
                                          target="_blank"
                                          className="linkwrap"
                                        >
                                          {" "}
                                          <div class="blocker"></div>
                                          <iframe
                                            src={baseURLImages + item.mediaUrl}
                                            width="100%"
                                            height="100%"
                                            frameborder="1"
                                          ></iframe>
                                        </a>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>
                          <div
                            role="tabpanel"
                            className="tab-pane"
                            id="tab-inspection-report"
                          >
                            <div style={{ height: "100vh", width: "100%" }}>
                              <div className="col-md-12 ">
                                {this.state.inspection == null
                                  ? null
                                  : this.state.inspection.map((item) => (
                                      <div className="col-md-3">
                                        {" "}
                                        <a
                                          href={baseURLImages + item.mediaUrl}
                                          target="_blank"
                                          className="linkwrap"
                                        >
                                          {" "}
                                          <div class="blocker"></div>
                                          <iframe
                                            src={baseURLImages + item.mediaUrl}
                                            width="100%"
                                            height="100%"
                                            frameborder="1"
                                          ></iframe>
                                        </a>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>
                          <div
                            role="tabpanel"
                            className="tab-pane"
                            id="tab-existing-bills"
                          >
                            <div style={{ height: "100vh", width: "100%" }}>
                              <div className="col-md-12 ">
                                {this.state.existingBills == null
                                  ? null
                                  : this.state.existingBills.map((item) => (
                                      <div className="col-md-3">
                                        {" "}
                                        <a
                                          href={baseURLImages + item.mediaUrl}
                                          target="_blank"
                                          className="linkwrap"
                                        >
                                          {" "}
                                          <div class="blocker"></div>
                                          <iframe
                                            src={baseURLImages + item.mediaUrl}
                                            width="100%"
                                            height="100%"
                                            frameborder="1"
                                          ></iframe>
                                        </a>
                                      </div>
                                    ))}
                              </div>
                            </div>
                          </div>
                        </>
                      ) : null}
                    </div>
                  </div>
                </div> */}


          {this.state.mypropertyDetails ? null : <DetailNewListing/>}
        </section>
        <div
          class="modal fade list-detail-modal"
          id="createOffer"
          tabindex="-1"
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4 class="modal-title" id="myModalLabel">
                  Make Offer
                </h4>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-xs-12 col-sm-12">
                    {" "}
                    <input
                      type="text"
                      className="form-control input-full main-input"
                      id="offerPrice"
                      name="offerPrice"
                      placeholder={this.state.offerPrice}
                      value={this.state.offerPrice}
                      required
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
                <div className="row">
                  <div className="col-xs-12 col-sm-12">
                    {" "}
                    <textarea
                      name="message"
                      class="form-control"
                      rows="3"
                      placeholder="Enter Message"
                      onChange={this.onHandleChange}
                    />
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn cancel-offer"
                  data-dismiss="modal"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn  make-offer"
                  onClick={() => this.handleMakeOffer()}
                  data-dismiss="modal"
                  data-toggle="modal"
                  data-target="#message"
                >
                  Make Offer
                </button>
              </div>
            </div>
          </div>
        </div>
        <div
          class="modal fade list-detail-modal"
          id="message"
          tabindex="-1"
          role="dialog"
          aria-labelledby="basicModal"
          aria-hidden="true"
        >
          <div class="modal-dialog modal-lg">
            <div class="modal-content">
              <div class="modal-header">
                <button
                  type="button"
                  class="close"
                  data-dismiss="modal"
                  aria-hidden="true"
                >
                  &times;
                </button>
                <h4>Alert !</h4>
              </div>
              <div class="modal-body">
                <div className="row">
                  <div className="col-xs-12 col-sm-12">
                    <h5>{this.state.errorMessage}</h5>
                  </div>
                </div>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn cancel-offer"
                  data-dismiss="modal"
                >
                  Ok
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default withRouter(ListDetail);
