import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ListingCard from "./../../common/ListingCard/ListingCard";
import HomeFooterSlider from "./../../common/HomeFooterSlider/HomeFooterSlider";

import Data from "../../../GeoData.json";
import ListingImage from "./../../../assets/images/grid-offer2.jpg";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import Autocomplete from "react-google-autocomplete";
import Modal from "react-responsive-modal";
import MySlider from "@material-ui/core/Slider";
import { withRouter } from "react-router-dom";
import NewGoogleMap from "../NewGoogleMap/NewGoogleMap";
import SearchMap from "../SearchMap/SearchMap";
import DefaultImage from "../../../assets/images/grid-offer1.jpg";
import one from "./../../../assets/images/one.jpg";
import two from "./../../../assets/images/two.jpg";
import three from "./../../../assets/images/three.jpg";
import farm from "./../../../assets/images/farm.jpg";
import avatar from "./../../../assets/images/avatar.jpg";
import GoogleMapReact from "google-map-react";
import { getToken } from "../../common/commonFunctions/commonFunctions";
import {
  disableScroll,
  enableScroll,
} from "../../common/commonFunctions/commonFunctions";
import { toast } from "react-toastify";
const AnyReactComponent = ({ text }) => <div>{text}</div>;
const PropertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial Plot" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
];
class FinancingNewListing extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Listings: [],
      myFavouritProperty: [],
      minValue:0,
      maxValue:1000000000,
      value: [0, 1000000],
      Kitchen: "",
      google: false,
      TransactionType: "",
      PropertyTypeId: "",
      CountryName: "",
      Bedroom: "",
      Bathroom: "",
      ATransactionType: "",
      APropertyTypeId: "",
      ACountryName: "",
      AKitchen: "",
      ABathroom: "",
      ABedroom: "",
      TransType: "",
      buyColor: "",
      rentColor: "",
      sellColor: "",
      commercialColor: "",
      openModel: false,
      openBath: "none",
      And: "",
      bathBlock: false,
      setActive: "",
      oneActive: "",
      twoActive: "",
      threeActive: "",
      fourActive: "",
      fiveActive: "",
      bActive: "",
      oneBActive: "",
      twoBActive: "",
      threeBActive: "",
      fourBActive: "",
      fiveBActive: "",
      center: {
        lat: 59.95,
        lng: 30.33,
      },
      isLoader: false,
    };
  }
  componentDidMount() {
    let array = [];
    let arraySliced = [];
    this.setState({ isLoader: true });
    disableScroll();
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/get_listings?TransactionType=Sold",
    })
      .then((response) => {
        this.setState({ isLoader: false });
        enableScroll();
        array = response.data.data;
        // arraySliced = array.slice(0, array.length - 5);
        this.setState({ Listings: array }, () => {});
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
      })
      .catch((error) => {
        window.scrollTo(0, document.body.scrollHeight || document.documentElement.scrollHeight);
        this.setState({ isLoader: false });
        enableScroll();
      });
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        console.log("my favourite property ", response.data.data);
        this.setState({ myFavouritProperty: response.data.data });
      })
      .catch((error) => {
        console.log("errrrrrrrrrrr", error);
      });
  }
  buyTransaction = () => {
    this.setState({ buyColor: "#C17400" });
    this.setState({ rentColor: "" });
    this.setState({ sellColor: "" });
    this.setState({ commercialColor: "" });
    this.setState({ TransType: "Buy" });
  };
  rentTransaction = () => {
    this.setState({ buyColor: "" });
    this.setState({ rentColor: "#C17400" });
    this.setState({ sellColor: "" });
    this.setState({ commercialColor: "" });
    this.setState({ TransType: "Rent" });
  };
  sellTransaction = () => {
    this.setState({ buyColor: "" });
    this.setState({ rentColor: "" });
    this.setState({ sellColor: "#C17400" });
    this.setState({ commercialColor: "" });
    this.setState({ TransType: "Sold" });
  };
  commercialTransaction = () => {
    this.setState({ buyColor: "" });
    this.setState({ rentColor: "" });
    this.setState({ sellColor: "" });
    this.setState({ commercialColor: "#C17400" });
    this.setState({ TransType: "Commercial" });
  };
  submitSearch = () => {
    this.setState({ isLoader: true });
    axios({
      method: "get",
      url:
        baseURL +
        `api/v1/Property/get_listings?MinimumAmount=${this.state.minValue}&MaximumAmount"${this.state.maxValue}${this.state.ATransactionType}${this.state.TransactionType}${this.state.APropertyTypeId}${this.state.PropertyTypeId}
${this.state.ACountryName}${this.state.CountryName}${this.state.AKitchen}${this.state.Kitchen}${this.state.ABathroom}${this.state.Bathroom}${this.state.ABedroom}${this.state.Bedroom}
`,
    })
      .then((response) => {
        this.setState({ openModel: false });
        this.setState({ isLoader: false });
        enableScroll();
        let array = response.data.data;
        let arraySliced = array.slice(0, array.length - 5);
        this.setState({ Listings: array });
        window.scroll({
          top: 800,
          left: 0,
          behavior: "smooth",
        });
      })
      .catch((error) => {
        this.setState({ isLoader: false });
        enableScroll();
      });
  };

  // const [value, setValue] = React.useState([0, 1000000]);

  handleChange = (event, newValue) => {
    this.setState({ minValue: newValue });
  };
  handleChangeTwo = (event, newValue) => {
    this.setState({ maxValue: newValue });
  };

  handleListingChange = (e) => {
    if (e.target.name === "TransactionTypes") {
      this.setState({ ATransactionType: "&TransactionType=" });
      this.setState({ TransactionType: e.target.value });
      if (this.state.TransactionType === "") {
        this.setState({ ATransactionType: "" });
      }
    } else if (e.target.name === "PropertyTypeId") {
      this.setState({ APropertyTypeId: "&PropertyTypeId=" });
      this.setState({ PropertyTypeId: e.target.value });
      if (this.state.PropertyTypeId === "") {
        this.setState({ APropertyTypeId: "" });
      }
    } else if (e.target.name === "CountryName") {
      this.setState({ ACountryName: "&CountryName=" });
      this.setState({ CountryName: e.target.value });
      if (this.state.CountryName === "") {
        this.setState({ ACountryName: "" });
      }
    } else if (e.target.name === "maxKitchen") {
      this.setState({ AKitchen: "&Kitchen=" });
      this.setState({ Kitchen: e.target.value });
      if (this.state.Kitchen === "") {
        this.setState({ AKitchen: "" });
      }
    } else if (e.target.name === "maxBed") {
      this.setState({ ABedroom: "&Bedroom=" });
      this.setState({ Bedroom: e.target.value });
      if (this.state.Bedroom === "") {
        this.setState({ ABedroom: "" });
      }
    } else if (e.target.name === "maxBath") {
      this.setState({ ABathroom: "&Bathroom=" });
      this.setState({ Bathroom: e.target.value });
      if (this.state.Bathroom === "") {
        this.setState({ ABathroom: "" });
      }
    }
  };

  setModel = () => {
    this.setState({ openModel: true });
  };
  closeModal = () => {
    this.setState({ openModel: false });
  };

  addBath = (value) => {

    this.setState({ ABathroom: "&Bathroom=" });
    this.setState({ Bathroom: value });

    if (value === "") {
      this.setState({ bActive: "#C17400" });
      this.setState({ oneBActive: "" });
      this.setState({ twoBActive: "" });
      this.setState({ threeBActive: "" });
      this.setState({ fourBActive: "" });
      this.setState({ fiveBActive: "" });
    } else if (value === "1") {
      this.setState({ bActive: "" });
      this.setState({ oneBActive: "#C17400" });
      this.setState({ twoBActive: "" });
      this.setState({ threeBActive: "" });
      this.setState({ fourBActive: "" });
      this.setState({ fiveBActive: "" });
    } else if (value === "2") {
      this.setState({ bActive: "" });
      this.setState({ oneBActive: "" });
      this.setState({ twoBActive: "#C17400" });
      this.setState({ threeBActive: "" });
      this.setState({ fourBActive: "" });
      this.setState({ fiveBActive: "" });
    } else if (value === "3") {
      this.setState({ bActive: "" });
      this.setState({ oneBActive: "" });
      this.setState({ twoBActive: "" });
      this.setState({ threeBActive: "#C17400" });
      this.setState({ fourBActive: "" });
      this.setState({ fiveBActive: "" });
    } else if (value === "4") {
      this.setState({ bActive: "" });
      this.setState({ oneBActive: "" });
      this.setState({ twoBActive: "" });
      this.setState({ threeBActive: "" });
      this.setState({ fourBActive: "#C17400" });
      this.setState({ fiveBActive: "" });
    } else if (value === "5") {
      this.setState({ bActive: "" });
      this.setState({ oneBActive: "" });
      this.setState({ twoBActive: "" });
      this.setState({ threeBActive: "" });
      this.setState({ fourBActive: "" });
      this.setState({ fiveBActive: "#C17400" });
    }
  };

  addBed = (value) => {
    this.setState({ ABedroom: "&Bedroom=" });
    this.setState({ Bedroom: value });
    if (value === "") {
      this.setState({ setActive: "#C17400" });
      this.setState({ oneActive: "" });
      this.setState({ twoActive: "" });
      this.setState({ threeActive: "" });
      this.setState({ fourActive: "" });
      this.setState({ fiveActive: "" });
    } else if (value == "1") {
      this.setState({ setActive: "" });
      this.setState({ oneActive: "#C17400" });
      this.setState({ twoActive: "" });
      this.setState({ threeActive: "" });
      this.setState({ fourActive: "" });
      this.setState({ fiveActive: "" });
    } else if (value == "2") {
      this.setState({ setActive: "" });
      this.setState({ oneActive: "" });
      this.setState({ twoActive: "#C17400" });
      this.setState({ threeActive: "" });
      this.setState({ fourActive: "" });
      this.setState({ fiveActive: "" });
    } else if (value == "3") {
      this.setState({ setActive: "" });
      this.setState({ oneActive: "" });
      this.setState({ twoActive: "" });
      this.setState({ threeActive: "#C17400" });
      this.setState({ fourActive: "" });
      this.setState({ fiveActive: "" });
    } else if (value == "4") {
      this.setState({ setActive: "" });
      this.setState({ oneActive: "" });
      this.setState({ twoActive: "" });
      this.setState({ threeActive: "" });
      this.setState({ fourActive: "#C17400" });
      this.setState({ fiveActive: "" });
    } else if (value == "5") {
      this.setState({ setActive: "" });
      this.setState({ oneActive: "" });
      this.setState({ twoActive: "" });
      this.setState({ threeActive: "" });
      this.setState({ fourActive: "" });
      this.setState({ fiveActive: "#C17400" });
    }
  };

  handleButton = (e) => {
    this.props.history.push({
      pathname: "/listingdetail",
      search: "selectedList=" + e,
    });
    // window.location.reload()
  };
  gotoNext = () => {
    this.slider.slickNext();
  };
  gotoPrev = () => {
    this.slider.slickPrev();
  };
  onPlaceSelected = (place) => {
    console.log("plc", place);
    alert("hello");
    this.props.history.push({
      pathname: `/Marketplace`,
      state: {
        name: "jibran",
        latitude: place.geometry.location.lat(),
        longitude: place.geometry.location.lng(),
      },
    });
  };
  buyHome = () => {
    this.props.history.push({
      pathname: `/Marketplace`,
      state: {
        TransactionType: "Buy",
      },
    });
  };
  rentHome = () => {
    this.props.history.push({
      pathname: `/Marketplace`,
      state: {
        TransactionType: "Rent",
      },
    });
  };
  soldHome = () => {
    this.props.history.push({
      pathname: `/Marketplace`,
      state: {
        TransactionType: "Sold",
      },
    });
  };
  commercialHome = () => {
    this.props.history.push({
      pathname: `/Marketplace`,
      state: {
        TransactionType: "Commercial",
      },
    });
  };

  favouritProperty = (id) => {
    toast.success("Added in favourite");
    axios({
      method: "post",
      url: baseURL + "api/v1/Property/AddFavouriteProperty",
      data: {
        propertyId: id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("my favourite property ", response.data.data);
            this.setState({ myFavouritProperty: response.data.data });
          })
          .catch((error) => {
            console.log("errrrrrrrrrrr", error);
          });
        console.log("remove");
        this.setState({ favouritColor: "red" });
      })
      .catch((error) => {
        console.log("errrrrrrr", error);
      });
  };
  removeFavouritProperty = (id) => {
    toast.error("Removed from favourite");
    axios({
      method: "put",
      url: baseURL + "api/v1/Property/RemoveFavouriteProperty",
      data: {
        propertyId: id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("my favourite property ", response.data.data);
            this.setState({ myFavouritProperty: response.data.data });
          })
          .catch((error) => {
            console.log("errrrrrrrrrrr", error);
          });

        console.log("remove");
        this.setState({ favouritColor: "red" });
      })
      .catch((error) => {
        console.log("errrrrrrr", error);
      });
  };
  setBath = () => {
    if (this.state.bathBlock === true) {
      this.setState({ bathBlock: false });
      this.setState({ openBath: "none" });
    } else {
      this.setState({ bathBlock: true });
      this.setState({ openBath: "block" });
    }
  };
  closeBath = () => {
    if (this.state.bathBlock === true) {
      this.setState({ bathBlock: false });
      this.setState({ openBath: "none" });
    } else {
      this.setState({ bathBlock: true });
      this.setState({ openBath: "block" });
    }
  };
  render() {
    if (this.state.Listings.length === 1) {
      var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        responsive: [
          {
            breakpoint: 2024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    } else if (this.state.Listings.length === 2) {
      var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 2,
        slidesToScroll: 2,
        responsive: [
          {
            breakpoint: 2024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    } else if (this.state.Listings.length === 3) {
      var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 3,
        responsive: [
          {
            breakpoint: 2024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    } else if (this.state.Listings.length >= 4) {
      var settings = {
        arrows: false,
        infinite: true,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 4,
        responsive: [
          {
            breakpoint: 2024,
            settings: {
              slidesToShow: 4,
              slidesToScroll: 4,
              infinite: true,
              dots: true,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
            },
          },
          {
            breakpoint: 480,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      };
    }
    return (
      <div>
        <div className="landing-header-section">
          <Modal
            classNames="modal-width"
            open={this.state.openModel}
            onClose={this.closeModal}
            center
          >
            <div className="accept-modal">
              {" "}
              <div class="modal-body">
                {" "}
                <section className="section-light top-padding-60 location-pnl bottom-padding-60">
                  <div className="transaction-frame-pnl">
                    <iframe
                      src={
                        "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.1583091352!2d-74.11976373946234!3d40.69766374859258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1642745113772!5m2!1sen!2s"
                      }
                    ></iframe>
                  </div>
                  <form
                    class="adv-search-form"
                    onSubmit={(e) => {
                      e.preventDefault();
                    }}
                  >
                    <div class="adv-search-cont">
                      <div class="pd-div">
                        <div class="row">
                          <div class="col-xs-12 col-lg-12 col-md-12 text-center">
                            <h1>Location & Neighbouring</h1>
                          </div>
                        </div>
                      </div>
                      <div class="pd-div margin-top-60">
                        <div class="row tab-content">
                          <div
                            role="tabpanel"
                            class="col-xs-12 adv-search-outer tab-pane fade in active"
                            id="apartments"
                          >
                            <div class="row">
                              <div class="col-xs-12 col-sm-6 col-md-4 col-lg-4 select-field">
                                <label>Transaction Type</label>
                                <select
                                  name="TransactionTypes"
                                  className="form-control input-full main-input property-select"
                                  id="TransactionTypes"
                                  // onChange={this.onHandleChange}
                                  onChange={this.handleListingChange}
                                >
                                  {" "}
                                  <option value="">
                                    Select Transaction Type
                                  </option>
                                  <option value="Sale">Sale</option>
                                  <option value="Rent">Rent</option>
                                </select>
                              </div>
                              <div class="col-xs-12 col-sm-6 col-md-4  col-lg-4 select-field">
                                <label>Property Type</label>
                                <select
                                  name="PropertyTypeId"
                                  className="form-control input-full main-input property-select"
                                  onChange={this.handleListingChange}
                                >
                                  <option value="">Select Property Type</option>
                                  <option value="1">House</option>
                                  <option value="2">Farm House</option>
                                  <option value="3">Penthouse</option>
                                  <option value="4">Flat</option>
                                  <option value="5">Commercial Plot</option>
                                  <option value="6">Agricultural Land</option>
                                  <option value="7">Residential Plot</option>
                                  <option value="8">Office</option>
                                  <option value="9">Warehouse</option>
                                  <option value="10">Building</option>
                                  <option value="11">Shop</option>
                                  <option value="12">Factory</option>
                                </select>
                              </div>
                              <div class="col-xs-12 col-sm-6 col-lg-4 col-md-4  select-field">
                                <label>Country Name</label>
                                <select
                                  name="CountryName"
                                  className="form-control input-full main-input property-select"
                                  onChange={this.handleListingChange}
                                >
                                  <option value="">Select Country</option>
                                  <option value="United States">
                                    United States
                                  </option>
                                  {Data.map((countryName) => (
                                    <option value={countryName.name}>
                                      {countryName.name}
                                    </option>
                                  ))}
                                </select>
                              </div>
                            </div>
                            <div class="row">
                              <div class="col-xs-12 col-sm-6 slider col-md-4  col-lg-4">
                                <div class="adv-search-range-cont">
                                  <label
                                    for="slider-range-price1-value"
                                    class="adv-search-label pull-left"
                                  >
                                    Amount:
                                  </label>
                                  <span
                                    className="pull-right"
                                    style={{ color: "white" }}
                                  >
                                    min-{this.state.minValue}$ max-
                                    {this.state.maxValue}$
                                  </span>
                                  <div style={{ marginTop: "50px" }}>
                                    {/* <Slider
                            value={value}
                            onChange={handleChange}
                            // valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            getAriaValueText={valuetext}
                          /> */}

                                    <MySlider
                                      min={0}
                                      max={100000000}
                                      value={this.state.minValue}
                                      onChange={this.handleChange}
                                    />
                                    <MySlider
                                    style={{marginTop:"20px"}}
                                     
                                      max={100000000}
                                      value={this.state.maxValue}
                                      onChange={this.handleChangeTwo}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div class="col-xs-12 col-sm-6 col-md-4  col-lg-4">
                                <div class="adv-search-range-cont">
                                  <label
                                    for="slider-range-price1-value"
                                    class="adv-search-label pull-left"
                                  >
                                    Beds & Bathrooms
                                  </label>
                                  <button
                                    class="drop-down-btn"
                                    onClick={this.setBath}
                                  >
                                    Bed & Bathrooms
                                  </button>
                                  <div
                                    class="dropdown-content"
                                    style={{
                                      display: `${this.state.openBath}`,
                                    }}
                                  >
                                    <div className="full-div">
                                      <h5>Bedrooms</h5>
                                      <div className="setBathBtn">
                                        <ul className="btn-list">
                                          <li
                                            onClick={() => {
                                              this.addBed("");
                                            }}

                                            style={{background:`${this.state.setActive}`}}
                                            // className={`${this.state.setActive}`}
                                          >
                                            Any
                                          </li>
                                          <li
                                            onClick={() => {
                                              this.addBed("1");
                                            }}
                                            style={{background:`${this.state.oneActive}`}}
                                            // className={`${this.state.oneActive}`}
                                          >
                                            1+
                                          </li>
                                          <li
                                            onClick={() => {
                                              this.addBed("2");
                                            }}
                                            style={{background:`${this.state.twoActive}`}}

                                            // className={`${this.state.twoActive}`}
                                          >
                                            2+
                                          </li>
                                          <li
                                            onClick={() => {
                                              this.addBed("3");
                                            }}
                                            style={{background:`${this.state.threeActive}`}}
                                            // className={`${this.state.threeActive}`}
                                          >
                                            3+
                                          </li>
                                          <li
                                            onClick={() => {
                                              this.addBed("4");
                                            }}
                                            style={{background:`${this.state.fourActive}`}}
                                            // className={`${this.state.fourActive}`}
                                          >
                                            4+
                                          </li>
                                          <li
                                            onClick={() => {
                                              this.addBed("5");
                                            }}
                                            // style={{background:"gold"}}
                                            style={{background:`${this.state.fiveActive}`}}
                                            // className={`${this.state.fiveActive}`}
                                          >
                                            5+
                                          </li>
                                        </ul>
                                      </div>
                                    </div>

                                    <div className="full-div">
                                      <h5>Bathrooms</h5>
                                      <ul className="btn-list">
                                        <li
                                          onClick={() => {
                                            this.addBath("");
                                          }}
                                          style={{background:`${this.state.bActive}`}}
                                          // className={`${this.state.bActive}`}
                                        >
                                          Any
                                        </li>
                                        <li
                                          onClick={() => {
                                            this.addBath("1");
                                          }}
                                          style={{background:`${this.state.oneBActive}`}}
                                          // className={`${this.state.oneBActive}`}
                                        >
                                          1+
                                        </li>
                                        <li
                                          onClick={() => {
                                            this.addBath("2");
                                          }}
                                          style={{background:`${this.state.twoBActive}`}}
                                          // className={`${this.state.twoBActive}`}
                                        >
                                          2+
                                        </li>
                                        <li
                                          onClick={() => {
                                            this.addBath("3");
                                          }}
                                          style={{background:`${this.state.threeBActive}`}}
                                          // className={`${this.state.threeBActive}`}
                                        >
                                          3+
                                        </li>
                                        <li
                                          onClick={() => {
                                            this.addBath("4");
                                          }}
                                          style={{background:`${this.state.fourBActive}`}}
                                          // className={`${this.state.fourBActive}`}
                                        >
                                          4+
                                        </li>
                                        <li
                                          onClick={() => {
                                            this.addBath("5");
                                          }}
                                          style={{background:`${this.state.fiveBActive}`}}
                                          // className={`${this.state.fiveBActive}`}
                                        >
                                          5+
                                        </li>
                                      </ul>
                                    </div>
                                    <div className="full-div">
                                      <button
                                        className="done-btn"
                                        onClick={this.closeBath}
                                      >
                                        Done
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                           
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="container">
                        <div class="row">
                          <div class="col-sm-12 col-md-6 col-lg-3 col-md-offset-6 col-lg-offset-9 adv-search-button-cont">
                            <button
                              onClick={() => {
                                this.submitSearch();
                              }}
                              class="button-primary pull-right"
                            >
                              <span>search</span>
                              <div class="button-triangle"></div>
                              <div class="button-triangle2"></div>
                              <div class="button-icon">
                                <i class="fa fa-search"></i>
                              </div>
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </section>
              </div>
              <div className="Modal-footer"></div>
            </div>
          </Modal>
          <div className="bg-layer setSearch">
            <div className="">
              <h1 style={{ color: "white" }} className="text-center">
                Find Property
              </h1>
              <div style={{ marginTop: "50px" }}>
                <button
                  onClick={() => {
                    this.buyTransaction();
                  }}
                  className="btn btn-primary"
                  style={{ backgroundColor: `${this.state.buyColor}` }}
                >
                  <span>Buy</span>
                </button>
                <button
                  onClick={() => {
                    this.rentTransaction();
                  }}
                  className="btn btn-primary"
                  style={{ backgroundColor: `${this.state.rentColor}` }}
                >
                  <span>Rent</span>
                </button>
                <button
                  onClick={() => {
                    this.sellTransaction();
                  }}
                  className="btn btn-primary"
                  style={{ backgroundColor: `${this.state.sellColor}` }}
                >
                  <span>Sold</span>
                </button>
                <button
                  onClick={() => {
                    this.commercialTransaction();
                  }}
                  className="btn btn-primary"
                  style={{ backgroundColor: `${this.state.commercialColor}` }}
                >
                  <span>Commercial</span>
                </button>
              </div>
              {/* <button style={{color:"black"}}>buy</button>
          <button style={{color:"black"}}>rent</button> */}
              <div>
                <SearchMap
                  className="input-search-bg"
                  style={{
                    width: "500px",
                    height: "60px",
                    paddingLeft: "16px",
                    marginTop: "2px",
                  }}
                  google={this.props.google}
                  center={{ lat: 18.5204, lng: 73.8567 }}
                  TransactionType={this.state.TransType}
                  zoom={15}
                  history={this.props.history}
                />
              </div>
              <div>
                <button class="button-primary" onClick={this.setModel}>
                  <span>Advance Search</span>
                  <div class="button-triangle"></div>
                  <div class="button-triangle2"></div>
                  <div class="button-icon">
                    <i class="fa fa-search"></i>
                  </div>
                </button>
              </div>
            </div>
          </div>

          {/* <div className="landing-bar">
          <div className="landing-bar-inner">
            <ul className="bar-list">
              <li>
                <div className="table">
                  <div className="table-cell">
                    <div className="txt-div">
                      <p>178, Lake View New York, Ny- 1004</p>
                    </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="table">
                  <div className="table-cell">
                  <div className="txt-div">
                    <p>2 Bedrooms</p>
                    <p>3 Bathrooms</p>
                    <p>35 Sqr.Ft</p>
                  </div>
                  </div>
                </div>
              </li>
              <li>
                <div className="table">
                  <div className="table-cell">
                  <div className="txt-div">
                    <h1>
                      <span>Price:</span>
                      $ 35,000
                    </h1>
                  </div>
                  </div>
                </div>
                
              </li>
            </ul>
          </div>
       
        </div> */}
        </div>

        <section
          className="section-light 
         villa-section  carosuile-section text-center"
        >
          <div className="row">
            <div className="container">
              <div
                className="col-sm-6 col-md-4 col-lg-3"
                onClick={() => {
                  this.buyHome();
                }}
              >
                <div className="homeSection">
                  <div className="img-pnl">
                    <img src={one} />
                  </div>
                  <div className="text-pnl">
                    <h4>Buy a home</h4>
                    <p>
                      Nashville residents have taken notice of a rash of texts
                      from one-named investors asking to buy their unlisted
                      homes
                    </p>
                    <button className="btn btn-primary">Search homes</button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3" 
               onClick={() => {
                this.rentHome();
              }}
              >
                <div className="homeSection">
                  <div className="img-pnl">
                    <img src={one} />
                  </div>
                  <div className="text-pnl">
                    <h4>Rent a home</h4>
                    <p>
                      Nashville residents have taken notice of a rash of texts
                      from one-named investors asking to buy their unlisted
                      homes
                    </p>
                    <button className="btn btn-primary">Search homes</button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3" 
               onClick={() => {
                this.soldHome();
              }}
              >
                <div className="homeSection">
                  <div className="img-pnl">
                    <img src={one} />
                  </div>
                  <div className="text-pnl">
                    <h4>Sell a home</h4>
                    <p>
                      Nashville residents have taken notice of a rash of texts
                      from one-named investors asking to buy their unlisted
                      homes
                    </p>
                    <button className="btn btn-primary">Search homes</button>
                  </div>
                </div>
              </div>
              <div className="col-sm-6 col-md-4 col-lg-3"
               onClick={() => {
                this.commercialHome();
              }}
              >
                <div className="homeSection">
                  <div className="img-pnl">
                    <img src={one} />
                  </div>
                  <div className="text-pnl">
                    <h4>Commercial home</h4>
                    <p>
                      Nashville residents have taken notice of a rash of texts
                      from one-named investors asking to buy their unlisted
                      homes
                    </p>
                    <button className="btn btn-primary">Search homes</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {this.state.Listings.length === 0 ? (
          <h1 className="text-center mt-5">no data found</h1>
        ) : (
          ""
        )}
        <section
          className="section-light 
         villa-section  carosuile-section"
        >
          <div className={this.state.isLoader ? "loader-bg" : ""}></div>
          <div className="grid-offers-container slider-cntnr">
            <a
              onClick={() => this.gotoPrev()}
              className="navigation-box navigation-box-prev"
              id="grid-offers-owl-prev"
            >
              <div className="navigation-triangle"></div>
              <div className="navigation-box-icon">
                <i className="fas fa-angle-left"></i>
              </div>
            </a>
            <a
              className="navigation-box navigation-box-next"
              id="grid-offers-owl-next"
              onClick={() => this.gotoNext()}
            >
              <div className="navigation-triangle"></div>
              <div className="navigation-box-icon">
                <i className="fas fa-angle-right"></i>
              </div>
            </a>

            <Slider
              ref={(c) => (this.slider = c)}
              style={{ width: "95%", margin: "auto" }}
              {...settings}
            >
              {this.state.Listings.map((list) => (
                <HomeFooterSlider
                  ImageURL={
                    list.propertyMedia === null ? (
                      <img height="175px" src={DefaultImage} />
                    ) : list.propertyMedia.filter(
                        (item) => item.isThumbnail === true
                      ).length <= 0 ? (
                      <img
                        onClick={() => this.handleButton(list.uuid)}
                        height="175px"
                        src={
                          baseURL +
                          list.propertyMedia.filter(
                            (item) => item.isThumbnail === false
                          )[0]?.mediaUrl
                        }
                      />
                    ) : (
                      list.propertyMedia
                        .filter((item) => item.isThumbnail === true)
                        .map((data) => (
                          <img height="175px" src={baseURL + data.mediaUrl} />
                        ))
                    )
                  }
                  PropertyType={PropertyType.filter(
                    (item) => item.id === list.propertyTypeId
                  ).map((id) => id.PropertyTypeName)}
                  TransactionType={list.transactionTypes}
                  LocalAddress={list.locationAddress}
                  Description={list.discription}
                  Price={list.price}
                  PropertyArea={list.propertyArea}
                  Bedrooms={list.bedrooms}
                  Bath={list.fullBaths}
                  uuid={list.uuid}
                  id={list.id}
                  favCount={list.propertyFavouritesCount}
                  handleButton={() => {}}
                  longitude={list.longitude}
                  latitude={list.latitude}
                  ListingData={this.state.Listings}
                  myFavouritProperty={this.state.myFavouritProperty}
                  favouritProperty={this.favouritProperty}
                  removeFavouritProperty={this.removeFavouritProperty}
                />
              ))}
            </Slider>
          </div>
        </section>
      </div>
    );
  }
}

export default withRouter(FinancingNewListing);
