import React, { useState } from "react";
import Button from "../../common/Button/Button";
import { setIsLoader } from "../../common/commonFunctions/commonFunctions";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import {
  disableScroll,
  enableScroll,
} from "../../common/commonFunctions/commonFunctions";
const ResetPassword = () => {
  const [resetPasswordEmail, setResetPasswordEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState("");
  const [isSuccess, setIsSuccess]=useState(false)
  const handleChange = (event) => {
    setResetPasswordEmail(event.target.value);
    console.log(event.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();

    console.log("onClick");
    disableScroll();
    setLoader(true);

    axios({
      method: "post",
      url: baseURL + "api/v1/Auth/ForgetPassword",
      data: {
        email: resetPasswordEmail,
      },
    })
      .then((response) => {
        enableScroll();
        setLoader(false);
        setIsSuccess(response.data.isSuccess)
        if (response.status == 200 || response.status == 201) {
          setMessage("Check Your Email ");
        }
      })

      .catch((error) => {
        enableScroll();
        setLoader(false);
        setErrorMessage(error.response.data.error.errorMessage);
      });
  };
  return (
    <div class="modal-body ">
      <div className={loader ? "loader-bg" : ""}></div>
      {isSuccess ? (
        <>
          <strong>
            <h4>
              <p class="login-or">{message}</p>
            </h4>
          </strong>
          <button
            type="button"
            class="btn btn-primary"
            style={{backgroundColor:"#C17400",border:"1px solid #C17400"}}
            data-dismiss="modal"
            aria-label="Close"
          >
            ok
          </button>
          <div class="mt-3 mb-3"></div>
        </>
      ) : (
        <>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>{" "}
          <div className="modal-title">
            <h1>
              Forgot your password<span class="special-color">?</span>
            </h1>
            <div class="short-title-separator"></div>
          </div>
          <p class="negative-margin forgot-info">
            Enter your email address.
            <br />
            We will send you a link to reset your password.
          </p>
          <form onSubmit={onSubmit}>
            <input
              name="resetPasswordEmail"
              type="email"
              pattern="^[a-zA-Z0-9.!#$%'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)+$"
              class="input-full main-input"
              placeholder="Email"
              onChange={handleChange}
              required
            />
            <div className="text-danger">{errorMessage}</div>
            <div className="text-success">{message}</div>
            <Button type="submit" buttonName="RESET PASSWORD" />
          </form>
        </>
      )}
    </div>
  );
};

export default ResetPassword;
