import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import Room from "../../../assets/images/rooms-icon.png";
import Bath from "../../../assets/images/bathrooms-icon.png";
import Area from "../../../assets/images/area-icon.png";
import Grid from "../../../assets/images/grid-icon.png";
import ListIcon from "../../../assets/images/list-icon.png";
import apartmentbanner from "../../../assets/images/apartment-banner.jpg";
import apartmentbaner from "../../../assets/images/bg-pattern12.jpg";

import { withRouter, Link } from "react-router-dom";
import Pagination from "../../common/Pagination/Pagination";
import queryString from "query-string";
import GoogleMapReact from "google-map-react";
import {
  disableScroll,
  enableScroll,
  getToken
} from "../../common/commonFunctions/commonFunctions";
import Map from "../Test/Map";
import MyGoogleMap from "../MyGoogleMap/MyGoogleMap";
import "./Maps.css"

const AnyReactComponent = ({ text }) => <div className="pin1"></div>;
const PropertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
];
class LendingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Listings: [],
      totalItems: "",
      list: "list",
      isLoader: false,
      currentPage: 1,
      pageSize: 4,
      paginationPages: 0,
      paginationCurrentPage: 0,
      orderByFilter: "",
      favourit: false,
      favouritColor: "black",
      myFavouritProperty: []
    };
  }

  componentDidMount() {


    axios({
      method: "get",
      url:
        baseURL +
        "api/v1/Property/GetMyFavouriteProperty",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        console.log("my favourite property ", response.data.data);
        this.setState({ myFavouritProperty: response.data.data })

      })
      .catch((error) => {
        console.log("errrrrrrrrrrr", error)
      });





    var url = this.props.location.search;
    var params = queryString.parse(url);

    if (url == "") {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings",
      })
        .then((response) => {
          console.log(response);
          enableScroll();
          console.log(
            "pageNumber",
            Math.ceil(response.data.totalItems / response.data.pageSize)
          );
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    } else {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          "Country=" +
          params.Country +
          "&City=" +
          params.City +
          "&TransactionType=" +
          params.TransactionType +
          "&PropertyTypeID=" +
          params.PropertyTypeID +
          "&MaximumAmount=" +
          params.MaximumAmount +
          "&MinimumAmount=" +
          params.MinimumAmount +
          "&Kitchen=" +
          params.Kitchen +
          "&Bedroom=" +
          params.Bedroom +
          "&Bathroom=" +
          params.Bathroom +
          "&PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          this.state.currentPage,
      })
        .then((response) => {
          enableScroll();
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {

        });
    }
  }

  favouritProperty = (id) => {
    this.state.myFavouritProperty.map((item) => {
      if (item.id == id) {
        this.setState({ favouritColor: "black" })
        axios({
          method: "put",
          url: baseURL + "api/v1/Property/RemoveFavouriteProperty",
          data: {
            propertyId: id,
          },
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("remove")
            this.setState({ favourit: false })
          })
          .catch((error) => {
            console.log("errrrrrrr", error)
          });
      }
      else {
        this.setState({ favouritColor: "red" })
        axios({
          method: "post",
          url: baseURL + "api/v1/Property/AddFavouriteProperty",
          data: {
            propertyId: id,
          },
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            this.setState({ favourit: true })
            console.log("added")
          })
          .catch((error) => {
          });
      }
    })
  }

  onHandleGridClick = () => {
    this.setState({
      list: "grid",
    });
  };
  onHandleListClick = () => {
    this.setState({
      list: "list",
    });
  };
  handlePageNumber = (value) => {
    var url = this.props.location.search;
    var params = queryString.parse(url);

    if (url == "") {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          this.state.orderByFilter +
          "PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          value,
      })
        .then((response) => {


          enableScroll();
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    } else {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          this.state.orderByFilter +
          "Country=" +
          params.Country +
          "&City=" +
          params.City +
          "&TransactionType=" +
          params.TransactionType +
          "&PropertyTypeID=" +
          params.PropertyTypeID +
          "&MaximumAmount=" +
          params.MaximumAmount +
          "&MinimumAmount=" +
          params.MinimumAmount +
          "&Kitchen=" +
          params.Kitchen +
          "&Bedroom=" +
          params.Bedroom +
          "&Bathroom=" +
          params.Bathroom +
          "&PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          value,
      })
        .then((response) => {
          enableScroll();
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    }
  };
  firstPage = () => {
    this.handlePageNumber(1);
  };
  lastPage = () => {
    this.handlePageNumber(this.state.paginationPages);
  };
  componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    const {
      location: { search: prevSearch },
    } = prevProps;

    if (search !== prevSearch) {
      if (search == "") {
        this.setState({ isLoader: true });

        axios({
          method: "get",
          url:
            baseURL +
            "api/v1/Property/get_listings?" +
            "&PageSize=" +
            this.state.pageSize +
            "&CurrentPage=" +
            this.state.currentPage,
        })
          .then((response) => {
            enableScroll();
            console.log("pageNumber", response);
            this.setState(
              {
                totalItems: response.data.totalItems,
                Listings: response.data.data,
                isLoader: false,
                paginationPages: Math.ceil(
                  response.data.totalItems / response.data.pageSize
                ),
                paginationCurrentPage: response.data.currentPage,
              },
              () => { }
            );
          })
          .catch((error) => {
            enableScroll();
            this.setState({ isLoader: false });
          });
      } else {
        var url = this.props.location.search;
        var params = queryString.parse(url);

        this.setState({ isLoader: true });
        axios({
          method: "get",
          url:
            baseURL +
            "api/v1/Property/get_listings?" +
            "Country=" +
            params.Country +
            "&City=" +
            params.City +
            "&TransactionType=" +
            params.TransactionType +
            "&PropertyTypeID=" +
            params.PropertyTypeID +
            "&MaximumAmount=" +
            params.MaximumAmount +
            "&MinimumAmount=" +
            params.MinimumAmount +
            "&Kitchen=" +
            params.Kitchen +
            "&Bedroom=" +
            params.Bedroom +
            "&Bathroom=" +
            params.Bathroom +
            "&PageSize=" +
            this.state.pageSize +
            "&CurrentPage=" +
            this.state.currentPage,
        })
          .then((response) => {
            enableScroll();
            this.setState(
              {
                totalItems: response.data.totalItems,
                Listings: response.data.data,
                isLoader: false,
                paginationPages: Math.ceil(
                  response.data.totalItems / response.data.pageSize
                ),
                paginationCurrentPage: response.data.currentPage,
              },
              () => { }
            );
          })
          .catch((error) => {
            enableScroll();
            this.setState({ isLoader: false });
          });
      }
    }
  }
  handleButton = (e) => {
    this.props.history.push({
      pathname: "/listingdetail",
      search: "selectedList=" + e,
    });
  };

  onChangeOrderBy = (event) => {
    var url = this.props.location.search;
    var params = queryString.parse(url);
    this.setState({ [event.target.name]: event.target.value });
    if (url == "") {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          event.target.value +
          "&PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          this.state.currentPage,
      })
        .then((response) => {
          console.log(response);
          enableScroll();
          console.log(
            "pageNumber",
            Math.ceil(response.data.totalItems / response.data.pageSize)
          );
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    } else {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          event.target.value +
          "Country=" +
          params.Country +
          "&City=" +
          params.City +
          "&TransactionType=" +
          params.TransactionType +
          "&PropertyTypeID=" +
          params.PropertyTypeID +
          "&MaximumAmount=" +
          params.MaximumAmount +
          "&MinimumAmount=" +
          params.MinimumAmount +
          "&Kitchen=" +
          params.Kitchen +
          "&Bedroom=" +
          params.Bedroom +
          "&Bathroom=" +
          params.Bathroom +
          "&PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          this.state.currentPage,
      })
        .then((response) => {
          enableScroll();
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    }
  };
  render() {
    return (
      <>
        <div className={this.state.isLoader ? "loader-bg" : ""}></div>
        <section className="section-light section-top-shadow lending-page">
          <div className="lending-map-container">
            <div className="lending-map-container-inner">
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d86094.71198750017!2d-122.22117104328487!3d47.59774939291554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906bcfa3a66041%3A0xbacf5482ead00765!2sBellevue%2C%20WA%2C%20USA!5e0!3m2!1sen!2s!4v1643263892799!5m2!1sen!2s" allowfullscreen="" loading="lazy"></iframe> */}
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d86094.71198750017!2d-122.22117104328487!3d47.59774939291554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906bcfa3a66041%3A0xbacf5482ead00765!2sBellevue%2C%20WA%2C%20USA!5e0!3m2!1sen!2s!4v1643263821012!5m2!1sen!2s" ></iframe>

              {/* <MyGoogleMap /> */}
            </div>
          </div>
          <div className="container lending-text-pnl">
            <div className="row">
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="land-description-pnl">
                  <div className="text-pnl">
                    <h1 class="hedng"> Desciption</h1>
                    <p>
                      Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="image-pnl">
                  <div className="row">
                    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                      <div className="full-div">
                        <img src={apartmentbanner} alt="banner" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6">
                      <div className="full-div">
                        <img src={apartmentbaner} alt="banner" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6">
                      <div className="full-div">
                        <img src={apartmentbaner} alt="banner" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6">
                      <div className="full-div">
                        <img src={apartmentbaner} alt="banner" />
                      </div>
                    </div>
                    <div className="col-lg-3 col-md-6 col-sm-3 col-xs-6">
                      <div className="full-div">
                        <img src={apartmentbaner} alt="banner" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="height"></div>
            <div className="row">
              <div className="col-lg-4 col-md-6 col-sm-6">
                <h1 class="hedng"> Land Info</h1>
                <button class="reg-btn yellow pull-right">
                  Buy Coin
                </button>
                <div className="full-div double-child"> <h3>Land Price </h3> <span>50000/DGI</span></div>
                <div className="full-div double-child"> <h3>Profit </h3> <span>30%</span></div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="full-div my-info-pnl">
                  <h1 class="hedng">Stats</h1>
                  <button class="reg-btn yellow pull-right">
                    Land Location
                  </button>
                  <div className="full-div double-child">
                    <h3>Occupied Land </h3> <span>50%</span>
                  </div>
                  <div className="full-div double-child">
                    <h3>Remaining Land</h3> <span>50%</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-6">
                <div className="full-div my-info-pnl">
                  <h1 class="hedng"> My Info</h1>
                  <button class="reg-btn yellow pull-right">
                    My Land
                  </button>
                  <div className="full-div double-child">
                    <h3>Token</h3> <span>500/DGI</span>
                  </div>
                  <div className="full-div double-child">
                    <h3>Profit</h3> <span>250/DGI</span>
                  </div>

                </div>
              </div>
            </div>
          </div>
          {/* <div className=""></div> */}
        </section>
      </>
    );
  }
}

export default withRouter(LendingPage);
