import React, { Component } from "react";
import Footer from "../../containers/Footer/Footer"
import {Link} from "react-router-dom"
class PageNotFound extends Component {
  render() {
    return (
      <>
        {" "}
        <section class="short-image no-padding blog-short-title">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-lg-12 short-image-title">
                <h5 class="subtitle-margin second-color">ERROR 404</h5>
                <h1 class="second-color">Page not found</h1>
                <div class="short-title-separator"></div>
              </div>
            </div>
          </div>
        </section>
        <section class="section-light section-top-shadow">
          <div class="container">
            <div class="row">
              <div class="col-xs-12">
                <h1 class="huge-header">
                  404<span class="special-color">.</span>
                </h1>
                <h1 class="error-subtitle text-color4">Page not found</h1>

                <p class="margin-top-105 centered-text">
                  The page you are looking for might have been removed, had its
                  address changed, or become temporarily unavailable.
                </p>
                <p class="centered-text">
                  Go to our{" "}
                  <strong>
                  <Link to="/home">  HOMEPAGE</Link>
                  </strong>{" "}
                
                </p>
              </div>
            </div>
          </div>
        </section>
 
      </>
    );
  }
}

export default PageNotFound;
