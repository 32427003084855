import React, { Component } from "react";
import "./Navbar.css";
import Button from "../Button/Button.js";
import Modal from "../Modal/Modal";
import Login from "../../containers/Login/Login";
import Register from "../../containers/Register/Register";
import ResetPassword from "../../containers/ResetPassword/ResetPassword";
import Logo from "../../../assets/images/cezco-logo.png";
import { getIsLoggedIn,  setIsLoggedIn, getUserName } from "../commonFunctions/commonFunctions";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import avatar from "./../../../assets/images/avatar.jpg";
import { baseURL } from "../../../ApiURL/ApiURL";
import { getToken } from "../commonFunctions/commonFunctions";
import axios from "axios";
import { connectMetaMaskaction } from "../MetaMask/MetaMask";

class Navbar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoggedIn: false,
      profileImage: "",
      count: 0,
      ConnectWallet:""
    
    };
  }
  componentDidMount() {
let myToken=localStorage.getItem("token")
if(myToken){
  setIsLoggedIn(true)  
this.setState({ConnectWallet:localStorage.getItem("address")})
}
  // localStorage.getItem("token")?(null):(connectMetaMaskaction().then((res)=>{
  //   this.setState({ConnectWallet:res.accounts[0]})
  //   axios({
  //     method: "post",
  //     url: baseURL + "api/v1/Auth/connect",
  //     data: {
  //       address: this.state.ConnectWallet,
  //     },
  //     headers: {
  //       Authorization: `Bearer ${getToken()}`,
  //     },
  //   })
  //     .then((response) => {
  //     console.log("i am a response",response.data.data.message)
  //     window.web3.eth.personal.sign(
  //       response.data.data.message,
  //       this.state.ConnectWallet,
  //       function (err, result) {
  //           if (!err) {
  //             axios({
  //               method: "post",
  //               url: baseURL + "api/v1/Auth/validateSignature",
  //               data: {
  //                 address: response.data.data.address,
  //                 signature: result,
                
  //               },
  //               headers: {
  //                 Authorization: `Bearer ${getToken()}`,
  //               },
  //             })
  //               .then((response) => {
  //                 console.log("gooooooooood",response.data.data.token)
  //                 localStorage.setItem("token",response.data.data.token)
  //               }
  //             )
  //                   .catch((error) => {
  //                     console.log("errr is",error)
  //                   });
  //           }
  //       }
  //   );
  //         })
  //         .catch((error) => {
  //           console.log("errr is",error)
  //         });
  //    console.log("response is this",res.accounts[0])
  //  }).catch((error)=>{
  //    console.log("i am error",error)
  //  }))

    axios({
      method: "GET",
      url: baseURL + "api/v1/account/GetAccountDetails",

      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((res) => {
        this.setState({ isLoader: false });
        console.log(res);
        if (res.data.isSuccess) {
          console.log("i am image", res.data.data.profileImage)
          this.setState({ profileImage: res.data.data.profileImage });
        }
      })
      .catch((error) => {
        this.setState({ isLoader: false });
      });




  
    this.setState({ isLoggedIn:getIsLoggedIn() });
    let navbar_height;
    window.addEventListener("scroll", function () {
      if (window.scrollY > 50) {
        document.getElementById("navbar_top").classList.add("fixed-top");
        // add padding top to show content behind navbar
        navbar_height = document.querySelector(".navbar").offsetHeight;
        document.getElementById("navbar_top").style.height = "auto";
      } else {
        document.getElementById("navbar_top").classList.remove("fixed-top");
        // remove padding top from body
        document.getElementById("navbar_top").style.height = "auto";
      }
    });
  }

  setCountFromOutside = (count) => {
    this.setState({ count: count });
  };
  render() {
    return (
      <div className="header-section">
        {/* <div className="top-bar-wrapper">
          <div className="container top-bar">
            <div className="row">
              <div className="col-xs-5 col-sm-8">
                <div className="top-mail pull-left hidden-xs">
                  <span className="top-icon-circle">
                    <i className="fa fa-envelope fa-sm"></i>
                  </span>
                  <span className="top-bar-text">info@cescotech.ca</span>
                </div>
              </div>
              <div className="col-xs-7 col-sm-4">
                {this.state.isLoggedIn ? null : (
                  <div
                    className="top-social-last top-dark pull-right"
                    data-toggle="tooltip"
                    data-placement="bottom"
                    title="Login/Register"
                  >
                    <a
                      className="top-icon-circle"
                      href="#login-modal"
                      data-toggle="modal"
                    >
                      <i className="fa fa-lock"></i>
                    </a>
                  </div>
                )}

                <div className="top-social pull-right">
                  <a className="top-icon-circle" href="#">
                    <i className="fab fa-facebook"></i>
                  </a>
                </div>
                <div className="top-social pull-right">
                  <a className="top-icon-circle" href="#">
                    <i className="fab fa-twitter"></i>
                  </a>
                </div>
                <div className="top-social pull-right">
                  <a className="top-icon-circle" href="#">
                    <i className="fab fa-google-plus"></i>
                  </a>
                </div>
                <div className="top-social pull-right">
                  <a className="top-icon-circle" href="#">
                    <i className="fab fa-skype"></i>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div> */}

        <nav id="navbar_top" className="navbar navbar-expand-md main-menu-cont">
          <div className="container">
            <div className="navbar-header">
              <button
                type="button"
                className="navbar-toggle collapsed"
                data-toggle="collapse"
                data-target="#navbar"
                aria-expanded="false"
                aria-controls="navbar"
              >
                <span className="sr-only">Toggle navigation</span>
                <span className="icon-bar icon-bar1"></span>
                <span className="icon-bar icon-bar2"></span>
                <span className="icon-bar icon-bar3"></span>
              </button>
              <Link to="/home" className="navbar-brand">
                <img
                  src={Logo} alt="Dwelling Goldmine"
                />
              </Link>
            </div>
            <div id="navbar" className="navbar-collapse collapse">
              {this.state.isLoggedIn ? (
                <ul className="nav navbar-nav navbar-right  my-auto d-flex align-items-center" style={{ display: "flex", alignItems: "center" }}>
                  <li className="dropdown">
                    <Link to="/buyProperty">Buy</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/rentProperty">Rent</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/sellProperty">Sell</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/commercialProperty">Commercial</Link>
                  </li>
               
                  <li className="dropdown">
                    <Link to="/MarketPlace">MarketPlace</Link>
                  </li>
                  {/* <li className="dropdown">
                    <Link to="/Lending">Lending</Link>
                  </li> */}
                  <li className="dropdown">
                    <Link to="/wallet">Wallet</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/addproperty">Upload Property</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/myproperty">My Property</Link>
                  </li>
                  <li>
                    <a
                      href="#"
                      className="dropdown-toggle"
                      data-toggle="dropdown"
                      role="button"
                      aria-haspopup="true"
                      aria-expanded="false"
                    >
                      {/* {getUserName()} */}
                      {/* {this.props.username === undefined
                        ? getUserName()
                        : this.props.username} */}
                      {
                        this.state.profileImage?
                        <img src={baseURL + this.state.profileImage} className="avatar" alt="pic" />: <img src={avatar} className="avatar" alt="pic" />
                      }
                    </a>
                    <ul className="dropdown-menu">
                      <li>
                        <Link to="/profile">Profile</Link>
                      </li>
                      <li className="dropdown">
                        <Link to="/offers">
                          Offers{" "}
                          {console.log(
                            "this is state value of count",
                            this.state.count
                          )}
                          {this.state.count === 0 ? null : (
                            <span className="notification-alert">
                              {this.state.count}
                            </span>
                          )}
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="/kyc">KYC</Link>
                      </li>
                      <li>
                        <Link to="/changepassword">Change Password</Link>
                      </li> */}
                      <li>
                        <a
                          className="logout"
                          onClick={() => {
                            localStorage.clear();
                            window.location.reload(true);
                          }}
                        >
                          Logout{" "}
                        </a>
                      </li>
                    </ul>
                  </li>
                </ul>
              ) : (
                <ul className="nav navbar-nav navbar-right">
                   <li className="dropdown">
                    <Link to="/buyProperty">Buy</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/rentProperty">Rent</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/sellProperty">Sell</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/commercialProperty">Commercial</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/financingProperty">Financing</Link>
                  </li>
                  <li className="dropdown">
                    <Link to="/MarketPlace">Marketplace</Link>
                  </li>
                  {/* <li className="dropdown">
                    <Link to="/Lending">Lending</Link>
                  </li> */}
                  <li className="dropdown">
                    <Link to="/contactus">Contact Us</Link>
                  </li>
                          
               {/* {
                
                this.state.ConnectWallet!==""? (<li className="dropdown">
                <a>{this.state.ConnectWallet.slice(0,10)}</a>
              </li>):(<li className="dropdown">
                 <a onClick={()=>connectMetaMaskaction().then((res)=>{
                  localStorage.setItem("address",res.accounts[0])
                  this.setState({ConnectWallet:res.accounts[0]})
                  axios({
                    method: "post",
                    url: baseURL + "api/v1/Auth/connect",
                    data: {
                      address: this.state.ConnectWallet,
                    },
                    headers: {
                      Authorization: `Bearer ${getToken()}`,
                    },
                  })
                    .then((response) => {
                      setIsLoggedIn(true)
                    console.log("i am a response",response.data.data.message)
                    window.web3.eth.personal.sign(
                      response.data.data.message,
                      this.state.ConnectWallet,
                      function (err, result) {
                          if (!err) {
                            axios({
                              method: "post",
                              url: baseURL + "api/v1/Auth/validateSignature",
                              data: {
                                address: response.data.data.address,
                                signature: result,
                              
                              },
                              headers: {
                                Authorization: `Bearer ${getToken()}`,
                              },
                            })
                              .then((response) => {
                                setIsLoggedIn(true)
                                window.location.reload()
                                console.log("gooooooooood",response.data.data.token)
                                localStorage.setItem("token",response.data.data.token)
                              }
                            )
                                  .catch((error) => {
                                    console.log("errr is",error)
                                  });
                          }
                      }
                  );
                        })
                        .catch((error) => {
                          console.log("errr is",error)
                        });
                   console.log("response is this",res.accounts[0])
                 }).catch((error)=>{
                   console.log("i am error",error)
                 })} style={{cursor:"pointer"}}>Connect Wallet</a>
               </li>)
               } */}
                  <li className="dropdown">
                    <Link to="#login-modal"
                      data-toggle="modal">Login</Link>
                  </li>
                </ul>
              )}
            </div>
          </div>
        </nav>
        {/* Login Modal */}

        <Modal modalID="login-modal" children={<Login />} />
        {/* Registeration modal */}
        <Modal modalID="registeration" children={<Register />} />

        {/* Forgot password Modal */}

        <Modal modalID="resetpassword" children={<ResetPassword />} />
      </div>
    );
  }
}

export default Navbar;
