//URL FOR MAIN CESCO

// export const baseURL = "https://api.cescotech.ca/"
// export const baseURLImages= "https://api.cescotech.ca/"
//  export const GoogleMapKey="AIzaSyAXerWFckDoBrseHjOzs4-FsXlORGcK1FY"


//URL FOR TESTING
export const baseURLImages= "https://goldmine-api.ibt-learning.com/"
export const baseURL = "https://goldmine-api.ibt-learning.com/"
export const GoogleMapKey="AIzaSyCejs0_ZPwic3PyACWTB999tQxwI7nTpzw"