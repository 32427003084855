import React from "react";
import "./Modal.css";
const Modal = (props) => {
  return (
    <div
      className="modal apartment-modal"
      id={props.modalID}
      tabindex="-1"
      aria-labelledby={props.modalID}
      aria-hidden="true"
      role="dialog"
    >
      <div className="modal-dialog mb-0">
        <div className="modal-content">
        
          {[props.children]}
        </div>
      </div>
    </div>
  );
};

export default Modal;
