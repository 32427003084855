export const setToken = (value) => {
  localStorage.setItem("token", value);
};
export const getToken = () => {
  return localStorage.getItem("token");
};
export const setUserName = (value) => {
  localStorage.setItem("userName", value);
};
export const getUserName = () => {
  return localStorage.getItem("userName");
};
export const setIsLoggedIn = (value) => {
  localStorage.setItem("isLoggedIn", value);
};
export const getIsLoggedIn = () => {
  return localStorage.getItem("isLoggedIn");
};

export const openModal = (value) => {
  document.getElementById(`${value}`).classList.add("in");
  document.getElementById(`${value}`).style.display = "block";
};

export const closeModal = (value) => {
  document.getElementById(`${value}`).classList.remove("in");
  document.getElementById(`${value}`).style.display = "none";
  console.log("close mdal called");
};

export const getIsLoader = () => {
  return localStorage.getItem("isLoader");
};

export const setIsLoader = (value) => {
  return localStorage.setItem("isLoader", value);
};

export const disableScroll=()=> {
  const body = document.querySelector('body');
    body.style.overflow = 'hidden' 
}
export const enableScroll=()=>{
  const body = document.querySelector('body');
  body.style.overflow = 'auto' 
}

export const counter=(object)=>{
  let count = 0;
  object.map((item) => {
    return item.offerChatStatus.filter(
      (item) => localStorage.getItem("email") == item.account.email
    )[0].unseenMessages > 0
      ? count++
      : null;
  });
  return count
}

export const isMyProperty=(value)=>{
  if(value===localStorage.getItem("email")){
  return true
  }else{
return false
  }
}