import React from "react";
import "./Button.css";

const Button = (props) => {

  return (
    <button
      className="button-primary button-shadow button-full"
     
      disabled={props.disableButton}
    >
      <span>{props.buttonName}</span>
      <div className="button-triangle"></div>
      <div className="button-triangle2"></div>
      <div className="button-icon">
        <i className="fa fa-user"></i>
      </div>
    </button>
  );
};

export default Button;
