import React, { Component } from "react";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import Room from "../../../assets/images/rooms-icon.png";
import Bath from "../../../assets/images/bathrooms-icon.png";
import Area from "../../../assets/images/area-icon.png";
import Grid from "../../../assets/images/grid-icon.png";
import ListIcon from "../../../assets/images/list-icon.png";
import DefaultImage from "../../../assets/images/grid-offer1.jpg";
import { withRouter, Link } from "react-router-dom";
import Pagination from "./../../common/Pagination/Pagination";
import queryString from "query-string";
import GoogleMapReact from "google-map-react";
import {
  disableScroll,
  enableScroll,
  getToken,
} from "../../common/commonFunctions/commonFunctions";
import Map from "../Test/Map";
import MyGoogleMap from "../MyGoogleMap/MyGoogleMap";
import { toast } from "react-toastify";
import NewGoogleMap from "../NewGoogleMap/NewGoogleMap";
import "react-toastify/dist/ReactToastify.css";
import "./Map.css";

const AnyReactComponent = ({ text }) => <div className="pin1"></div>;
const PropertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
];
class MarketPlace extends Component {
  constructor(props) {
    super(props);
    this.state = {
      Listings: [],
      totalItems: "",
      list: "list",
      isLoader: false,
      currentPage: 1,
      pageSize: 4,
      paginationPages: 0,
      paginationCurrentPage: 0,
      orderByFilter: "",
      favourit: false,
      favouritColor: "black",
      myFavouritProperty: [],
      latitude: 37.0902,
      longitude: 95.7129,
    };
  }

  componentDidMount() {
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        console.log("my favourite property ", response.data.data);
        this.setState({ myFavouritProperty: response.data.data });
      })
      .catch((error) => {
        console.log("errrrrrrrrrrr", error);
      });
    var TransactionType = this.props.location.state?.TransactionType;
    var cityArea = this.props.location.state?.area;
    var myLatitude = this.props.location.state?.latitude;
    var myLngitude = this.props.location.state?.longitude;
    this.setState({ latitude: parseFloat(myLatitude) });
    this.setState({ longitude: parseFloat(myLngitude) });
    console.log("name===", TransactionType);
    console.log("latt===", myLatitude);
    console.log("lnnng===", myLngitude);
    var url = this.props.location.search;
    var params = queryString.parse(url);
    if (url == "") {
      disableScroll();
      this.setState({ isLoader: true });

      if (TransactionType != null) {
        axios({
          method: "get",
          url:
            baseURL +
            "api/v1/Property/get_listings?" +
            "TransactionType=" +
            TransactionType +
            "&cityArea=" +
            cityArea,
        })
          .then((response) => {
            console.log(response);
            enableScroll();
            console.log(
              "pageNumber",
              Math.ceil(response.data.totalItems / response.data.pageSize)
            );
            this.setState(
              {
                totalItems: response.data.totalItems,
                Listings: response.data.data,
                isLoader: false,
                paginationPages: Math.ceil(
                  response.data.totalItems / response.data.pageSize
                ),
                paginationCurrentPage: response.data.currentPage,
              },
              () => { }
            );

            console.log("First state data");
          })
          .catch((error) => {
            enableScroll();
            this.setState({ isLoader: false });
          });
      } else {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/get_listings",
        })
          .then((response) => {
            console.log(response);
            enableScroll();
            console.log(
              "pageNumber",
              Math.ceil(response.data.totalItems / response.data.pageSize)
            );
            this.setState(
              {
                totalItems: response.data.totalItems,
                Listings: response.data.data,
                isLoader: false,
                paginationPages: Math.ceil(
                  response.data.totalItems / response.data.pageSize
                ),
                paginationCurrentPage: response.data.currentPage,
              },
              () => { }
            );
          })
          .catch((error) => {
            enableScroll();
            this.setState({ isLoader: false });
          });
      }
    } else {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          "Country=" +
          params.Country +
          "&City=" +
          params.City +
          "&TransactionType=" +
          params.TransactionType +
          "&PropertyTypeID=" +
          params.PropertyTypeID +
          "&MaximumAmount=" +
          params.MaximumAmount +
          "&MinimumAmount=" +
          params.MinimumAmount +
          "&Kitchen=" +
          params.Kitchen +
          "&Bedroom=" +
          params.Bedroom +
          "&Bathroom=" +
          params.Bathroom +
          "&PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          this.state.currentPage,
      })
        .then((response) => {
          enableScroll();
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => { });
    }
  }

  favouritProperty = (id) => {
    toast.success("Added in favourite");
    axios({
      method: "post",
      url: baseURL + "api/v1/Property/AddFavouriteProperty",
      data: {
        propertyId: id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/get_listings",
        })
          .then((response) => {
            this.setState(
              {
                Listings: response.data.data,
              },
              () => { }
            );
          })
          .catch((error) => { });
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("my favourite property ", response.data.data);
            this.setState({ myFavouritProperty: response.data.data });
          })
          .catch((error) => {
            console.log("errrrrrrrrrrr", error);
          });

        console.log("remove");
        this.setState({ favouritColor: "red" });
      })
      .catch((error) => {
        console.log("errrrrrrr", error);
      });
  };
  removeFavouritProperty = (id) => {
    toast.error("Removed from favourite");
    axios({
      method: "put",
      url: baseURL + "api/v1/Property/RemoveFavouriteProperty",
      data: {
        propertyId: id,
      },
      headers: {
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        axios({
          method: "get",
          url: baseURL + "api/v1/Property/get_listings",
        })
          .then((response) => {
            this.setState(
              {
                Listings: response.data.data,
              },
              () => { }
            );
          })
          .catch((error) => { });

        axios({
          method: "get",
          url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
          headers: {
            Authorization: `Bearer ${getToken()}`,
          },
        })
          .then((response) => {
            console.log("my favourite property ", response.data.data);
            this.setState({ myFavouritProperty: response.data.data });
          })
          .catch((error) => {
            console.log("errrrrrrrrrrr", error);
          });

        console.log("remove");
        this.setState({ favouritColor: "red" });
      })
      .catch((error) => {
        console.log("errrrrrrr", error);
      });
  };

  onHandleGridClick = () => {
    this.setState({
      list: "grid",
    });
  };
  onHandleListClick = () => {
    this.setState({
      list: "list",
    });
  };
  handlePageNumber = (value) => {
    var url = this.props.location.search;
    var params = queryString.parse(url);

    if (url == "") {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          this.state.orderByFilter +
          "PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          value,
      })
        .then((response) => {
          enableScroll();
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    } else {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          this.state.orderByFilter +
          "Country=" +
          params.Country +
          "&City=" +
          params.City +
          "&TransactionType=" +
          params.TransactionType +
          "&PropertyTypeID=" +
          params.PropertyTypeID +
          "&MaximumAmount=" +
          params.MaximumAmount +
          "&MinimumAmount=" +
          params.MinimumAmount +
          "&Kitchen=" +
          params.Kitchen +
          "&Bedroom=" +
          params.Bedroom +
          "&Bathroom=" +
          params.Bathroom +
          "&PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          value,
      })
        .then((response) => {
          enableScroll();
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    }
  };
  firstPage = () => {
    this.handlePageNumber(1);
  };
  lastPage = () => {
    this.handlePageNumber(this.state.paginationPages);
  };
  componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    const {
      location: { search: prevSearch },
    } = prevProps;

    if (search !== prevSearch) {
      if (search == "") {
        this.setState({ isLoader: true });

        axios({
          method: "get",
          url:
            baseURL +
            "api/v1/Property/get_listings?" +
            "&PageSize=" +
            this.state.pageSize +
            "&CurrentPage=" +
            this.state.currentPage,
        })
          .then((response) => {
            enableScroll();
            console.log("pageNumber", response);
            this.setState(
              {
                totalItems: response.data.totalItems,
                Listings: response.data.data,
                isLoader: false,
                paginationPages: Math.ceil(
                  response.data.totalItems / response.data.pageSize
                ),
                paginationCurrentPage: response.data.currentPage,
              },
              () => { }
            );
          })
          .catch((error) => {
            enableScroll();
            this.setState({ isLoader: false });
          });
      } else {
        var url = this.props.location.search;
        var params = queryString.parse(url);

        this.setState({ isLoader: true });
        axios({
          method: "get",
          url:
            baseURL +
            "api/v1/Property/get_listings?" +
            "Country=" +
            params.Country +
            "&City=" +
            params.City +
            "&TransactionType=" +
            params.TransactionType +
            "&PropertyTypeID=" +
            params.PropertyTypeID +
            "&MaximumAmount=" +
            params.MaximumAmount +
            "&MinimumAmount=" +
            params.MinimumAmount +
            "&Kitchen=" +
            params.Kitchen +
            "&Bedroom=" +
            params.Bedroom +
            "&Bathroom=" +
            params.Bathroom +
            "&PageSize=" +
            this.state.pageSize +
            "&CurrentPage=" +
            this.state.currentPage,
        })
          .then((response) => {
            enableScroll();
            this.setState(
              {
                totalItems: response.data.totalItems,
                Listings: response.data.data,
                isLoader: false,
                paginationPages: Math.ceil(
                  response.data.totalItems / response.data.pageSize
                ),
                paginationCurrentPage: response.data.currentPage,
              },
              () => { }
            );
          })
          .catch((error) => {
            enableScroll();
            this.setState({ isLoader: false });
          });
      }
    }
  }
  handleButton = (e) => {
    this.props.history.push({
      pathname: "/listingdetail",
      search: "selectedList=" + e,
    });
  };

  onChangeOrderBy = (event) => {
    var url = this.props.location.search;
    var params = queryString.parse(url);
    this.setState({ [event.target.name]: event.target.value });
    if (url == "") {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          event.target.value +
          "&PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          this.state.currentPage,
      })
        .then((response) => {
          console.log(response);
          enableScroll();
          console.log(
            "pageNumber",
            Math.ceil(response.data.totalItems / response.data.pageSize)
          );
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    } else {
      disableScroll();
      this.setState({ isLoader: true });
      axios({
        method: "get",
        url:
          baseURL +
          "api/v1/Property/get_listings?" +
          event.target.value +
          "Country=" +
          params.Country +
          "&City=" +
          params.City +
          "&TransactionType=" +
          params.TransactionType +
          "&PropertyTypeID=" +
          params.PropertyTypeID +
          "&MaximumAmount=" +
          params.MaximumAmount +
          "&MinimumAmount=" +
          params.MinimumAmount +
          "&Kitchen=" +
          params.Kitchen +
          "&Bedroom=" +
          params.Bedroom +
          "&Bathroom=" +
          params.Bathroom +
          "&PageSize=" +
          this.state.pageSize +
          "&CurrentPage=" +
          this.state.currentPage,
      })
        .then((response) => {
          enableScroll();
          this.setState(
            {
              totalItems: response.data.totalItems,
              Listings: response.data.data,
              isLoader: false,
              paginationPages: Math.ceil(
                response.data.totalItems / response.data.pageSize
              ),
              paginationCurrentPage: response.data.currentPage,
            },
            () => { }
          );
        })
        .catch((error) => {
          enableScroll();
          this.setState({ isLoader: false });
        });
    }
  };
  render() {
    return (
      <>
        <div className={this.state.isLoader ? "loader-bg" : ""}></div>
        <section className="section-light list-section section-top-shadow listing">
          <div className="listing-map-container">
            <div className="listing-map-container-inner">
              <a className="save-search" href="#">
                Save Search
              </a>
              {console.log("Listing Data", this.state.Listings)}
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d86094.71198750017!2d-122.22117104328487!3d47.59774939291554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906bcfa3a66041%3A0xbacf5482ead00765!2sBellevue%2C%20WA%2C%20USA!5e0!3m2!1sen!2s!4v1643263892799!5m2!1sen!2s" allowfullscreen="" loading="lazy"></iframe> */}
              {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d86094.71198750017!2d-122.22117104328487!3d47.59774939291554!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x54906bcfa3a66041%3A0xbacf5482ead00765!2sBellevue%2C%20WA%2C%20USA!5e0!3m2!1sen!2s!4v1643263821012!5m2!1sen!2s" ></iframe> */}
              {/* <iframe src="AIzaSyCJ4Vvcw5iAbxJoX-XDKzauIVO7TJHxaEM" ></iframe> */}
              {/* <MyGoogleMap /> */}
              <NewGoogleMap
                searchedCenterLagLng={{
                  searchedLat: this.state.latitude,
                  searchedLng: this.state.longitude,
                }}
                filteredList={this.state.Listings}
              />
            </div>
          </div>
          <div className="list-right-container">
            <div className="row">
              <div className="col-xs-12 col-lg-12">
                <h1>
                  {/* {this.state.totalItems} estates found */}
                  Location For Sale
                </h1>
              </div>
              <div className="col-lg-12 col-sm-12 col-md-12">
                {/* <div className="order-by-container">
                  <select
                    name="orderByFilter"
                    className="bootstrap-select select-search-list btn-group"
                    title="Order By:"
                    onChange={this.onChangeOrderBy}
                  >
                    <option>For Sale</option>
                    <option value="Price=DESC&">Price high to low</option>
                    <option value="Price=ASC&">Price low to high</option>
                    <option value="SqFootage=DESC&">
                      Square Footage high to low
                    </option>
                    <option value="SqFootage=ASC&">
                      Square Footage low to high
                    </option>
                    <option value="Date=DESC&"> Latest </option>
                    <option value="Date=ASC&">Oldest</option>
                  </select>
                </div> */}
                {/* <div className="order-by-container">
                  <select
                    name="orderByFilter"
                    className="bootstrap-select select-search-list btn-group"
                    title="Order By:"
                    onChange={this.onChangeOrderBy}
                  >
                    <option>Price</option>
                    <option value="Price=DESC&">Price high to low</option>
                    <option value="Price=ASC&">Price low to high</option>
                    <option value="SqFootage=DESC&">
                      Square Footage high to low
                    </option>
                    <option value="SqFootage=ASC&">
                      Square Footage low to high
                    </option>
                    <option value="Date=DESC&"> Latest </option>
                    <option value="Date=ASC&">Oldest</option>
                  </select>
                </div>
                <div className="order-by-container">
                  <select
                    name="orderByFilter"
                    className="bootstrap-select select-search-list btn-group"
                    title="Order By:"
                    onChange={this.onChangeOrderBy}
                  >
                    <option>Home Type</option>
                    <option value="Price=DESC&">Price high to low</option>
                    <option value="Price=ASC&">Price low to high</option>
                    <option value="SqFootage=DESC&">
                      Square Footage high to low
                    </option>
                    <option value="SqFootage=ASC&">
                      Square Footage low to high
                    </option>
                    <option value="Date=DESC&"> Latest </option>
                    <option value="Date=ASC&">Oldest</option>
                  </select>
                </div> */}
                <div className="order-by-container">
                  <select
                    name="orderByFilter"
                    className="bootstrap-select select-search-list btn-group"
                    title="Order By:"
                    onChange={this.onChangeOrderBy}
                  >
                    <option>All Filters</option>
                    <option value="Price=DESC&">Price high to low</option>
                    <option value="Price=ASC&">Price low to high</option>
                    <option value="SqFootage=DESC&">
                      Square Footage high to low
                    </option>
                    <option value="SqFootage=ASC&">
                      Square Footage low to high
                    </option>
                    <option value="Date=DESC&"> Latest </option>
                    <option value="Date=ASC&">Oldest</option>
                  </select>
                </div>
              </div>
              <div className="col-xs-12">
                {this.state.Listings.length === 0 ? (
                  <h1 className="text-center mt-5">no data found</h1>
                ) : (
                  ""
                )}
                {/* <div className="row">
                   <div className="col-xs-12 col-lg-12">
                     <div className="view-icons-container">
                      <a
                        className={
                          this.state.list === "list"
                            ? "view-box view-box-active"
                            : "view-box"
                        }
                        onClick={() => this.onHandleListClick()}
                      >
                        <img src={Grid} alt="" />
                      </a>
                      <a
                        className={
                          this.state.list === "grid"
                            ? "view-box view-box-active"
                            : "view-box"
                        }
                        onClick={() => this.onHandleGridClick()}
                      >
                        <img src={ListIcon} alt="" />
                      </a>
                    </div> 
                  </div>
                </div> */}
                {this.state.list === "list" ? (
                  <div className="row grid-offer-row">
                    {/* <i class="fa fa-heart" style={{color:`${this.state.favouritColor}`}}  onClick={()=>this.favouritProperty(2)} ></i> */}
                    {this.state.Listings == null ||
                      this.state.Listings === undefined ||
                      this.state.Listings.map((list) => (
                        <div className="col-xs-6 col-sm-12 col-md-12 col-lg-6 grid-offer-col">
                          <div className="grid-offer">
                            <div className="grid-offer-front">
                              <div
                                className="grid-offer-photo"
                                onClick={() => this.handleButton(list.uuid)}
                              >
                                {list.propertyMedia === null ? (
                                  <img height="177px" src={DefaultImage} />
                                ) : list.propertyMedia.filter(
                                  (item) => item.isThumbnail === true
                                ).length <= 0 ? (
                                  <img
                                    height="177px"
                                    src={
                                      baseURL +
                                      list.propertyMedia.filter(
                                        (item) => item.isThumbnail === false
                                      )[0]?.mediaUrl
                                    }
                                  />
                                ) : list.propertyMedia.filter(
                                  (item) => item.isThumbnail === true
                                ).length > 1 ? (
                                  <img
                                    height="177px"
                                    src={
                                      baseURL +
                                      list.propertyMedia.filter(
                                        (item) => item.isThumbnail === true
                                      )[0]?.mediaUrl
                                    }
                                  />
                                ) : (
                                  list.propertyMedia
                                    .filter((item) => item.isThumbnail === true)
                                    .map((data) => {
                                      return list.thumbnail === true ? null : (
                                        <img
                                          height="177px"
                                          src={baseURL + data.mediaUrl}
                                        />
                                      );
                                    })
                                )}
                                <div className="type-container">
                                  <div className="estate-type reg-btn black">
                                    {PropertyType.filter(
                                      (item) => item.id === list.propertyTypeId
                                    ).map((id) => id.PropertyTypeName)}
                                  </div>
                                  <div className="transaction-type reg-btn yellow">
                                    {list.propertyStatus}
                                  </div>
                                </div>
                              </div>
                              <div className="grid-offer-text">
                                <div className="wrap-address">
                                  <div className="grid-offer-price">
                                    $ {list.price.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                                    {this.state.myFavouritProperty.some(
                                      (myfav) => {
                                        return myfav.id == list.id;
                                      }
                                    ) ? (
                                      <i
                                        class="fa fa-heart"
                                        style={{ color: "gold" }}
                                        onClick={() =>
                                          this.removeFavouritProperty(list.id)
                                        }
                                      >
                                        {list.propertyFavouritesCount}
                                      </i>
                                    ) : (
                                      <i
                                        class="fa fa-heart"
                                        style={{ color: "grey" }}
                                        onClick={() => {
                                          this.favouritProperty(list.id);
                                        }}
                                      >
                                        {list.propertyFavouritesCount}
                                      </i>
                                    )}
                                  </div>
                                  <div className="grid-offer-h4">
                                    <p>
                                      {list.bedrooms}-Beds {list.fullBaths}-Baths{" "}
                                      {list.propertyArea}-Sq.Ft
                                    </p>
                                    <h4 className="grid-offer-title">juj{list.discription}</h4>
                                  </div>
                                </div>
                                <div className="clearfix"></div>
                                <p className="description-p">
                                  <i className="fa fa-map-marker"></i>{" "}
                                  {list.locationAddress}
                                </p>
                                <div className="clearfix"></div>
                              </div>

                              {/* <div className="grid-offer-params">
                                <div className="grid-area">
                                  <img src={Area} alt="" />
                                  {list.propertyArea}
                                  <sup>2</sup>
                                </div>
                                <div className="grid-rooms">
                                  <img src={Room} alt="" />
                                  {list.bedrooms}
                                </div>
                                <div className="grid-baths">
                                  <img src={Bath} alt="" />
                                  {list.fullBaths}
                                </div>
                              </div> */}
                            </div>
                            {/* <div className="grid-offer-back">
                              <GoogleMapReact
                                bootstrapURLKeys={{
                                  key: "AIzaSyCejs0_ZPwic3PyACWTB999tQxwI7nTpzw",
                                }}
                                defaultCenter={{
                                  lat: list.latitude,
                                  lng: list.longitude,
                                }}
                                defaultZoom={20}
                              >
                                <AnyReactComponent
                                  lat={list.latitude}
                                  lng={list.longitude}
                                  text="My Marker"
                                />
                              </GoogleMapReact>
                              <div className="button">
                                <a
                                  className="button-primary"
                                  onClick={() => this.handleButton(list.uuid)}
                                >
                                  <span>read more</span>
                                  <div className="button-triangle"></div>
                                  <div className="button-triangle2"></div>
                                  <div className="button-icon">
                                    <i className="fa fa-search"></i>
                                  </div>
                                </a>
                              </div>
                            </div> */}
                          </div>
                        </div>
                      ))}
                  </div>
                ) : (
                  <div className="row list-offer-row">
                    {this.state.Listings == null ||
                      this.state.Listings.map((list) => (
                        <div className="col-xs-12">
                          <div className="list-offer">
                            <div className="list-offer-left">
                              <div className="list-offer-front">
                                <div className="list-offer-photo">
                                  {list.propertyMedia === null ? (
                                    <img height="177px" src={DefaultImage} />
                                  ) : list.propertyMedia.filter(
                                    (item) => item.isThumbnail === true
                                  ).length <= 0 ? (
                                    <img
                                      height="177px"
                                      src={
                                        baseURL +
                                        list.propertyMedia.filter(
                                          (item) => item.isThumbnail === false
                                        )[0].mediaUrl
                                      }
                                    />
                                  ) : list.propertyMedia.filter(
                                    (item) => item.isThumbnail === true
                                  ).length >= 1 ? (
                                    <img
                                      height="177px"
                                      src={
                                        baseURL +
                                        list.propertyMedia.filter(
                                          (item) => item.isThumbnail === true
                                        )[0].mediaUrl
                                      }
                                    />
                                  ) : (
                                    list.propertyMedia
                                      .filter(
                                        (item) => item.isThumbnail === true
                                      )
                                      .map((data) => {
                                        return list.thumbnail ===
                                          true ? null : (
                                          <img
                                            height="177px"
                                            src={baseURL + data.mediaUrl}
                                          />
                                        );
                                      })
                                  )}
                                  <div className="type-container">
                                    <div className="estate-type">
                                      {" "}
                                      {PropertyType.filter(
                                        (item) =>
                                          item.id === list.propertyTypeId
                                      ).map((id) => id.PropertyTypeName)}
                                    </div>
                                    <div className="transaction-type">
                                      {" "}
                                      {list.propertyStatus}
                                    </div>
                                  </div>
                                </div>
                                <div className="list-offer-params">
                                  <div className="list-area">
                                    <img src={Area} alt="" />
                                    {list.propertyArea}
                                    <sup>2</sup>
                                  </div>
                                  <div className="list-rooms">
                                    <img src={Room} alt="" />
                                    {list.bedrooms}
                                  </div>
                                  <div className="list-baths">
                                    <img src={Bath} alt="" />
                                    {list.fullBaths}
                                  </div>
                                </div>
                              </div>
                              <div className="list-offer-back">
                                <GoogleMapReact
                                  bootstrapURLKeys={{
                                    key: "AIzaSyCejs0_ZPwic3PyACWTB999tQxwI7nTpzw",
                                  }}
                                  defaultCenter={{
                                    lat: list.latitude,
                                    lng: list.longitude,
                                  }}
                                  defaultZoom={20}
                                >
                                  <AnyReactComponent
                                    lat={list.latitude}
                                    lng={list.longitude}
                                    text="My Marker"
                                  />
                                </GoogleMapReact>
                              </div>
                            </div>
                            <a className="list-offer-right">
                              <div className="list-offer-text">
                                <i className="fa fa-map-marker list-offer-localization hidden-xs"></i>
                                <div className="list-offer-h4">
                                  <a
                                    className="location-title"
                                    onClick={() => this.handleButton(list.uuid)}
                                  >
                                    {" "}
                                    <h4 className="list-offer-title">
                                      {list.locationAddress}
                                    </h4>
                                  </a>
                                </div>
                                <div className="clearfix"></div>
                                <p className=""> {list.discription}</p>
                                <div className="clearfix"></div>
                              </div>
                              <div className="price-list-cont">
                                <div className="list-price">$ {list.price}</div>
                              </div>
                            </a>
                            <div className="clearfix"></div>
                          </div>

                          <div className="clearfix"></div>
                        </div>
                      ))}{" "}
                  </div>
                )}
              </div>
            </div>
          </div>
        </section>

        {/* <Pagination
          onPageNumberChild={this.handlePageNumber}
          pageNumber={this.state.paginationPages}
          currentPage={this.state.paginationCurrentPage}
          firstPage={this.firstPage}
          lastPage={this.lastPage}
        /> */}
      </>
    );
  }
}

export default withRouter(MarketPlace);
