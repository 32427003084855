import { render } from "@testing-library/react";
import React, { Component } from "react";
import AreaIcon from "../../../assets/images/area-icon.png";
import BathroomIcon from "../../../assets/images/bathrooms-icon.png";
import RoomIcon from "../../../assets/images/rooms-icon.png";

import ButtonSubmit from "../Button/ButtonSubmit";
import { withRouter } from "react-router-dom";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import { getToken } from "../commonFunctions/commonFunctions";
import { toast } from "react-toastify";
// import GoogleMapReact from "google-map-react";
// import { GoogleMapKey } from "../../../ApiURL/ApiURL";
const AnyReactComponent = ({ text }) => <div className="pin1"></div>;
class HomeFooterSlider extends Component {
  state = {
    Listings: [],
    itemID: false,
  };

  constructor(props) {
    super(props);
    this.state = {
      center: {
        lat: 59.95,
        lng: 30.33,
      },

      Listings: [],
      condition: false,
    };
  }

  // updateCondition = () => {
  //   this.setState({ condition: true });
  // };

  // componentDidMount() {
  //   if (this.state.condition) {
  //     this.updateCondition();
  //     axios({
  //       method: "get",
  //       url: baseURL + "api/v1/Property/get_listings",
  //     })
  //       .then((response) => {
  //         console.log("my ressssssss", response);
  //         this.setState({
  //           Listings: response.data.data,
  //         });
  //       })
  //       .catch((error) => {});
  //     console.log("hye i am list ", this.state.Listings);
  //     console.log("id is ", this.props.id);
  //     axios({
  //       method: "get",
  //       url: baseURL + "api/v1/Property/GetMyFavouriteProperty",
  //       headers: {
  //         Authorization: `Bearer ${getToken()}`,
  //       },
  //     })
  //       .then((response) => {
  //         console.log("my favourite property ", response.data.data);
  //         this.setState({ myFavouritProperty: response.data.data });
  //       })
  //       .catch((error) => {
  //         console.log("errrrrrrrrrrr", error);
  //       });
  //   }
  // }

  handleButton = (e) => {
    this.props.history.push({
      pathname: "/listingdetail",
      search: "selectedList=" + e,
    });
    this.props.handleButton();
  };

  render() {
    return (
      <div className="grid-offer-col listing">
        <div className="grid-offer">
          <div className="grid-offer-front">
            <div className="grid-offer-photo sm">
              {this.props.ImageURL}
              <div className="type-container">
                <div className="estate-type reg-btn black">
                  {this.props.PropertyType}
                </div>
                <div className="transaction-type reg-btn yellow">
                  {this.props.TransactionType}
                </div>
              </div>
            </div>
            <div className="grid-offer-text">
              <div className="wrap-address">
                <div className="grid-offer-price">
                  $ {this.props.Price.toLocaleString(undefined, { maximumFractionDigits: 2 })}
                  {this.props.myFavouritProperty.some((myfav) => {
                    return myfav.id == this.props.id;
                  }) ? (
                    <i
                      class="fa fa-heart"
                      onClick={() =>
                        this.props.removeFavouritProperty(this.props.id)
                      }
                      style={{ color: "gold" }}
                    >
                      {this.props.ListingData.map((myItem) => {
                        if (myItem.id == this.props.id)
                          return <span>{myItem.propertyFavouritesCount}</span>;
                      })}
                    </i>
                  ) : (
                    <i
                      class="fa fa-heart"
                      style={{ color: "grey" }}
                      onClick={() => this.props.favouritProperty(this.props.id)}
                    >
                      {this.props.ListingData.map((myItem) => {
                        if (myItem.id == this.props.id)
                          return <span>{myItem.propertyFavouritesCount}</span>;
                      })}
                    </i>
                  )}
                </div>
                <div className="grid-offer-h4">
                  <p>
                    {this.props.Bedrooms}-Beds {this.props.Bath}-Baths{" "}
                    {this.props.PropertyArea}-Sq.Ft
                  </p>
                  <h4 className="grid-offer-title">{this.props.Description}</h4>
                </div>
                {/* <div className="grid-offer-h4">
                  <h4 className="grid-offer-title">
                    {this.props.LocalAddress}
                  </h4>
                </div> */}
              </div>
              <div className="clearfix"></div>
              <p className="description-p">
                <i className="fa fa-map-marker"></i> {this.props.LocalAddress}
              </p>
              <div className="clearfix"></div>
            </div>

            {/* <div className="grid-offer-params">
              <div className="grid-area">
                <img height="20px" width="20px" src={AreaIcon} alt="" />
                {this.props.PropertyArea}
                <sup>2</sup>
              </div>
              <div className="grid-rooms">
                <img height="20px" width="20px" src={RoomIcon} alt="" />
                {this.props.Bedrooms}
              </div>
              <div className="grid-baths">
                <img height="20px" width="20px" src={BathroomIcon} alt="" />
                {this.props.Bath}
              </div>
            </div> */}
          </div>
          {/* <div className="grid-offer-back">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: GoogleMapKey,
              }}
              defaultCenter={{
                lat: this.props.latitude,
                lng: this.props.longitude,
              }}
              defaultZoom={20}
            >
              <AnyReactComponent
                lat={this.props.latitude}
                lng={this.props.longitude}
                text="My Marker"
              />
            </GoogleMapReact>
            <div className="button">
              <a href="" className="button-primary">
                <span>read more</span>
                <div className="button-triangle"></div>
                <div className="button-triangle2"></div>
                <div className="button-icon">
                  <i className="fa fa-search"></i>
                </div>
              </a>
            </div>

            <div className="button">
              <ButtonSubmit
                buttonName="Read More"
                handleButton={() => this.handleButton(this.props.uuid)}
              />
            </div>
          </div> */}
        </div>
      </div>
    );
  }
}
export default withRouter(HomeFooterSlider);
