import React, { Component } from "react";
import queryString from "query-string";
import axios from "axios";
import { baseURL, baseURLImages, GoogleMapKey } from "../../../ApiURL/ApiURL";
import NewListing from "../NewListing/NewListing";
import GoogleMapReact from "google-map-react";
import { withRouter } from "react-router-dom";
import ImageDefault from "../../../assets/images/bg-pattern12.jpg";
import homeicon from "../../../assets/images/home-icon.png";
import userimg from "../../../assets/images/user-img.png";
import mobileicon from "../../../assets/images/mobile-icon.png";
import charticon from "../../../assets/images/chart-icon.png";
import mapimage from "../../../assets/images/mapimage.png";
import { Swiper, SwiperSlide } from "swiper/react";
import { Link } from "react-router-dom";
import "swiper/swiper-bundle.css";
import DataTable from "react-data-table-component";
import ListBanner from "../../common/ListBanner/ListBanner";
import Button from "../../common/Button/Button";
import "./ListDetail.css";
import "react-image-gallery/styles/css/image-gallery.css";
import {
  disableScroll,
  enableScroll,
  getToken,
  closeModal,
  isMyProperty,
} from "../../common/commonFunctions/commonFunctions";
import ImageGallery from 'react-image-gallery';


const AnyReactComponent = ({ text }) => <div className="pin1"></div>;
const PropertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial Plot" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
];
const images = [
  {
    original: 'https://picsum.photos/id/1018/1000/600/',
    thumbnail: 'https://picsum.photos/id/1018/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1015/1000/600/',
    thumbnail: 'https://picsum.photos/id/1015/250/150/',
  },
  {
    original: 'https://picsum.photos/id/1019/1000/600/',
    thumbnail: 'https://picsum.photos/id/1019/250/150/',
  },
];
// const data = [
//   {
//     id: 1,
//     transactionHash: "01234X456465465132156465135486541321564156431",
//     from: "Xyz",
//     to: "Xyz",
//     date: "21/03/2021",
//     contractAddress: "2131@1254131#15351313",

//     moreDetails: (
//       <a href={"https://ropsten.etherscan.io/tx/"} target="_blank">
//         View in Ethurscan
//       </a>
//     ),
//   },
// ];

const columns = [
  {
    name: "Transaction Hash",
    selector: "nftMintingBlockchainTransactionHash",
  },
  {
    name: "NFT smart Address",
    selector: "nftSmartContractAddress",
  },
  {
    name: "NFT TokkenID",
    selector: "nftTokenId",
  },
  {
    name: "Owner Address",
    selector: "nftMintingOwnerAddress",
  },
  {
    name: "Owned Date",
    selector: "nftMintingBlockchainTransactionDate",
  },

  {
    name: "More Details",
    selector: "moreDetails",
  },
];

class ListDetailImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      listDetail: "",
      PropertyType: "",
      isLoader: "",
      dataList: [],
      imagesMedia: [],
      mypropertyDetails: false,
      offerPrice: 0,
      message: "",
      propertyUuid: "",
      errorMessage: "",
      isMyProperty: false,
      documents: [],
      inspection: [],
      existingBills: [],
      List: [],
      imageList:[],
      center: {
        lat: 59.95,
        lng: 30.33,
      },
    };
  }
  static defaultProps = {
    zoom: 5,
  };

  componentDidMount() {
    disableScroll();

    console.log("nnnnnnnnnanannanme", this.props.location.state.name)

    this.setState({ isLoader: true });
    var url = this.props.location.search;
    var params = queryString.parse(url);
    console.log(params);
    let array = [];
    let center = "";
    let imagesMedia = [];
    let dataList = [];
    this.setState({ propertyUuid: params.selectedList });
    this.setState({ mypropertyDetails: params.myproperty == "true" });

    axios({
      method: "get",
      url: baseURL + "api/v1/Property/get_listings",
    })
      .then((response) => {



        this.setState({ isLoader: false });
        enableScroll();

        array = response.data.data.filter(
          (item) => item.uuid == this.props.location.state.name
        );
        center = { lat: array[0].latitude, lng: array[0].longitude };
        imagesMedia = array[0].propertyMedia;
        console.log("HHHHHHHHHHHHHHHHHHHHHHH", response)
        this.setState({ List: array })
        // this.setState({ List:  response.data.data })
        console.log("data is ", response.data.data)
        console.log("array", array);
        let myImageArray=this.state.List.map((item) => {
          let imageArray=item.propertyMedia.map((image) => {

           const  images = 
              {
                original: `${baseURL}${image.mediaUrl}`,
                // original: 'https://picsum.photos/id/1018/1000/600/',
                thumbnail: `${baseURL}${image.mediaUrl}`,
                // thumbnail: 'https://picsum.photos/id/1018/250/150/',
              }
           
            return images
            
            })
          return imageArray
        })
          this.setState({imageList:myImageArray[0]})
       console.log("what is image",this.state.imageList)
         



        // dataList = [
        //   {
        //     nftMintingBlockchainTransactionHash:
        //       array[0].nftMintingBlockchainTransactionHash,
        //     nftSmartContractAddress: array[0].nftSmartContractAddress,
        //     nftTokenId: array[0].nftTokenId,
        //     nftMintingOwnerAddress: array[0].nftMintingOwnerAddress,
        //     nftMintingBlockchainTransactionDate:
        //       array[0].nftMintingBlockchainTransactionDate,
        //     moreDetails: (
        //       <a
        //         href={
        //           "https://ropsten.etherscan.io/tx/" +
        //           array[0].nftMintingBlockchainTransactionHash
        //         }
        //         target="_blank"
        //       >
        //         View in Ethurscan
        //       </a>
        //     ),
        //   },
        // ];
        this.setState({
          isMyProperty: isMyProperty(array[0].accountEmail),
          documents: array[0].propertyProofDocs,
          inspection: array[0].propertyInspectionReport,
          existingBills: array[0].propertyExistingBill,
          listDetail: array[0],
          PropertyType: PropertyType.filter(
            (item) => item.id === array[0].propertyTypeId
          ).map((id) => id.PropertyTypeName),
          dataDetail: array,
          imagesMedia: imagesMedia,
          dataList: dataList,
          offerPrice: array[0].price,

          center: center,
        });
      })
      .catch((error) => {
        this.setState({ isLoader: false });
        enableScroll();
      });
  }

  componentDidUpdate(prevProps) {
    const {
      location: { search },
    } = this.props;
    const {
      location: { search: prevSearch },
    } = prevProps;

    if (search !== prevSearch) {
      this.setState({ isLoader: true });
      disableScroll();
      var params = queryString.parse(search);
      let array = [];
      let center = "";
      let imagesMedia = [];
      let dataList = [];
      axios({
        method: "get",
        url: baseURL + "api/v1/Property/get_listings",
      })
        .then((response) => {
          this.setState({ isLoader: false });
          enableScroll();
          array = response.data.data.filter(
            (item) => item.uuid == params.selectedList
          );
          console.log("array", array);
          center = { lat: array[0].latitude, lng: array[0].longitude };
          imagesMedia = array[0].propertyMedia;
          dataList = [
            {
              nftMintingBlockchainTransactionHash:
                array[0].nftMintingBlockchainTransactionHash,
              nftSmartContractAddress: array[0].nftSmartContractAddress,
              nftTokenId: array[0].nftTokenId,
              nftMintingOwnerAddress: array[0].nftMintingOwnerAddress,
              nftMintingBlockchainTransactionDate:
                array[0].nftMintingBlockchainTransactionDate,
              moreDetails: (
                <a
                  href={
                    "https://ropsten.etherscan.io/tx/" +
                    array[0].nftMintingBlockchainTransactionHash
                  }
                  target="_blank"
                >
                  View in Ethurscan
                </a>
              ),
            },
          ];
          this.setState({
            documents: array[0].propertyProofDocs,
            listDetail: array[0],
            offerPrice: array[0].price,
            PropertyType: PropertyType.filter(
              (item) => item.id === array[0].propertyTypeId
            ).map((id) => id.PropertyTypeName),

            imagesMedia: imagesMedia,
            dataList: dataList,
            center: center,
          });
        })
        .catch((error) => {
          this.setState({ isLoader: false });
          enableScroll();
        });
    }
  }
  handleMakeOffer = () => {
    this.setState({
      isLoader: true,
    });
    disableScroll();
    axios({
      method: "post",
      url: baseURL + "api/Offer/MakeOffer",
      data: {
        offerPrice: this.state.offerPrice,
        message: this.state.message,
        propertyUuid: this.state.propertyUuid,
      },
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        this.setState({
          isLoader: false,
          errorMessage: response.data.message,
        });
        enableScroll();
      })

      .catch((error) => {
        this.setState({
          isLoader: false,
          errorMessage: "Something went Wrong",
        });
        enableScroll();
      });
  };
  onHandleChange = (event) => {
    this.setState({ [event.target.name]: event.target.value });
  };
  handleButton = (e) => {
    this.props.history.push({
      pathname: "/generatepdf",
      search: "selectedpdf=" + this.state.propertyUuid,
    });
  };

  render() {
    return (
      <>

       
          
                <ImageGallery items={this.state.imageList} />
           
         



      </>
    );
  }
}

export default withRouter(ListDetailImage);
