import React, { Component } from "react";

import Slider from "rc-slider";
import "rc-slider/assets/index.css";
const { Range } = Slider;
const style = { width: 400, margin: 50 };

class CustomizedRange extends Component {
  state = {
    mini: 0,
    maxi: 3000,
  };
  constructor(props) {
    super(props);
    this.state = {
      min: 0,
      max: 5000,
    };
  }
  componentDidMount() {}
  onSliderChange = (value) => {
    console.log(value);
    this.setState({ min: value[0], max: value[1] });
  };

  render() {
    return (
      <div className="adv-search-range-cont">
        <label
          for="slider-range-price1-value"
          className="adv-search-label pull-left"
        >
          {this.props.label}:
        </label>
        <span className="pull-right">{this.props.signSymbol}</span>
        <input
          type="text"
          id="slider-range-price1-value"
          placeholder={this.state.min + "-" + this.state.max}
          readonly
          className="adv-search-amount"
        />
        <div className="clearfix"></div>

        <Range
          onChange={this.onSliderChange}
          min={this.state.mini }
          max={this.state.maxi}
        />
      </div>
    );
  }
}

export default CustomizedRange;
