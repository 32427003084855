import React, { useEffect, useState } from "react";
import Logo from "../../../assets/images/pdfLogo.png";
import OREALOGO from "../../../assets/images/OREALOGO.png";
import Seal from "../../../assets/images/seal.png";
import html2PDF from "jspdf-html2canvas";
import "./GeneratePDF.css";
import queryString from "query-string";
import axios from "axios";
import { baseURL } from "./../../../ApiURL/ApiURL";
import { getToken } from "./../../common/commonFunctions/commonFunctions";
import { ToWords } from "to-words";
function GeneratePDF(props) {
  const toWords = new ToWords();
  const [pdfId, setPdfId] = useState("");
  const [loader, setLoader] = useState(true);
  const [buyerName, setBuyerName] = useState("");
  const [buyerEmail, setBuyerEmail] = useState("");
  const [buyerPhoneNumber, setBuyerPhoneNumber] = useState("");

  const [sellerName, setSellerName] = useState("");
  const [sellerEmail, setSellerEmail] = useState("");
  const [sellerPhoneNumber, setSellerPhoneNumber] = useState("");
  const [propertyFrontage, setPropertyFrontage] = useState("");
  const [propertyAddress, setPropertyAddress] = useState("");
  const [propertyAmountInWords, setPropertyAmountInWords] = useState("");
  const [propertyAmount, setPropertyAmount] = useState("");
  const [propertyDetail, setPropertyDetail] = useState([]);
  useEffect(() => {
    var url = props.location.search;
    var params = queryString.parse(url);
    let propertyDetail = [];
    setPdfId(params.selectedpdf);
    console.log("hello", pdfId);
    axios({
      method: "get",
      url: baseURL + "api/Offer/GetOffers",
      headers: {
        "Content-Type": "application/json-patch+json",
        accept: "text/plain",
        Authorization: `Bearer ${getToken()}`,
      },
    })
      .then((response) => {
        console.log("hello", response.data.data);
        propertyDetail = response.data.data.filter(
          (item) => item.property.uuid === pdfId
        );
        propertyDetail = propertyDetail
          .filter((item) => item.isAccepted === true)
          .map((item) => {
            setBuyerName(item.offerBy.name);
            setBuyerEmail(item.offerBy.email);
            setBuyerPhoneNumber(item.offerBy.phoneNumber);
            setSellerName(item.offerTo.name);
            setSellerEmail(item.offerTo.email);
            setSellerPhoneNumber(item.offerTo.phoneNumber);
            setPropertyAddress(item.property.locationAddress);
            setPropertyFrontage(item.property.propertyFrontage);
            setPropertyAmount(item.property.price);
            let words = toWords.convert(item.property.price);
            setPropertyAmountInWords(words);
          });
      })
      .catch((error) => {
        setLoader(true);
      });
  }, [pdfId]);
  const generatePDF = () => {
    let pages = document.getElementById("page");
    html2PDF(pages, {
      jsPDF: {
        format: "a4",
      },
      imageType: "image/jpeg",
      output: "./Agreement.pdf",
    });
  };

  useEffect(() => {
    var today = new Date();
    var day = today.getDate();

    console.log("day", day);
  }, []);
  return (
    <>
      <div id="page">
        <div className="page page1">
          {" "}
          <header className="header-section">
            {" "}
            <div className="col-md-12 main">
              <div className="col-md-2 form-100">
                <b>Form 100</b>

                <span> for use in the Province of Ontario</span>
              </div>
              <div className="col-md-8 heading">
                New Agreement of Purchase and Sale
              </div>
              <div className="col-md-2 logo">
                <img height="70px " width="70px" src={Logo} alt="pdf logo" />
              </div>
            </div>
            <div className="col-md-12 seperator-line-wrapper">
              <div className="seperator-line"></div>
            </div>
          </header>
          <div className="agreement col-md-12">
            <div className="date">
              This Agreement of Purchase and Sale dated this
              ..................................... day of
              .........................................................................................................
              20............
            </div>
            <div className="buyer">
              <b>BUYER : </b>
              <b className="buyer-name">{buyerName}</b>, agrees to purchase from
            </div>
            <div className="seller">
              <b>SELLER : </b>
              <b className="seller-name">{sellerName}</b>, the following
            </div>

            <br />
            <div className="realproperty">
              <b>REAL PROPERTY:</b>
            </div>
            <br />
            <div className="address">
              Address <b>{propertyAddress}</b>
            </div>
            <div className="fronting">
              fronting on the
              <b>Missing</b>
              side of
              <b className="sideof">Missing</b>
              in the
              <b className="inthe">Missing</b>
              and having a frontage of
              <b className="havingfrontage">{propertyFrontage}</b>
              more or less by a depth of
              <b className="depth">Missing</b> more or less and legally
              described as
              <b className="legallydescribed">Missing</b>
              <br />
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp;
              <b className="theproperty">Missing</b>&nbsp;&nbsp;(the “property”)
              <div className="pdf-form-label">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                (Legal description of land including easements not described
                elsewhere)
              </div>
            </div>
            <div className="purchase-price">
              <b>PURCHASE PRICE:</b> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
              <span>
                Dollars (CDN$)&nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b className="propertyprice">{propertyAmount}$</b>
                <div className="propertypricewords">
                  <b> {propertyAmountInWords}</b>
                </div>
                &nbsp;&nbsp;&nbsp; Dollars
              </span>
            </div>
            <br />
            <div className="schedule">
              <b>
                SCHEDULE(S) A &nbsp;&nbsp;
                <b className="schedule-input">MISSING</b> &nbsp;&nbsp;attached
                hereto form(s) part of this Agreement.
              </b>
            </div>
            <ol>
              <li value="1" className="irrevocability">
                <b>IRREVOCABILITY:</b> This offer shall be irrevocable by
                &nbsp;&nbsp;<b className="seller">{sellerName}</b>&nbsp;&nbsp;
                until&nbsp;&nbsp; ............................... &nbsp;&nbsp;on
                the&nbsp;&nbsp; .............. &nbsp;&nbsp;day of&nbsp;&nbsp;
                ...................................................................&nbsp;&nbsp;
                20&nbsp;&nbsp;..............&nbsp;&nbsp; , after which time, if
                not accepted, this offer shall be null and void and the deposit
                shall be returned to the Buyer in full without interest.
              </li>
              <li value="2" className="completion-date">
                {" "}
                <b> COMPLETION DATE:</b> This Agreement shall be completed by no
                later than 6:00 p.m. on the &nbsp;&nbsp;..............
                &nbsp;&nbsp;day of
                &nbsp;&nbsp;...................................................&nbsp;&nbsp;
                20&nbsp;&nbsp;..............&nbsp;&nbsp; Upon completion, vacant
                possession of the property shall be given to the Buyer unless
                otherwise provided for in this Agreement.
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>
                  INITIALS OF BUYER(S): &nbsp;&nbsp;{" "}
                  <span className="oval"></span>
                </b>{" "}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>
                  INITIALS OF SELLER(S): &nbsp;&nbsp;{" "}
                  <span className="oval"></span>
                </b>
              </li>
            </ol>
          </div>
        </div>
        <div className="page page2 ">
          <div className="col-md-12 agreement">
            <ol>
              <li value="3" className="notices">
                <b>NOTICES:</b> The Seller hereby appoints the Listing Brokerage
                as agent for the Seller for the purpose of giving and receiving
                notices pursuant to this Agreement. Where a Brokerage (Buyer’s
                Brokerage) has entered into a representation agreement with the
                Buyer, the Buyer hereby appoints the Buyer’s Brokerage as agent
                for the purpose of giving and receiving notices pursuant to this
                Agreement.{" "}
                <b>
                  Where a Brokerage represents both the Seller and the Buyer
                  (multiple representation), the Brokerage shall not be
                  appointed or authorized to be agent for either the Buyer or
                  the Seller for the purpose of giving and receiving notices.
                </b>{" "}
                Any notice relating hereto or provided for herein shall be in
                writing. In addition to any provision contained herein and in
                any Schedule hereto, this offer, any counter-offer, notice of
                acceptance thereof or any notice to be given or received
                pursuant to this Agreement or any Schedule hereto (any of them,
                “Document”) shall be deemed given and received when delivered
                personally or hand delivered to the Address for Service provided
                in the Acknowledgement below, or where a facsimile number or
                email address is provided herein, when transmitted
                electronically to that facsimile number or email address,
                respectively, in which case, the signature(s) of the party
                (parties) shall be deemed to be original.
                <br />
                FAX No.&nbsp;:&nbsp;&nbsp;
                ........................................................................................
                FAX No.&nbsp;:&nbsp;&nbsp;
                ............................................................................................
                <div className="pdf-form-label">
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(For
                  delivery of Documents to
                  Seller)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(For
                  delivery of Documents to Buyer)
                </div>
                <br />
                Email Address:&nbsp;&nbsp;
                <b className="seller-email"> {sellerEmail} </b>
                Email Address:&nbsp;&nbsp;
                <b className="buyer-email"> {buyerEmail} </b>
                <div className="pdf-form-label">
                  {" "}
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(For
                  delivery of Documents to
                  Seller)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(For
                  delivery of Documents to Buyer)
                </div>
              </li>
              <li className="chattels">
                <b> CHATTELS INCLUDED:</b>
                <div>
                  {" "}
                  .......................................................................................................MISSING........................................................................................................
                  ...............................................................................................................................................................................................................
                  ...............................................................................................................................................................................................................
                  ...............................................................................................................................................................................................................
                </div>{" "}
                <div>
                  Unless otherwise stated in this Agreement or any Schedule
                  hereto, Seller agrees to convey all fixtures and chattels
                  included in the Purchase Price free from all liens,
                  encumbrances or claims affecting the said fixtures and
                  chattels.
                </div>
              </li>
              <li className="fixtures-excluded">
                {" "}
                <b> FIXTURES EXCLUDED:</b>
                <div>
                  {" "}
                  ............................................................................................................MISSING....................................................................................................
                  ................................................................................................................................................................................................................
                  ................................................................................................................................................................................................................
                  ................................................................................................................................................................................................................
                </div>
              </li>
              <li className="rental-items">
                <b>RENTAL ITEMS (Including Lease, Lease to Own): </b> The
                following equipment is rented and <b>not</b> included in the
                Purchase Price. The Buyer agrees to assume the rental
                contract(s), if assumable:
                <div>
                  ....................................................................................................................MISSING............................................................................................
                  ................................................................................................................................................................................................................
                  ................................................................................................................................................................................................................
                </div>
                <div>
                  {" "}
                  The Buyer agrees to co-operate and execute such documentation
                  as may be required to facilitate such assumption.
                </div>{" "}
              </li>
              <li className="hst">
                <b>HST: </b> If the sale of the property (Real Property as
                described above) is subject to Harmonized Sales Tax (HST), then
                such tax shall be
                <div>
                  {" "}
                  <b className="hst-tax">MISSING</b>&nbsp;&nbsp; the Purchase
                  Price. If the sale of the property is not subject to HST,
                  Seller agrees to certify on or before
                </div>{" "}
                <div className="pdf-form-label">
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(included
                  in/in addition to)
                </div>
                <div>
                  {" "}
                  closing, that the sale of the property is not subject to HST.
                  Any HST on chattels, if applicable, is not included in the
                  Purchase Price.
                </div>
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>
                  INITIALS OF BUYER(S): &nbsp;&nbsp;{" "}
                  <span className="oval"></span>
                </b>{" "}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>
                  INITIALS OF SELLER(S): &nbsp;&nbsp;{" "}
                  <span className="oval"></span>
                </b>
              </li>
            </ol>
          </div>
        </div>
        <div className="page page3 ">
          <div className="col-md-12">
            <ol>
              <li value="8">
                <b> TITLE SEARCH:</b> Buyer shall be allowed until 6:00 p.m. on
                the .............. day of
                .......................................................,
                20...................... , (Requisition Date) to examine the
                title to the property at Buyer’s own expense and until the
                earlier of: (i) thirty days from the later of the Requisition
                Date or the date on which the conditions in this Agreement are
                fulfilled or otherwise waived or; (ii) five days prior to
                completion, to satisfy Buyer that there are no outstanding work
                orders or deficiency notices affecting the property, and that
                its present use (. ) may be lawfully continued and that the
                principal building may be insured against risk of fire. Seller
                hereby consents to the municipality or other governmental
                agencies releasing to Buyer details of all outstanding work
                orders and deficiency notices affecting the property, and Seller
                agrees to execute and deliver such further authorizations in
                this regard as Buyer may reasonably require.
              </li>
              <li className="future-use">
                <b> FUTURE USE: </b>
                Seller and Buyer agree that there is no representation or
                warranty of any kind that the future intended use of the
                property by Buyer is or will be lawful except as may be
                specifically provided for in this Agreement.
              </li>
              <li className="title">
                <b>TITLE:</b> Provided that the title to the property is good
                and free from all registered restrictions, charges, liens, and
                encumbrances except as otherwise specifically provided in this
                Agreement and save and except for (a) any registered
                restrictions or covenants that run with the land providing that
                such are complied with; (b) any registered municipal agreements
                and registered agreements with publicly regulated utilities
                providing such have been complied with, or security has been
                posted to ensure compliance and completion, as evidenced by a
                letter from the relevant municipality or regulated utility; (c)
                any minor easements for the supply of domestic utility or
                telecommunication services to the property or adjacent
                properties; and (d) any easements for drainage, storm or
                sanitary sewers, public utility lines, telecommunication lines,
                cable television lines or other services which do not materially
                affect the use of the property. If within the specified times
                referred to in paragraph 8 any valid objection to title or to
                any outstanding work order or deficiency notice, or to the fact
                the said present use may not lawfully be continued, or that the
                principal building may not be insured against risk of fire is
                made in writing to Seller and which Seller is unable or
                unwilling to remove, remedy or satisfy or obtain insurance save
                and except against risk of fire (Title Insurance) in favour of
                the Buyer and any mortgagee, (with all related costs at the
                expense of the Seller), and which Buyer will not waive, this
                Agreement notwithstanding any intermediate acts or negotiations
                in respect of such objections, shall be at an end and all monies
                paid shall be returned without interest or deduction and Seller,
                Listing Brokerage and Co-operating Brokerage shall not be liable
                for any costs or damages. Save as to any valid objection so made
                by such day and except for any objection going to the root of
                the title, Buyer shall be conclusively deemed to have accepted
                Seller’s title to the property.
              </li>
              <li className="closing-arrangements">
                <b>CLOSING ARRANGEMENTS:</b> Where each of the Seller and Buyer
                retain a lawyer to complete the Agreement of Purchase and Sale
                of the property, and where the transaction will be completed by
                electronic registration pursuant to Part III of the Land
                Registration Reform Act, R.S.O. 1990, Chapter L4 and the
                Electronic Registration Act, S.O. 1991, Chapter 44, and any
                amendments thereto, the Seller and Buyer acknowledge and agree
                that the exchange of closing funds, non-registrable documents
                and other items (the “Requisite Deliveries”) and the release
                thereof to the Seller and Buyer will (a) not occur at the same
                time as the registration of the transfer/deed (and any other
                documents intended to be registered in connection with the
                completion of this transaction) and (b) be subject to conditions
                whereby the lawyer(s) receiving any of the Requisite Deliveries
                will be required to hold same in trust and not release same
                except in accordance with the terms of a document registration
                agreement between the said lawyers. The Seller and Buyer
                irrevocably instruct the said lawyers to be bound by the
                document registration agreement which is recommended from time
                to time by the Law Society of Ontario. Unless otherwise agreed
                to by the lawyers, such exchange of Requisite Deliveries shall
                occur by the delivery of the Requisite Deliveries of each party
                to the office of the lawyer for the other party or such other
                location agreeable to both lawyers.
              </li>
              <li className="documents-discharge">
                {" "}
                <b>DOCUMENTS AND DISCHARGE: </b>Buyer shall not call for the
                production of any title deed, abstract, survey or other evidence
                of title to the property except such as are in the possession or
                control of Seller. If requested by Buyer, Seller will deliver
                any sketch or survey of the property within Seller’s control to
                Buyer as soon as possible and prior to the Requisition Date. If
                a discharge of any Charge/Mortgage held by a corporation
                incorporated pursuant to the Trust And Loan Companies Act
                (Canada), Chartered Bank, Trust Company, Credit Union, Caisse
                Populaire or Insurance Company and which is not to be assumed by
                Buyer on completion, is not available in registrable form on
                completion, Buyer agrees to accept Seller’s lawyer’s personal
                undertaking to obtain, out of the closing funds, a discharge in
                registrable form and to register same, or cause same to be
                registered, on title within a reasonable period of time after
                completion, provided that on or before completion Seller shall
                provide to Buyer a mortgage statement prepared by the mortgagee
                setting out the balance required to obtain the discharge, and,
                where a real-time electronic cleared funds transfer system is
                not being used, a direction executed by Seller directing payment
                to the mortgagee of the amount required to obtain the discharge
                out of the balance due on completion.
              </li>
              <li className="inception">
                <b>INSPECTION:</b> Buyer acknowledges having had the opportunity
                to inspect the property and understands that upon acceptance of
                this offer there shall be a binding agreement of purchase and
                sale between Buyer and Seller.{" "}
                <b>
                  The Buyer acknowledges having the opportunity to include a
                  requirement for a property inspection report in this Agreement
                  and agrees that except as may be specifically provided for in
                  this Agreement, the Buyer will not be obtaining a property
                  inspection or property inspection report regarding the
                  property.
                </b>
              </li>
              <li className="insurance">
                <b>INSURANCE:</b> All buildings on the property and all other
                things being purchased shall be and remain until completion at
                the risk of Seller. Pending completion, Seller shall hold all
                insurance policies, if any, and the proceeds thereof in trust
                for the parties as their interests may appear and in the event
                of substantial damage, Buyer may either terminate this Agreement
                and have all monies paid returned without interest or deduction
                or else take the proceeds of any insurance and complete the
                purchase. No insurance shall be transferred on completion. If
                Seller is taking back a Charge/ Mortgage, or Buyer is assuming a
                Charge/Mortgage, Buyer shall supply Seller with reasonable
                evidence of adequate insurance to protect Seller’s or other
                mortgagee’s interest on completion.
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>
                  INITIALS OF BUYER(S): &nbsp;&nbsp;{" "}
                  <span className="oval"></span>
                </b>{" "}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>
                  INITIALS OF SELLER(S): &nbsp;&nbsp;{" "}
                  <span className="oval"></span>
                </b>
              </li>
            </ol>
          </div>
        </div>
        <div className="page page4 ">
          <div className="col-md-12">
            <ol>
              <li value="15" className="planning-act">
                <b> PLANNING ACT: </b> This Agreement shall be effective to
                create an interest in the property only if Seller complies with
                the subdivision control provisions of the Planning Act by
                completion and Seller covenants to proceed diligently at
                Seller’s expense to obtain any necessary consent by completion.
              </li>
              <li className="document-preparation">
                <b>DOCUMENT PREPARATION: </b>
                The Transfer/Deed shall, save for the Land Transfer Tax
                Affidavit, be prepared in registrable form at the expense of
                Seller, and any Charge/Mortgage to be given back by the Buyer to
                Seller at the expense of the Buyer. If requested by Buyer,
                Seller covenants that the Transfer/Deed to be delivered on
                completion shall contain the statements contemplated by Section
                50(22) of the Planning Act, R.S.O.1990.
              </li>
              <li className="residency">
                <b>RESIDENCY: </b>
                (a) Subject to (b) below, the Seller represents and warrants
                that the Seller is not and on completion will not be a
                non-resident under the non-residency provisions of the Income
                Tax Act which representation and warranty shall survive and not
                merge upon the completion of this transaction and the Seller
                shall deliver to the Buyer a statutory declaration that Seller
                is not then a non-resident of Canada; (b) provided that if the
                Seller is a non-resident under the non-residency provisions of
                the Income Tax Act, the Buyer shall be credited towards the
                Purchase Price with the amount, if any, necessary for Buyer to
                pay to the Minister of National Revenue to satisfy Buyer’s
                liability in respect of tax payable by Seller under the
                non-residency provisions of the Income Tax Act by reason of this
                sale. Buyer shall not claim such credit if Seller delivers on
                completion the prescribed certificate..
              </li>
              <li className="adjustments">
                <b>ADJUSTMENTS:</b>Any rents, mortgage interest, realty taxes
                including local improvement rates and unmetered public or
                private utility charges and unmetered cost of fuel, as
                applicable, shall be apportioned and allowed to the day of
                completion, the day of completion itself to be apportioned to
                Buyer.{" "}
              </li>
              <li className="property-assesments">
                <b>PROPERTY ASSESSMENT:</b> The Buyer and Seller hereby
                acknowledge that the Province of Ontario has implemented current
                value assessment and properties may be re-assessed on an annual
                basis. The Buyer and Seller agree that no claim will be made
                against the Buyer or Seller, or any Brokerage, Broker or
                Salesperson, for any changes in property tax as a result of a
                re-assessment of the property, save and except any property
                taxes that accrued prior to the completion of this transaction.
              </li>
              <li className="time-limits">
                {" "}
                <b>TIME LIMITS:</b> Time shall in all respects be of the essence
                hereof provided that the time for doing or completing of any
                matter provided for herein may be extended or abridged by an
                agreement in writing signed by Seller and Buyer or by their
                respective lawyers who may be specifically authorized in that
                regard.
              </li>
              <li className="tender">
                <b>TENDER:</b> Any tender of documents or money hereunder may be
                made upon Seller or Buyer or their respective lawyers on the day
                set for completion. Money shall be tendered with funds drawn on
                a lawyer’s trust account in the form of a bank draft, certified
                cheque or wire transfer using the Large Value Transfer System.
              </li>
              <li className="family-law-act">
                <b>FAMILY LAW ACT:</b> Seller warrants that spousal consent is
                not necessary to this transaction under the provisions of the
                Family Law Act, R.S.O.1990 unless the spouse of the Seller has
                executed the consent hereinafter provided.
              </li>
              <li className="uffi">
                <b>UFFI:</b> Seller represents and warrants to Buyer that during
                the time Seller has owned the property, Seller has not caused
                any building on the property to be insulated with insulation
                containing ureaformaldehyde, and that to the best of Seller’s
                knowledge no building on the property contains or has ever
                contained insulation that contains ureaformaldehyde. This
                warranty shall survive and not merge on the completion of this
                transaction, and if the building is part of a multiple unit
                building, this warranty shall only apply to that part of the
                building which is the subject of this transaction.
              </li>
              <li className="legal">
                <b>LEGAL, ACCOUNTING AND ENVIRONMENTAL ADVICE: </b>The parties
                acknowledge that any information provided by the brokerage is
                not legal, tax or environmental advice.
              </li>
              <li className="consumer-reports">
                CONSUMER REPORTS: The Buyer is hereby notified that a consumer
                report containing credit and/ or personal information may be
                referred to in connection with this transaction.
              </li>
              <li className="agreement-writing">
                <b> AGREEMENT IN WRITING:</b> If there is conflict or
                discrepancy between any provision added to this Agreement
                (including any Schedule attached hereto) and any provision in
                the standard pre-set portion hereof, the added provision shall
                supersede the standard pre-set provision to the extent of such
                conflict or discrepancy. This Agreement including any Schedule
                attached hereto, shall constitute the entire Agreement between
                Buyer and Seller. There is no representation, warranty,
                collateral agreement or condition, which affects this Agreement
                other than as expressed herein. For the purposes of this
                Agreement, Seller means vendor and Buyer means purchaser. This
                Agreement shall be read with all changes of gender or number
                required by the context.
              </li>
              <li className="date-and-time">
                <b>TIME AND DATE:</b> Any reference to a time and date in this
                Agreement shall mean the time and date where the property is
                located.
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                <br />
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>
                  INITIALS OF BUYER(S): &nbsp;&nbsp;{" "}
                  <span className="oval"></span>
                </b>{" "}
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;
                &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;{" "}
                <b>
                  INITIALS OF SELLER(S): &nbsp;&nbsp;{" "}
                  <span className="oval"></span>
                </b>
              </li>
            </ol>
          </div>
        </div>
        <div className="page page5 ">
          <div className="col-md-12">
            <ol>
              <li value="28" className="successor">
                <b> SUCCESSOR AND ASSIGNS: </b>The heirs, executors,
                administrators, successors and assigns of the undersigned are
                bound by the terms herein.
              </li>
            </ol>
            <div className="col-md-12">
              <div className="signed-sealed">
                SIGNED, SEALED AND DELIVERED in the presence of:
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                IN WITNESS whereof I have hereunto set my hand and seal:
              </div>
              <br />
              <div className="seal-form">
                <div>
                  {" "}
                  ..........................................................................................
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b className="buyer">{buyerName}</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img height="16px" width="16px" src={Seal} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...............................................
                </div>
                <div className="pdf-form-label">
                  (Witness)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Buyer)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Seal)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Date)
                </div>
              </div>
              <br />
              <div className="seal-form">
                <div>
                  {" "}
                  ..........................................................................................
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b className="buyer">{buyerName}</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img height="16px" width="16px" src={Seal} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...............................................
                </div>
                <div className="pdf-form-label">
                  (Witness)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Buyer)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Seal)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Date)
                </div>
              </div>
              <br />
            </div>
            <div className="col-md-12 ">
              I, the Undersigned Seller, agree to the above offer. I hereby
              irrevocably instruct my lawyer to pay directly to the brokerage(s)
              with whom I have agreed to pay commission, the unpaid balance of
              the commission together with applicable Harmonized Sales Tax (and
              any other taxes as may hereafter be applicable), from the proceeds
              of the sale prior to any payment to the undersigned on completion,
              as advised by the brokerage(s) to my lawyer.
            </div>
            <div className="col-md-12">
              <br />
              <div className="signed-sealed">
                SIGNED, SEALED AND DELIVERED in the presence of:
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                IN WITNESS whereof I have hereunto set my hand and seal:
              </div>
              <br />
              <div className="seal-form">
                <div>
                  {" "}
                  ..........................................................................................
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b className="seller">{sellerName}</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img height="16px" width="16px" src={Seal} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...............................................
                </div>
                <div className="pdf-form-label">
                  (Witness)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Seller)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Seal)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Date)
                </div>
              </div>
              <br />
              <div className="seal-form">
                <div>
                  {" "}
                  ..........................................................................................
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b className="seller">{sellerName}</b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img height="16px" width="16px" src={Seal} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...............................................
                </div>
                <div className="pdf-form-label">
                  (Witness)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Seller)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Seal)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Date)
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <br />
              <b> SPOUSAL CONSENT:</b> The undersigned spouse of the Seller
              hereby consents to the disposition evidenced herein pursuant to
              the provisions of the Family Law Act, R.S.O.1990, and hereby
              agrees to execute all necessary or incidental documents to give
              full force and effect to the sale evidenced herein.
            </div>
            <div className="col-md-12">
              <br />{" "}
              <div className="seal-form">
                <div>
                  {" "}
                  ..........................................................................................
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <b className="spouse"></b>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                  <img height="16px" width="16px" src={Seal} />
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;...............................................
                </div>
                <div className="pdf-form-label">
                  (Witness)&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Spouse)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Seal)
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(Date)
                </div>
              </div>
              <br />
            </div>
            <div className="col-md-12">
              <b> CONFIRMATION OF ACCEPTANCE:</b> Notwithstanding anything
              contained herein to the contrary, I confirm this Agreement with
              all changes both typed and written was finally accepted by all
              parties at .......................................... this
              ................. day
              of.....................................................,
              20...........
              <div className="pdf-form-label">
                {" "}
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;(a.m./p.m.)
              </div>
              <br />
            </div>
            <div className="col-md-5 col-md-offset-7">
              ..............................................................................................
              <div className="pdf-form-label">
                (Signature of Seller or Buyer)
              </div>
            </div>
            <div className="acknowledgement-form">
              <div className="col-md-12 acknowledgement ">
                {" "}
                <br />
                <br />
                <b> ACKNOWLEDGEMENT</b>
              </div>
              <div className="col-md-12 ">
                <br />
                <div className="col-md-6 left">
                  I acknowledge receipt of my signed copy of this accepted
                  Agreement of <br />
                  Purchase and Sale and I authorize the Brokerage to forward a
                  copy to my lawyer
                  <div className="">
                    <b className="seller">{sellerName}</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b className="date">{}</b>
                  </div>
                  <div className="pdf-form-label">
                    {" "}
                    (Seller)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (Date)
                  </div>
                  <div className="">
                    <b className="seller">{sellerName}</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b className="date">{}</b>
                  </div>
                  <div className="pdf-form-label">
                    {" "}
                    (Seller)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (Date)
                  </div>
                  <div className="">
                    Address for Service
                    .................................................................................................
                    ..............................................................................
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b className="tel-no">{sellerPhoneNumber}</b>
                  </div>
                  <div className="pdf-form-label">
                    {" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Tel.
                    No.)
                  </div>
                  <div>
                    Seller’s Lawyer &nbsp;&nbsp;&nbsp;
                    .....................................................................................................
                  </div>
                  <div>
                    Address &nbsp;&nbsp;&nbsp;
                    ................................................................................................................
                  </div>
                  <div>
                    Email &nbsp;&nbsp;&nbsp;
                    .....................................................................................................................
                  </div>
                  <div>
                    .................................................................
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    ........................................................
                  </div>
                  <div className="pdf-form-label">
                    (Tel. No.)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (Fax. No.)
                  </div>
                </div>
                <div className="col-md-6 right">
                  I acknowledge receipt of my signed copy of this accepted
                  Agreement of <br />
                  Purchase and Sale and I authorize the Brokerage to forward a
                  copy to my lawyer
                  <div className="">
                    <b className="buyer">{buyerName}</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b className="date">{}</b>
                  </div>
                  <div className="pdf-form-label">
                    {" "}
                    (Buyer)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (Date)
                  </div>
                  <div className="">
                    <b className="buyer">{buyerName}</b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b className="date">{}</b>
                  </div>
                  <div className="pdf-form-label">
                    {" "}
                    (Buyer)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (Date)
                  </div>
                  <div className="">
                    Address for Service
                    .................................................................................................
                    ..............................................................................
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <b className="tel-no">{buyerPhoneNumber}</b>
                  </div>
                  <div className="pdf-form-label">
                    {" "}
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; (Tel.
                    No.)
                  </div>
                  <div>
                    Buyer’s Lawyer &nbsp;&nbsp;&nbsp;
                    .....................................................................................................
                  </div>
                  <div>
                    Address &nbsp;&nbsp;&nbsp;
                    ................................................................................................................
                  </div>
                  <div>
                    Email &nbsp;&nbsp;&nbsp;
                    .....................................................................................................................
                  </div>
                  <div>
                    .................................................................
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    ........................................................
                  </div>
                  <div className="pdf-form-label">
                    (Tel. No.)
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    (Fax. No.)
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="page page6">
          <header className="header-section">
            {" "}
            <div className="col-md-12 main">
              <div className="col-md-2 form-100">
                <img
                  height="auto "
                  width="auto"
                  src={OREALOGO}
                  alt="pdf logo"
                />
                <b>Form 100</b>

                <span> for use in the Province of Ontario</span>
              </div>
              <div className="col-md-8 heading">
                Schedule A <br />
                Agreement of Purchase and Sale
              </div>
              <div className="col-md-2 logo"></div>
            </div>
            <div className="col-md-12 seperator-line-wrapper">
              <div className="seperator-line"></div>
            </div>
          </header>
          <div className="col-md-12">
            <br />
            <div className="col-md-12">
              {" "}
              This Schedule is attached to and forms part of the Agreement of
              Purchase and Sale between:
            </div>
            <br />
            <div className="col-md-12">
              <b>BUYER:</b>{" "}
              ......................................................................MISSING..............................................................................................................................
              , and
            </div>
            <br />
            <div className="col-md-12">
              <b>Seller:</b>{" "}
              ....................................................................MISSING...........................................................................................................................................................
            </div>
            <br />
            <div className="col-md-12">
              for the purchase and sale of
              ................................................................MISSING..........................................................................................................{" "}
            </div>
            <br />
            <div className="col-md-12">
              {" "}
              .........................................................
              &nbsp;&nbsp;&nbsp;&nbsp; dated the &nbsp;&nbsp;&nbsp;&nbsp;
              ................... &nbsp;&nbsp; day of &nbsp;&nbsp;
              ...............................................................................................,&nbsp;&nbsp;
              &nbsp;&nbsp; 20.......................
            </div>
            <br />
            <div className="col-md-12">
              Buyer agrees to pay the balance as follows:
            </div>
          </div>
        </div>
      </div>
      <div className="col-md-12 button-alignment">
        <div
          className="button-primary button-shadow button-full pdf  "
          onClick={generatePDF}
        >
          <span>Download Agreement</span>
          <div className="button-triangle"></div>
          <div className="button-triangle2"></div>
          <div className="button-icon">
            <i className="fa fa-th-list"></i>
          </div>
        </div>
      </div>
    </>
  );
}

export default GeneratePDF;
