import React, { Component } from "react";
import "./ChatView.css";
class ChatView extends Component {
  render() {
    return (
      <section class="section-light section-top-shadow chat-view">
        <div class="container">
          <div class="row">
            <div class="col-xs-12 col-md-9 col-md-push-3 chat">
              <div
                className="chat-box"
              >
               <div className="chat-message">
                   hello my name is smith 
               </div>
               <div className="chat-message">
                   hello my name is smith 
               </div>
               <div className="chat-message">
                   hello my name is smith 
               </div>
               <div className="chat-message">
                   hello my name is smith 
               </div>
               <div className="chat-message">
                   hello my name is smith 
               </div>
               <div className="chat-message">
                   hello my name is smith 
               </div>
               <div className="chat-message">
                   hello my name is smith 
               </div>
               <div className="chat-message">
                   hello my name is smith 
               </div>
               <div className="chat-message">
                   hello my name is smith 
               </div>
              </div>
            <div className="send-message">
                <textarea 
                className="input-full main-input property-textarea margin-top-15"
                placeholder="Enter Message Here........"
                row="2"/>
                <button className="send-button" type="button">Send</button>
            </div>
            </div>
            <div class="col-xs-12 col-md-3 col-md-pull-9">
              <div class="sidebar-left">
                <div className="row margin-top-45">
                  <div className="col-xs-12 apartment-tabs">
                    <ul className="nav nav-tabs" role="tablist">
                      <li role="presentation" className="active">
                        <a
                          href="#tab-sent"
                          aria-controls="tab-sent"
                          role="tab"
                          data-toggle="tab"
                        >
                          <span>Sent</span>
                          <div className="button-triangle2"></div>
                        </a>
                      </li>
                      <li role="presentation">
                        <a
                          href="#tab-recieved"
                          aria-controls="tab-recieved"
                          role="tab"
                          data-toggle="tab"
                        >
                          <span>Recieved</span>
                          <div className="button-triangle2"></div>
                        </a>
                      </li>
                    </ul>

                    <div className="tab-content">
                      <div
                        role="tabpanel"
                        className="tab-pane active"
                        id="tab-sent"
                      >
                        <div style={{ height: "100vh", width: "100%" }}>
                          <div class="list-offer">
                            <div class="offer-list">
                              <div class="list-offer-text">
                                <div class="list-offer-h4">
                                  <a>
                                    <h4 class="list-offer-title">SMITH</h4>
                                  </a>
                                </div>
                                <div class="clearfix"></div>
                                <p class="description-p">
                                  Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum
                                  Lorem Ipsum Lorem Lorem Ipsum Lorem Ipsum
                                  Lorem Lorem Ipsum Lorem Ipsum Lorem Lorem
                                  Ipsum Lorem Ipsum Lorem Lorem Ipsum Lorem
                                  Ipsum Lorem Lorem Ipsum Lorem Ipsum Lorem
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum Lorem Ipsum Lorem
                                  Ipsum Lorem IPsum Lorem Ipsum Lorem Ipsum
                                  Lorem IPsum Lorem Ipsum Lorem Ipsum Lorem
                                  IPsum Lorem Ipsum Lorem Ipsum Lorem IPsum
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum
                                </p>

                                <div class="clearfix"></div>
                              </div>
                              <div class="profile-list-footer">
                                <div class="profile-list-info hidden-xs">
                                  added: 28/11/15
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                          <div class="list-offer">
                            <div class="offer-list">
                              <div class="list-offer-text">
                                <div class="list-offer-h4">
                                  <a>
                                    <h4 class="list-offer-title">SMITH</h4>
                                  </a>
                                </div>
                                <div class="clearfix"></div>
                                <p class="description-p">
                                  Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum
                                  Lorem Ipsum Lorem Lorem Ipsum Lorem Ipsum
                                  Lorem Lorem Ipsum Lorem Ipsum Lorem Lorem
                                  Ipsum Lorem Ipsum Lorem Lorem Ipsum Lorem
                                  Ipsum Lorem Lorem Ipsum Lorem Ipsum Lorem
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum Lorem Ipsum Lorem
                                  Ipsum Lorem IPsum Lorem Ipsum Lorem Ipsum
                                  Lorem IPsum Lorem Ipsum Lorem Ipsum Lorem
                                  IPsum Lorem Ipsum Lorem Ipsum Lorem IPsum
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum
                                </p>

                                <div class="clearfix"></div>
                              </div>
                              <div class="profile-list-footer">
                                <div class="profile-list-info hidden-xs">
                                  added: 28/11/15
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                          <div class="list-offer">
                            <div class="offer-list">
                              <div class="list-offer-text">
                                <div class="list-offer-h4">
                                  <a>
                                    <h4 class="list-offer-title">SMITH</h4>
                                  </a>
                                </div>
                                <div class="clearfix"></div>
                                <p class="description-p">
                                  Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum
                                  Lorem Ipsum Lorem Lorem Ipsum Lorem Ipsum
                                  Lorem Lorem Ipsum Lorem Ipsum Lorem Lorem
                                  Ipsum Lorem Ipsum Lorem Lorem Ipsum Lorem
                                  Ipsum Lorem Lorem Ipsum Lorem Ipsum Lorem
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum Lorem Ipsum Lorem
                                  Ipsum Lorem IPsum Lorem Ipsum Lorem Ipsum
                                  Lorem IPsum Lorem Ipsum Lorem Ipsum Lorem
                                  IPsum Lorem Ipsum Lorem Ipsum Lorem IPsum
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum
                                </p>

                                <div class="clearfix"></div>
                              </div>
                              <div class="profile-list-footer">
                                <div class="profile-list-info hidden-xs">
                                  added: 28/11/15
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                          <div class="list-offer">
                            <div class="offer-list">
                              <div class="list-offer-text">
                                <div class="list-offer-h4">
                                  <a>
                                    <h4 class="list-offer-title">SMITH</h4>
                                  </a>
                                </div>
                                <div class="clearfix"></div>
                                <p class="description-p">
                                  Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum
                                  Lorem Ipsum Lorem Lorem Ipsum Lorem Ipsum
                                  Lorem Lorem Ipsum Lorem Ipsum Lorem Lorem
                                  Ipsum Lorem Ipsum Lorem Lorem Ipsum Lorem
                                  Ipsum Lorem Lorem Ipsum Lorem Ipsum Lorem
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum Lorem Ipsum Lorem
                                  Ipsum Lorem IPsum Lorem Ipsum Lorem Ipsum
                                  Lorem IPsum Lorem Ipsum Lorem Ipsum Lorem
                                  IPsum Lorem Ipsum Lorem Ipsum Lorem IPsum
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum
                                </p>

                                <div class="clearfix"></div>
                              </div>
                              <div class="profile-list-footer">
                                <div class="profile-list-info hidden-xs">
                                  added: 28/11/15
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                          <div class="list-offer">
                            <div class="offer-list">
                              <div class="list-offer-text">
                                <div class="list-offer-h4">
                                  <a>
                                    <h4 class="list-offer-title">SMITH</h4>
                                  </a>
                                </div>
                                <div class="clearfix"></div>
                                <p class="description-p">
                                  Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum
                                  Lorem Ipsum Lorem Lorem Ipsum Lorem Ipsum
                                  Lorem Lorem Ipsum Lorem Ipsum Lorem Lorem
                                  Ipsum Lorem Ipsum Lorem Lorem Ipsum Lorem
                                  Ipsum Lorem Lorem Ipsum Lorem Ipsum Lorem
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum Lorem Ipsum Lorem
                                  Ipsum Lorem IPsum Lorem Ipsum Lorem Ipsum
                                  Lorem IPsum Lorem Ipsum Lorem Ipsum Lorem
                                  IPsum Lorem Ipsum Lorem Ipsum Lorem IPsum
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum
                                </p>

                                <div class="clearfix"></div>
                              </div>
                              <div class="profile-list-footer">
                                <div class="profile-list-info hidden-xs">
                                  added: 28/11/15
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                          <div class="list-offer">
                            <div class="offer-list">
                              <div class="list-offer-text">
                                <div class="list-offer-h4">
                                  <a>
                                    <h4 class="list-offer-title">SMITH</h4>
                                  </a>
                                </div>
                                <div class="clearfix"></div>
                                <p class="description-p">
                                  Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum
                                  Lorem Ipsum Lorem Lorem Ipsum Lorem Ipsum
                                  Lorem Lorem Ipsum Lorem Ipsum Lorem Lorem
                                  Ipsum Lorem Ipsum Lorem Lorem Ipsum Lorem
                                  Ipsum Lorem Lorem Ipsum Lorem Ipsum Lorem
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum Lorem Ipsum Lorem
                                  Ipsum Lorem IPsum Lorem Ipsum Lorem Ipsum
                                  Lorem IPsum Lorem Ipsum Lorem Ipsum Lorem
                                  IPsum Lorem Ipsum Lorem Ipsum Lorem IPsum
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum
                                </p>

                                <div class="clearfix"></div>
                              </div>
                              <div class="profile-list-footer">
                                <div class="profile-list-info hidden-xs">
                                  added: 28/11/15
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>

                          <div class="clearfix"></div>
                        </div>
                      </div>

                      <div
                        role="tabpanel"
                        className="tab-pane"
                        id="tab-recieved"
                      >
                        <div style={{ height: "100vh", width: "100%" }}>
                          <div class="list-offer">
                            <div class="offer-list">
                              <div class="list-offer-text">
                                <div class="list-offer-h4">
                                  <a>
                                    <h4 class="list-offer-title">SMITH</h4>
                                  </a>
                                </div>
                                <div class="clearfix"></div>
                                <p class="description-p">
                                  Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum
                                  Lorem Ipsum Lorem Lorem Ipsum Lorem Ipsum
                                  Lorem Lorem Ipsum Lorem Ipsum Lorem Lorem
                                  Ipsum Lorem Ipsum Lorem Lorem Ipsum Lorem
                                  Ipsum Lorem Lorem Ipsum Lorem Ipsum Lorem
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum Lorem Ipsum Lorem
                                  Ipsum Lorem IPsum Lorem Ipsum Lorem Ipsum
                                  Lorem IPsum Lorem Ipsum Lorem Ipsum Lorem
                                  IPsum Lorem Ipsum Lorem Ipsum Lorem IPsum
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum
                                </p>

                                <div class="clearfix"></div>
                              </div>
                              <div class="profile-list-footer">
                                <div class="profile-list-info hidden-xs">
                                  added: 28/11/15
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                          <div class="list-offer">
                            <div class="offer-list">
                              <div class="list-offer-text">
                                <div class="list-offer-h4">
                                  <a>
                                    <h4 class="list-offer-title">SMITH</h4>
                                  </a>
                                </div>
                                <div class="clearfix"></div>
                                <p class="description-p">
                                  Lorem Ipsum Lorem Ipsum Lorem Lorem Ipsum
                                  Lorem Ipsum Lorem Lorem Ipsum Lorem Ipsum
                                  Lorem Lorem Ipsum Lorem Ipsum Lorem Lorem
                                  Ipsum Lorem Ipsum Lorem Lorem Ipsum Lorem
                                  Ipsum Lorem Lorem Ipsum Lorem Ipsum Lorem
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum Lorem Ipsum Lorem
                                  Ipsum Lorem IPsum Lorem Ipsum Lorem Ipsum
                                  Lorem IPsum Lorem Ipsum Lorem Ipsum Lorem
                                  IPsum Lorem Ipsum Lorem Ipsum Lorem IPsum
                                  Lorem Ipsum Lorem Ipsum Lorem IPsum Lorem
                                  Ipsum Lorem Ipsum Lorem IPsum Lorem Ipsum
                                  Lorem Ipsum Lorem IPsum
                                </p>

                                <div class="clearfix"></div>
                              </div>
                              <div class="profile-list-footer">
                                <div class="profile-list-info hidden-xs">
                                  added: 28/11/15
                                </div>
                              </div>
                            </div>
                            <div class="clearfix"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>{" "}
      </section>
    );
  }
}

export default ChatView;
