import { useEffect, useState } from "react";
import "./SendWallet.css";
import {
  getToken,
  openModal,
} from "../../common/commonFunctions/commonFunctions";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
const SendWallet = (props) => {
  const [addAmount, setAddAmount] = useState(0);

  const [addressValue, setAddressValue] = useState();

  const [walletId, setWalletId] = useState();
  const [message, setMessage] = useState();
  const [messageColor, setMessageColor] = useState();
  const [open, setOpen] = useState();
  const [isLoader, setIsLoader] = useState(false);
  const inputEventOne = (event) => {
    setAddAmount(event.target.value);
  };

  const inputEventTwo = (event) => {
    setAddressValue(event.target.value);
  };

  const Submit = (event) => {
    event.preventDefault();
    if (props.balance < addAmount) {
      setMessage("Please Enter sufficient amount");
      onOpenModal();
    } else {
      setIsLoader(true);
      axios({
        method: "post",
        url: baseURL + "api/v1/wallet/send_balance",
        data: {
          walletUuid: walletId,
          amount: parseFloat(addAmount),
          toAddress: addressValue,
        },
        headers: {
          "Content-Type": "application/json-patch+json",
          accept: "text/plain",
          Authorization: `Bearer ${getToken()}`,
        },
      })
        .then((response) => {
          setMessage(response.data.message);

          updateOnSendAmount();
          onOpenModal();
          setMessage(response.data.message);
          setMessageColor(response.data.isSuccess);
          setIsLoader(false);
        })
        .catch((error) => {
          setIsLoader(false);
        });
    }
  };
  useEffect(() => {
    setWalletId(props.id);
  }, [props.id]);
  const updateOnSendAmount = () => {
    props.updateOnSend();
  };

  const onOpenModal = () => {
    setOpen(true);
  };
  const onCloseModal = () => {
    setOpen(false);
  };
  return (
    <>
      <div className={isLoader ? "loader-bg" : ""}></div>
      <div className=" send-wallet">
        <form onSubmit={Submit}>
          <div className="input-1">
            <input
              type="number"
              step="0.000000000000001"
              onChange={inputEventOne}
              className="form-control input-full main-input"
              name="Amount"
              required
              placeholder="Type Amount"
            />
          </div>

          <div className="input-2">
            <input
              type="text"
              onChange={inputEventTwo}
              value={addressValue}
              required
              className="form-control input-full main-input"
              name="Address"
              placeholder="Type Address"
            />
          </div>
          <div className="buttons-div">
            <button type="submit" className="send-amount-button">
              SEND
            </button>
          </div>
        </form>
      </div>
      
      <Modal open={open} onClose={onCloseModal} center>
        <div class="modal-profile-body">
          <div className="row">
            <div className="col-xs-12 col-sm-12">
              <h5>
                {" "}
                {message}
                <span class="special-color">.</span>
              </h5>
            </div>
          </div>
        </div>
        <div class="modal-profile-footer">
          <div className="buttons">
            <a
              className="buttons"
              class="button-primary button-shadow"
              onClick={onCloseModal}
            >
              <span>Ok</span>
              <div class="button-triangle"></div>
              <div class="button-triangle2"></div>
              <div class="button-icon">
              <i class="fa fa-wallet"></i>
              </div>
            </a>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SendWallet;
