import React, { useEffect, useState } from "react";
import Blog1 from "../../../assets/images/footer-blog1.jpg";
import Blog2 from "../../../assets/images/footer-blog2.jpg";
import Logo from "../../../assets/images/cezco-logo.png";
import { Link } from "react-router-dom";
import { baseURL } from "./../../../ApiURL/ApiURL";
import axios from "axios";
import { Modal } from "react-responsive-modal";
import "react-responsive-modal/styles.css";
import "./Footer.css";
const Footer = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [email, setEmail] = useState("");
  const [loader, setLoader] = useState(false);
  const [message, setMessage] = useState("");
  const [isSuccess, setIsSuccess] = useState(false);
  const changeHandler = (event) => {
    setEmail(event.target.value);
    console.log("email", email);
  };
  const submit = (e) => {
    e.preventDefault();
    setLoader(true);
    axios({
      method: "post",
      url: baseURL + "api/v1/account/AddEmailSubscription",
      data: {
        Email: email,
      },
    })
      .then((response) => {
        console.log("response", response);
        setIsSuccess(response.data.isSuccess);
        setMessage(response.data.message);
        openModal();
        setLoader(false);
      })

      .catch((error) => {
        setLoader(false);
      });
  };
  const openModal = () => {
    setIsOpen(true);
  };
  const closeModal = () => {
    setIsOpen(false);
  };
  let myCurrentDate = new Date()
  return (
    <>
      <div className={loader ? "loader-bg" : ""}></div>
      <div>
        {/* <footer class="large-cont">
          <div class="container">
            <div class="row">
              <div class="col-xs-6 col-sm-6 col-lg-3">
                <h4 class="second-color">
                  contact us<span class="special-color">.</span>
                </h4>
                <div class="footer-title-separator"></div>
                <p class="footer-p"></p>
                <address>
                  <span>
                    <i class="fa fa-envelope fa-sm"></i>
                    <a href="mailto:info@cescoTech.ca`">info@cescoTech.ca</a>
                  </span>
                </address>
                <div class="clear"></div>
              </div>
              <div class="col-xs-6 col-sm-6 col-lg-3">
                <h4 class="second-color">
                  quick links<span class="special-color">.</span>
                </h4>
                <div class="footer-title-separator"></div>
                <ul class="footer-ul">
                  <li>
                    <span class="custom-ul-bullet"></span>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <span class="custom-ul-bullet"></span>
                    <Link to="/listing">Listing</Link>
                  </li>

                  <li>
                    <span class="custom-ul-bullet"></span>
                    <Link to="/contactus">Contact us</Link>
                  </li>
                </ul>
              </div>
              <div class="col-xs-12 col-sm-6 col-lg-3">
                <h4 class="second-color">
                  from the blog<span class="special-color">.</span>
                </h4>
                <div class="footer-title-separator"></div>
                <div class="row">
                  <div class="col-xs-6 col-sm-12">
                    <article>
                      <a href="blog-right-sidebar.html">
                        <img src={Blog2} alt="" class="footer-blog-image" />
                      </a>
                      <div class="footer-blog-title">
                        <a
                          href="https://www.feedspot.com/infiniterss.php?_src=feed_title&followfeedid=4886269&q=site:https%3A%2F%2Ftorontolife.com%2Fcategory%2Freal-estate%2Ffeed%2F"
                          target="_blank">
                          Toronto Life is the destination for people who care
                          about Toronto, the country's most vibrant city, and
                          want to get the most out of it. Get articles on real
                          estate.
                        </a>
                      </div>
                      <div class="footer-blog-date">
                        <i class="fa fa-calendar-o"></i>7/7/2021
                      </div>
                      <div class="clearfix"></div>
                    </article>
                    <div class="footer-blog-separator hidden-xs"></div>
                  </div>
                  <div class="col-xs-6 col-sm-12">
                    <article>
                      <a href="blog-right-sidebar.html">
                        <img src={Blog1} alt="" class="footer-blog-image" />
                      </a>
                      <div class="footer-blog-title">
                        <a
                          target="_blank"
                          href="https://www.feedspot.com/infiniterss.php?_src=feed_title&followfeedid=4621248&q=site:https%3A%2F%2Fwww.canadianrealestatemagazine.ca%2Frss">
                          Canadian Real Estate Wealth Magazine brings real
                          estate investment news, market updates and market
                          reports for free. Find the latest Canadian real estate
                          trends. Canadian Real Estate Wealth is the leading
                          independent property investment magazine and website
                          in Canada.
                        </a>
                      </div>
                      <div class="footer-blog-date">
                        <i class="fa fa-calendar-o"></i>8/08/2021
                      </div>
                      <div class="clearfix"></div>
                    </article>
                  </div>
                </div>
              </div>
              <div class="col-xs-12 col-sm-6 col-lg-3">
                <h4 class="second-color">
                  newsletter<span class="special-color">.</span>
                </h4>
                <div class="footer-title-separator"></div>

                <form class="form-inline footer-newsletter" onSubmit={submit}>
                  <input
                    type="email"
                    name="email"
                    class="form-control"
                    id="exampleInputEmail2"
                    placeholder="enter your email"
                    onChange={changeHandler}
                  />
                  <button type="submit" class="btn">
                    <i class="fa fa-lg fa-paper-plane"></i>
                  </button>
                </form>
              </div>
            </div>
          </div>
        </footer> */}
        <footer class="small-cont">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-md-12 footer-copyrights">
                 Copyright &copy; {myCurrentDate.getFullYear()} Dwelling Goldmine
                Ltd.
              </div>
            </div>
          </div>
        </footer>
      </div>

      <Modal
        classNames="modal-responsive-layout"
        open={isOpen}
        onClose={closeModal}
        center>
        <div className="modal-body">
          {" "}
          {isSuccess === false ? (
            <h3> {message}</h3>
          ) : (
            <h3>Thank You For Subscribing To Our Company Newsletter</h3>
          )}
          <button className="button-primary" onClick={closeModal}>
            close
          </button>
        </div>
      </Modal>
    </>
  );
};

export default Footer;
