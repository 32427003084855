import React, { useEffect } from "react";
import axios from "axios";
import { baseURL } from "../../../ApiURL/ApiURL";
import Data from "../../../GeoData.json";
import RangeSlider from "rc-slider";
import Slider from '@material-ui/core/Slider';

// import Slider from 'react-rangeslider'
import { useState } from "react";
import { useParams } from "react-router-dom";
const { Range } = RangeSlider;


const propertyType = [
  { id: 1, PropertyTypeName: "House" },
  { id: 2, PropertyTypeName: "Farm House" },
  { id: 3, PropertyTypeName: "Penthouse" },
  { id: 4, PropertyTypeName: "Flat" },
  { id: 5, PropertyTypeName: "Commercial Plot" },
  { id: 6, PropertyTypeName: "Agricultural Land" },
  { id: 7, PropertyTypeName: "Residential Plot" },
  { id: 8, PropertyTypeName: "Office" },
  { id: 9, PropertyTypeName: "Warehouse" },
  { id: 10, PropertyTypeName: "Building" },
  { id: 11, PropertyTypeName: "Shop" },
  { id: 12, PropertyTypeName: "Factory" },
];
const SectionLight = () => {
  var state = {
    Listings: "",
    image1: "",
    image2: "",
    image3: "",
    PropertyTypeId: "",
    DistrictName: "",
    cityNames: "",
    PropertyStatus: "",
    minAmount: 0,
    maxAmount: 100000000,
    CityName: "",
    CountryName: "",
    TransactionTypes: "",
    isLoader: false,
  };

  // const [rang, setRange] = useState()
  // const handleChange = value => {
  //   setRange(value)
  // };

  const [value, setValue] = React.useState([0, 1000000]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  function valuetext(value) {
    return `${value}°C`;
  }

const[TransactionType,setTransactionType]=useState("")
const[PropertyTypeId,setPropertyTypeId]=useState("")


  const ListingChange=(e)=>{
if(e.target.name==="TransactionTypes"){
setTransactionType(e.target.value)
}
else if(e.target.name==="PropertyTypeId"){
setPropertyTypeId(e.target.value)
}
  }

const submitSearch=()=>{
  axios({
    method: "get",
    url:
      baseURL +
      "api/v1/Property/get_listings?"+"TransactionType"+TransactionType+"PropertyTypeID"+PropertyTypeId,
  })
    .then((response) => {

    })
    .catch((error) => {
     
    });
  // var parms=useParams()
  //   var url = parms.location;
  //   console.log("url is this",url)
    // var params = queryString.parse(url);

    // if (url == "") {
    //   disableScroll();
    //   this.setState({ isLoader: true });
    //   axios({
    //     method: "get",
    //     url: baseURL + "api/v1/Property/get_listings",
    //   })
    //     .then((response) => {
    //       console.log(response);
    //       enableScroll();
    //       console.log(
    //         "pageNumber",
    //         Math.ceil(response.data.totalItems / response.data.pageSize)
    //       );
    //       this.setState(
    //         {
    //           totalItems: response.data.totalItems,
    //           Listings: response.data.data,
    //           isLoader: false,
    //           paginationPages: Math.ceil(
    //             response.data.totalItems / response.data.pageSize
    //           ),
    //           paginationCurrentPage: response.data.currentPage,
    //         },
    //         () => {}
    //       );
    //     })
    //     .catch((error) => {
    //       enableScroll();
    //       this.setState({ isLoader: false });
    //     });
    // } else {
    //   disableScroll();
    //   this.setState({ isLoader: true });
    //   axios({
    //     method: "get",
    //     url:
    //       baseURL +
    //       "api/v1/Property/get_listings?" +
    //       "Country=" +
    //       params.Country +
    //       "&City=" +
    //       params.City +
    //       "&TransactionType=" +
    //       params.TransactionType +
    //       "&PropertyTypeID=" +
    //       params.PropertyTypeID +
    //       "&MaximumAmount=" +
    //       params.MaximumAmount +
    //       "&MinimumAmount=" +
    //       params.MinimumAmount +
    //       "&Kitchen=" +
    //       params.Kitchen +
    //       "&Bedroom=" +
    //       params.Bedroom +
    //       "&Bathroom=" +
    //       params.Bathroom +
    //       "&PageSize=" +
    //       this.state.pageSize +
    //       "&CurrentPage=" +
    //       this.state.currentPage,
    //   })
    //     .then((response) => {
    //       enableScroll();
    //       this.setState(
    //         {
    //           totalItems: response.data.totalItems,
    //           Listings: response.data.data,
    //           isLoader: false,
    //           paginationPages: Math.ceil(
    //             response.data.totalItems / response.data.pageSize
    //           ),
    //           paginationCurrentPage: response.data.currentPage,
    //         },
    //         () => {}
    //       );
    //     })
    //     .catch((error) => {});
    // }
  
}
  useEffect(() => {
    let array = [];
    let arraySliced = [];
    axios({
      method: "get",
      url: baseURL + "api/v1/Property/get_listings?"+"TransactionType"+TransactionType,
    })
      .then((response) => {
        // this.setState({ isLoader: false });
        array = response.data.data;
        arraySliced = array.slice(Math.max(array.length - 3, 0));
        let image1 = "";
        let image2;
        let image3;
        image1 = arraySliced[0].propertyMedia[0].mediaUrl;
        image2 = arraySliced[1].propertyMedia[0].mediaUrl;
        image3 = arraySliced[2].propertyMedia[0].mediaUrl;
        // this.setState(
        //   {
        //     Listings: arraySliced,
        //     image1: image1,
        //     image2: image2,
        //     image3: image3,
        //   },
        //   () => { }
        // );
      })
      .catch((error) => {
        // this.setState({ isLoader: false });
      });
  }, [])

  return (
    <>
      <section className="highlight-section">
        <div className="img-pnl"></div>
        <div className="txt-pnl">
          <h1 class="hedng"> Property Highlights</h1>
          <ul className="text-listing">
            <li>
              <h4>Fabulous View</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </p>
            </li>
            <li>
              <h4>Fabulous View</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </p>
            </li>
            <li>
              <h4>Fabulous View</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </p>
            </li>
            <li>
              <h4>Fabulous View</h4>
              <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
              </p>
            </li>
          </ul>
        </div>
      </section>

      <section className="section-light top-padding-60 location-pnl bottom-padding-60" >
        <div className="transaction-frame-pnl">
          <iframe src={"https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.1583091352!2d-74.11976373946234!3d40.69766374859258!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY%2C%20USA!5e0!3m2!1sen!2s!4v1642745113772!5m2!1sen!2s"}></iframe>
        </div>
        <form class="adv-search-form" action="#">
          <div class="adv-search-cont">
            <div class="container">
              <div class="row">

                <div class="col-xs-12 col-lg-12 col-md-12 text-center">
                  <h1>Location & Neighbouring</h1>
                </div>
              </div>
            </div>
            <div class="container margin-top-60">
              <div class="row tab-content">
                <div
                  role="tabpanel"
                  class="col-xs-12 adv-search-outer tab-pane fade in active"
                  id="apartments"
                >
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 col-md-3 col-lg-3 select-field">
                      <label>Transaction Type</label>
                      <select
                        name="TransactionTypes"
                        className="form-control input-full main-input property-select"
                        id="TransactionTypes"
                      // onChange={this.onHandleChange}
                      onChange={ListingChange}
                      >
                        {" "}
                        <option value="">Select Transaction Type</option>
                        <option value="Sale">Sale</option>
                        <option value="Lease">Rent</option>
                      </select>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3  col-lg-3 select-field">
                      <label>Property Type</label>
                      <select
                        name="PropertyTypeId"
                        className="form-control input-full main-input property-select"
                      onChange={ListingChange}
                      >
                        <option value="">Select Property Type</option>
                        {propertyType.map((item) => (
                          <option value={item.id}>
                            {item.PropertyTypeName}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-lg-3 col-md-3  select-field">
                      <label>Country Name</label>
                      <select
                        name="CountryName"
                        className="form-control input-full main-input property-select"
                      // onChange={this.onHandleCountryChange}
                      >
                        <option value="">Select Country</option>
                        {Data.map((countryName) => (
                          <option value={countryName.name}>
                            {countryName.name}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col-xs-12 col-sm-6 slider col-md-3  col-lg-3">
                      <div class="adv-search-range-cont">
                        <label
                          for="slider-range-price1-value"
                          class="adv-search-label pull-left"
                        >
                          Amount:
                        </label>
                        <span className="pull-right" style={{color:"white"}}>min-{value[0]}$ max-{value[1]}$</span>
                        <div style={{ marginTop: "50px" }}>
                          {/* <Slider
                            value={value}
                            onChange={handleChange}
                            // valueLabelDisplay="auto"
                            aria-labelledby="range-slider"
                            getAriaValueText={valuetext}
                          /> */}

                          <Slider
                            min={0}
                            max={1000000}
                            value={value}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3  col-lg-3">
                      <div class="adv-search-range-cont">
                        <label
                          for="slider-range-price1-value"
                          class="adv-search-label pull-left"
                        >
                          Kitchen:
                        </label>
                        <select
                          name="maxKitchen"
                          className="form-control input-full main-input property-select"
                          id="maxKitchen"
                        // onChange={this.onHandleChange}
                        >
                          {" "}
                          <option value="">Select </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        {/* <span className="pull-right"></span>
                        <input
                          type="text"
                          id="slider-range-price1-value"
                          placeholder={
                            this.state.minKitchen + "-" + this.state.maxKitchen
                          }
                          readonly
                          class="adv-search-amount"
                        />
                        <div class="clearfix"></div>

                        <Range
                          onChange={this.onSliderChangeKitchen}
                          min={0}
                          max={10}
                        /> */}
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3  col-lg-3">
                      <div class="adv-search-range-cont">
                        <label
                          for="slider-range-price1-value"
                          class="adv-search-label pull-left"
                        >
                          Bedrooms:
                        </label>
                        <select
                          name="maxBed"
                          className="form-control input-full main-input property-select"
                          id="maxBed"
                        // onChange={this.onHandleChange}
                        >
                          {" "}
                          <option value="">Select </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        {/* <span className="pull-right"></span>
                        <input
                          type="text"
                          id="slider-range-price1-value"
                          placeholder={
                            this.state.minBed + "-" + this.state.maxBed
                          }
                          readonly
                          class="adv-search-amount"
                        />
                        <div class="clearfix"></div>

                        <Range
                          onChange={this.onSliderChangeBed}
                          min={0}
                          max={10}
                        /> */}
                      </div>
                    </div>
                    <div class="col-xs-12 col-sm-6 col-md-3  col-lg-3">
                      <div class="adv-search-range-cont">
                        <label
                          for="slider-range-price1-value"
                          class="adv-search-label pull-left"
                        >
                          Bathrooms:
                        </label>
                        <select
                          name="maxBath"
                          className="form-control input-full main-input property-select"
                          id="maxBath"
                        // onChange={this.onHandleChange}
                        >
                          {" "}
                          <option value="">Select </option>
                          <option value="1">1</option>
                          <option value="2">2</option>
                          <option value="3">3</option>
                          <option value="4">4</option>
                          <option value="5">5</option>
                          <option value="6">6</option>
                          <option value="7">7</option>
                          <option value="8">8</option>
                          <option value="9">9</option>
                          <option value="10">10</option>
                        </select>
                        {/* <span className="pull-right"></span>
                        <input
                          type="text"
                          id="slider-range-price1-value"
                          placeholder={
                            this.state.minBath + "-" + this.state.maxBath
                          }
                          readonly
                          class="adv-search-amount"
                        />
                        <div class="clearfix"></div>

                        <Range
                          onChange={this.onSliderChangeBath}
                          min={0}
                          max={10}
                        /> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="container">
              <div class="row">
                <div class="col-sm-12 col-md-6 col-lg-3 col-md-offset-6 col-lg-offset-9 adv-search-button-cont">
                  <button
                       onClick={submitSearch}
                    class="button-primary pull-right"
                  >
                    <span>search</span>
                    <div class="button-triangle"></div>
                    <div class="button-triangle2"></div>
                    <div class="button-icon">
                      <i class="fa fa-search"></i>
                    </div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </form>

      </section>
    </>
  );
};

export default SectionLight;
